import { Directive, EventEmitter, Input, Output } from "@angular/core";
import { ModelI } from "../models/model";

export type SortDirection = "asc" | "desc";
const rotate: { [key: string]: SortDirection } = { asc: 'desc', desc: 'asc' };

export interface SortEvent<T extends ModelI | undefined> {
  column: Extract<keyof T, string>;
  direction: SortDirection;
}

@Directive({
  selector: 'th[sortable]',
  standalone: true,
  host: {
    '[class.asc]': 'direction === "asc"',
    '[class.desc]': 'direction === "desc"',
    '(click)': 'rotate()',
  },
})
export class SortableHeaderDirective {
  @Input() sortable: string = 'id';
  @Input() direction: SortDirection = 'asc';
  @Output() sort = new EventEmitter<SortEvent<any>>();

  rotate() {
    this.direction = rotate[this.direction];
    this.sort.emit({ column: this.sortable, direction: this.direction });
  }
}