import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import {
  CommonModule, DatePipe,
} from '@angular/common'
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { HeaderComponent } from '../../../../components/admin/header/component';
import { FooterComponent } from '../../../../components/admin/footer/component';
import { SidebarComponent } from '../../../../components/admin/sidebar/component';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { Fetchervice } from '../../../../services/fetch.service';
import { RouteNamesService } from '../../../../services/route_name.service';
import { Link, TabsMenu } from '../../../../components/admin/common/tab_menu';
import { FormCardHeader } from '../../../../components/admin/common/form_card_header';
import { SupplierContact } from '../../../../models/supplier_contacts';
import { SortEvent, SortableHeaderDirective } from '../../../../directive/sorteable_header.directive';
import { getSupplierMenu } from '../form/component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CreateSupplierContact } from './create';

@Component({
  selector: 'app-supplier-contact',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    SortableHeaderDirective,
    HeaderComponent,
    SidebarComponent,
    FooterComponent,
    FormCardHeader,
    TabsMenu,
  ],
  providers: [
    DatePipe,
  ],
  templateUrl: './component.html',
  styleUrl: './component.scss'
})
export class SupplierContactComponent<T extends SupplierContact> implements OnInit {
  id?: number;
  title = "Contacts for Supplier";

  model: Fetchervice<T>;

  basePath!: string;

  menu: Link[] = [
    //
  ]

  constructor(
    private route: ActivatedRoute,
    private routeNames: RouteNamesService,
    private modalService: NgbModal,
  ) {
    this.model = Fetchervice.new<T>();
  
    this.basePath = this.routeNames.path("admin.supplier.index") as string;
  }

  ngOnInit(): void {
    this.id = this.route.snapshot.params['id'];
    this.menu = getSupplierMenu(this.basePath, `${this.id}`);

    this.model
      .from(`/api/v1/supplier/${this.id}/contact`)
      .perPage(0)
      .get();
  }

  onSort(event: SortEvent<T>) {
    this.model
      .orderBy(event.column, event.direction)
      .paginate(1)
      .get();
  }

  perPage(event: any) {
    this.model
      .paginate(1, event.target.value)
      .get()
    ;
  }

  setPage(n: number): void {
    this.model
      .paginate(n)
      .get()
    ;
  }

  open(id?: number) {
      const modalRef = this.modalService.open(CreateSupplierContact);
      modalRef.componentInstance.id = id;
  
      modalRef.result.then((model: Fetchervice<SupplierContact>) => {
        model.save(() => {
          this.model.get();
        });
      }).catch((error) => {
        console.log(error);
      });
  }
}
