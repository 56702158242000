<div class="d-flex flex-nowrap border rounded min-vh-100">

  <app-sidebar class="d-flex flex-column flex-shrink-0 text-dark bg-white border-end" style="width: 280px;" />

  <div class="d-flex flex-column flex-grow-1">
    <app-header />

    <main class="container-fluid h-100">
      <div class="row">
        <div class="col px-0">
          <div class="card border-0">
            <app-card-header [title]="title" [basePath]="basePath" />
            <div class="card-body">
              <app-table [model]="model" [basePath]="basePath"></app-table>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</div>

<app-footer />