import { Component, inject } from '@angular/core';
import { CommonModule, NgTemplateOutlet } from '@angular/common';
import { LoadingService } from '../../services/loading.service';

@Component({
    selector: 'app-loading',
    standalone: true,
    imports: [NgTemplateOutlet, CommonModule],
    template: `
    <div *ngIf="service.isLoading" class="position-fixed vh-100 bg-dark bg-opacity-25 w-100" style="z-index: 10;">
        <div class="position-absolute top-50 start-50 translate-middle">
            <div class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>
    </div>
	`,
    // host: { class: 'position-absolute vh-100 bg-dark bg-opacity-25 w-100', style: 'z-index: 1000' },
})
export class LoadingSpinner {
    constructor(public service: LoadingService) {

    }
}