import {
	Entity,
	Listable,
	Metadata,
	ModelI
} from "./model";



@Entity({
    url: "/api/v1/supplier_contact"
})
export class SupplierContact implements ModelI {
	id!: number;
	name!: string;
	description!: string;
	email!: string;
	phone_number!: string[];
	status!: number;
	supplier_id!: string;
	author_id!: string;
}
