<div class="d-flex flex-nowrap border rounded min-vh-100">

  <app-sidebar class="d-flex flex-column flex-shrink-0 text-dark bg-white" style="width: 280px;" />

  <div class="d-flex flex-column flex-grow-1">
    <app-header />

    <main class="container-fluid h-100">
      <div class="row">
        <div class="col px-0">
          <div class="card border-0">
            <app-card-header [title]="title" [basePath]="basePath">
              <button class="btn btn-outline-success" (click)="open()" i18n>New</button>
            </app-card-header>
            <div class="card-body">
              <app-tabs-menu [links]="menu" />

              <div class="card-body">
                <div class="table-responsive">
                  <table class="table table-hover">
                    <thead class="border-bottom border-dark">
                      <tr>
                        <th role="button" scope="col" sortable="id"
                          (sort)="model.orderBy($event.column, $event.direction).paginate(1).get()">#</th>
                        <th role="button" scope="col" sortable="title"
                          (sort)="model.orderBy($event.column, $event.direction).paginate(1).get()" i18n>Question</th>
                        <th role="button" scope="col" sortable="description"
                          (sort)="model.orderBy($event.column, $event.direction).paginate(1).get()" i18n>Answer</th>
                        <th i18n>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      @for (item of model.items; track item.id) {
                      <tr>
                        <th scope="row">{{ item.id }}</th>
                        <td>
                          <span class="text-decoration-underline text-primary" role="button" (click)="open(item.id)">
                            {{ item.title }}
                          </span>
                        </td>
                        <td class="small">{{ item.description }}</td>
                        <td class="text-center">
                          <span class="text-danger small" role="button" (click)="delete(item.id)">
                            <i class="fa-solid fa-trash"></i>
                          </span>
                        </td>
                      </tr>
                      }
                      @empty {
                      <tr>
                        <td class="ps-5" colspan="4">No questions found.</td>
                      </tr>
                      }
                    </tbody>
                  </table>

                  @if (model.items.length > 0) {
                  <div class="d-flex justify-content-between p-2">
                    <nav>
                      <ul class="pagination">
                        @if (model.pagination.current == 1) {
                        <li class="page-item disabled">
                          <span class="page-link" role="button">&laquo;</span>
                        </li>
                        }
                        @else {
                        <li class="page-item" (click)="setPage(1)">
                          <span class="page-link" role="button">&laquo;</span>
                        </li>
                        }
                        @for (page of model.pagination.pages; track page) {
                        @if (page == model.pagination.current) {
                        <li class="page-item active">
                          <span class="page-link" role="button">{{ page }}</span>
                        </li>
                        } @else {
                        <li class="page-item" (click)="setPage(page)">
                          <span class="page-link" role="button">{{ page }}</span>
                        </li>
                        }
                        }
                        @if (model.pagination.current == model.pagination.last) {
                        <li class="page-item disabled">
                          <span class="page-link" role="button">&raquo;</span>
                        </li>
                        }
                        @else {
                        <li class="page-item" (click)="setPage(model.pagination.last)">
                          <span class="page-link" role="button">&raquo;</span>
                        </li>
                        }
                      </ul>
                    </nav>
                    <select class="form-select w-auto" (change)="perPage($event)">
                      <option value="15">15 items per page</option>
                      <option value="30">30 items per page</option>
                      <option value="50">50 items per page</option>
                      <option value="100">100 items per page</option>
                    </select>
                  </div>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</div>

<app-footer />