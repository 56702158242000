<header class="text-dark py-2 px-5 bg-white border-bottom">
  <nav class="navbar navbar-expand-lg navbar-light">
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse flex-column flex-md-row" id="navbarNav">
      <ul class="navbar-nav me-auto mb-2 mb-lg-0">
        <li class="nav-item">
          <a class="nav-link active" aria-current="page" href="#" target="_blank">Inicio</a>
        </li>
      </ul>
      <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
        <li class="nav-item" ngbDropdown placement="bottom-start">
          <button type="button" class="btn btn-link nav-link p-0" ngbDropdownToggle>
            <img src="https://github.com/systemson.png" alt="" width="32" height="32" class="rounded-circle me-2">
          </button>
          <ul class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
            <li>
              <h6 class="dropdown-header">{{ auth.user?.name }}</h6>
            </li>
            <li ngbDropdownItem>
              <a class="text-decoration-none text-dark" routerLink="/suppliers/profile" i18n>Profile</a>
            </li>
            <li>
              <hr class="dropdown-divider">
            </li>
            <li ngbDropdownItem>
              <span role="button" ngbDropdownItem (click)="logout();">Logout</span>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </nav>
</header>