import { Inject, inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { RouteNamesService } from '../services/route_name.service';

export const GuestGuard: CanActivateFn = (route: ActivatedRouteSnapshot) => {
  const router = inject(Router);
  const auth = inject(AuthService);
  const routeNames = inject(RouteNamesService);

  if (auth.user && !auth.tokenValid(route.data["scope"])) {
    auth.logout();
  }

  if (!auth.user) {
    return true;
  }

  return router.createUrlTree([routeNames.path(auth.redirectTo())]);
};
