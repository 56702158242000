import { Component, inject } from '@angular/core';

import { NgTemplateOutlet } from '@angular/common';
import { NgbToastModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastService } from '../../services/toats.service';

@Component({
    selector: 'app-toasts',
    standalone: true,
    imports: [NgbToastModule, NgTemplateOutlet],
    template: `
    @for (toast of toastService.toasts; track toast) {
        <ngb-toast
          [class]="toast.classname"
          [header]="toast.header"
          [autohide]="true"
          [delay]="toast.delay || 5000"
          (hidden)="toastService.remove(toast)"
        >
            {{ toast.body }}
        </ngb-toast>
    }
	`,
    host: { class: 'toast-container position-fixed top-0 end-0', style: 'z-index: 1200' },
})
export class ToastsContainer {
    toastService = inject(ToastService);
}