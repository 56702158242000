import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import {
  CommonModule,
} from '@angular/common'
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { HeaderComponent } from '../../../../components/admin/header/component';
import { FooterComponent } from '../../../../components/admin/footer/component';
import { SidebarComponent } from '../../../../components/admin/sidebar/component';
import { ActivityCategory } from '../../../../models/activity_category';
import { FormControl, FormGroup, ReactiveFormsModule, ValidationErrors } from '@angular/forms';
import { Fetchervice } from '../../../../services/fetch.service';
import { Location } from '../../../../models/location';
import { Regularity } from '../../../../models/regularity';
import { Activity } from '../../../../models/activity';
import { EditorConfig, NgxSimpleTextEditorModule, UNDO_BUTTON, REDO_BUTTON, BOLD_BUTTON, ITALIC_BUTTON, UNDERLINE_BUTTON, STRIKE_THROUGH_BUTTON, SEPARATOR, FONT_SIZE_SELECT } from 'ngx-simple-text-editor';
import { Validate } from '../../../../services/validator';
import { RouteNamesService } from '../../../../services/route_name.service';
import { Link, TabsMenu } from '../../../../components/admin/common/tab_menu';
import { FormCardHeader } from '../../../../components/admin/common/form_card_header';
import { Language } from '../../../../models/language';

@Component({
  selector: 'app-activity-form',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    HeaderComponent,
    SidebarComponent,
    FooterComponent,
    NgxSimpleTextEditorModule,
    FormCardHeader,
    TabsMenu,
  ],
  templateUrl: './component.html',
  styleUrl: './component.scss'
})
export class ActivityFormComponent<T extends Activity> implements OnInit {
  title = $localize`New Activity`;
  id?: number;
  form = new FormGroup({
    title: new FormControl('', [Validate.required, Validate.minLength(3), Validate.maxLength(124)]),
    summary: new FormControl('', [Validate.required, Validate.minLength(20), Validate.maxLength(255)]),
    description: new FormControl('', [Validate.required, Validate.minLength(20), Validate.maxLength(2048)]),

    base_price: new FormControl(0, [Validate.required, Validate.min(0)]),
    duration_quantity: new FormControl(1, [Validate.required]),
    duration_unit: new FormControl("hours", [Validate.required]),
    book_before: new FormControl(0, [Validate.required, Validate.min(0)]),
    cancel_before: new FormControl(0, [Validate.required, Validate.min(0)]),
    //
    min_participants: new FormControl(1, [Validate.required, Validate.min(1)]),
    expected_participants: new FormControl(1, [Validate.required, Validate.min(1)]),
    max_participants: new FormControl(1, [Validate.required, Validate.min(1)]),
    departure_address: new FormControl('', Validate.required),
    //
    category_id: new FormControl(1, [Validate.required]),
    language_id: new FormControl(1, [Validate.required]),
    regularity_id: new FormControl(2, [Validate.required]),
    origin_id: new FormControl(1, [Validate.required]),
    destination_id: new FormControl('', [Validate.required]),

  });

  menu: Link[] = [
    //
  ]

  config: EditorConfig = {
    placeholder: $localize`Description`,
    buttons: [REDO_BUTTON, UNDO_BUTTON, SEPARATOR, BOLD_BUTTON, ITALIC_BUTTON, UNDERLINE_BUTTON, STRIKE_THROUGH_BUTTON, SEPARATOR, FONT_SIZE_SELECT],
  };

  model: Fetchervice<T>;
  categories: Fetchervice<ActivityCategory>;
  locations: Fetchervice<Location>;
  languages: Fetchervice<Language>;
  regularities: Fetchervice<Regularity>;

  basePath!: string;

  constructor(
    private route: ActivatedRoute,
    private routeNames: RouteNamesService
  ) {
    this.basePath = this.routeNames.path("suppliers.activity.index") as string;

    this.model = Fetchervice.new<T>(this.form, { redirectTo: this.basePath });
    this.categories = Fetchervice.new<ActivityCategory>();
    this.locations = Fetchervice.new<Location>();
    this.languages = Fetchervice.new<Language>();
    this.regularities = Fetchervice.new<Regularity>();
  }

  ngOnInit(): void {
    this.id = this.route.snapshot.params['id'];

    this.model
      .from("/api/v1/suppliers/activity")

    if (this.id) {
      this.menu = getSupplierActivityMenu(this.basePath, `${this.id}`);

      this.model
        .find(this.id, () => {
          this.title = $localize`Sumary for Activity - ${this.model.attributes.title}`;
        });
    }

    this.categories
      .from("/api/v1/activity_category")
      .orderBy("id", "asc")
      .where("status", "1")
      .perPage(0)
      .cache()
      .get();

    this.locations
      .from(Location)
      .orderBy("id", "asc")
      .where("status", "1")
      .perPage(0)
      .cache()
      .get();

    this.regularities
      .from("/api/v1/activity_regularity")
      .orderBy("id", "asc")
      .where("status", "1")
      .perPage(0)
      .cache()
      .get();

    this.languages
      .from(Language)
      .orderBy("id", "asc")
      .where("status", "1")
      .perPage(0)
      .cache()
      .get();


  }

  isInvalid(name: string): boolean {
    this.getFormValidationErrors();
    const formControl = this.form.get(name);

    if (formControl) {
      return formControl.invalid && (formControl.dirty || formControl.touched)
    }

    return false;
  }

  onChangeExpected(e: any) {
    let value = e.target.value;

    if (typeof value != "number") {
      value = parseInt(value);
    }

    this.form.get("min_participants")?.setValue(value);
    this.form.get("max_participants")?.setValue(value);
  }

  length(name: string) {
    return this.form.get(name)?.value?.length ?? 0;
  }
  getFormValidationErrors() {
    Object.keys(this.form.controls).forEach(key => {
      const controlErrors: ValidationErrors | undefined | null = this.form.get(key)?.errors;
      if (controlErrors) {
        Object.keys(controlErrors).forEach(keyError => {
          console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
        });
      }
    });
  }
}

export function getSupplierActivityMenu(basePath: string, id: string): Link[] {
  return [
    {
      path: [basePath, `${id}`, 'update'],
      label: $localize`Summary`,
    },
    {
      path: [basePath, `${id}`, 'prices'],
      label: $localize`Prices`,
    },
    {
      path: [basePath, `${id}`, 'images'],
      label: $localize`Images`,
    },
    {
      path: [basePath, `${id}`, 'dates'],
      label: $localize`Dates`,
    },
    {
      path: [basePath, `${id}`, 'details'],
      label: $localize`Details`,
    },
    {
      path: [basePath, `${id}`, 'highlights'],
      label: $localize`Highlights`,
    },
    {
      path: [basePath, `${id}`, 'faq'],
      label: $localize`FAQ`,
    },
    {
      path: [basePath, `${id}`, 'unsuitables'],
      label: $localize`Unsuitables`,
    },
    {
      path: [basePath, `${id}`, 'reservations'],
      label: $localize`Reservations`,
    },
    {
      path: [basePath, `${id}`, 'comments'],
      label: $localize`Comments`,
    }
  ]
}
