<!-- <app-header /> -->
<main class="my-5">
  <section class="container-fluid my-5 p-5">
    <div class="p-5 my-5">
      <h1 class="text-body-emphasis" i18n>Not found</h1>
      <p class="col-lg-8 fs-5 text-muted" i18n>The page you are looking for doesn't exist or has been moved.</p>
      <a class="btn btn-outline-secondary btn-lg px-4 px-5" routerLink="/">
        <i class="fa-solid fa-home"></i>
        <span class="ps-2" i18n>Go Home</span>
      </a>
    </div>
  </section>
</main>
<!-- <app-footer /> -->