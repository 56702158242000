import { Component, inject, Input, OnInit } from '@angular/core';
import { FormGroup, FormControl, ReactiveFormsModule } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Validate } from '../../../../services/validator';
import { CommonModule } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { Fetchervice } from '../../../../services/fetch.service';
import { ActivityImage } from '../../../../models/activity_image';

@Component({
  selector: 'app-activity-image-upload',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
  ],
  template: `
<div class="modal-header">
  <h4 class="modal-title">{{ title }}</h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
</div>
<div class="modal-body">
  <form class="form needs-validation" novalidate [formGroup]="form">
    <div class="form-floating mb-3">
        <input type="file" class="form-control" id="image" placeholder="Image" formControlName="image"
            [ngClass]="isInvalid('image') ? 'is-invalid' : ''" accept="image/*" (change)="handleInputEvent($event)">
        <label for="image" i18n>Image</label>
    </div>
      <div class="form-floating mb-3">
          <input type="text" class="form-control" id="description" placeholder="Description" formControlName="description"
              [ngClass]="isInvalid('description') ? 'is-invalid' : ''">
          <label for="description" i18n>Description</label>
      </div>
  </form>
</div>
<div class="modal-footer">
  <button
    type="button"
    class="btn btn-outline-secondary"
    (click)="modal.dismiss()"
    i18n
  >
    Cancel
  </button>
  <button
    type="button"
    class="btn btn-danger"
    (click)="modal.close(model)"
    [disabled]="!form.valid"
    i18n
  >
    Save
  </button>
</div>
    `,
})
export class CreateActivityImage<T extends ActivityImage> implements OnInit {
  modal = inject(NgbActiveModal);

  title = $localize`Upload Activity Image`;

  @Input() id?: number;
  @Input() model!: Fetchervice<T>;

  form = new FormGroup({
    image: new FormControl(null, [Validate.required]),
    description: new FormControl('', []),
  });

  ngOnInit() {
    this.model.setForm(this.form);
  }

  isInvalid(name: string): boolean {
    const formControl = this.form.get(name);

    if (formControl) {
      return formControl.invalid && (formControl.dirty || formControl.touched)
    }

    return false;
  }

  handleInputEvent($event: any) {
    const image = $event.target.files[0];

    this.form.patchValue({
      image: image,
    })
  }
}