<div class="d-flex flex-nowrap border rounded min-vh-100">
  <app-sidebar class="d-flex flex-column flex-shrink-0 text-dark bg-white" style="width: 280px;" />

  <div class="d-flex flex-column flex-grow-1">
    <app-header />

    <main class="container-fluid h-100">
      <!-- Page title -->
      <div class="my-5">
        <h3>My Profile</h3>
        <hr>
        <div class="form-responsive">
          <form class="form">
            <div class="row g-3">
              <div class="col-8">
                <div class="form-floating mb-3">
                  <input type="text" class="form-control" id="trade_name" placeholder="Trade Name">
                  <label for="floatingInput">Trade Name</label>
                </div>
                <div class="form-floating mb-3">
                  <input type="text" class="form-control" id="legal_name" placeholder="Legal Name">
                  <label for="floatingInput">Legal Name</label>
                </div>
                <div class="mb-3">
                  <label for="floatingInput">Address</label>
                  <textarea class="form-control" id="address" rows="3"></textarea>
                </div>
                <div class="mb-3">
                  <label for="description">Description</label>
                  <textarea class="form-control" id="description" rows="3"></textarea>
                </div>
              </div>
              <div class="col-4">
                <div class="mb-3">
                  <label for="document_code" class="form-label text-muted small" i18n>Document Number</label>
                  <div class="input-group input-group-lg mb-3">
                    <select class="form-select" id="document_type_id" formControlName="document_type_id">
                      <option value="1">Cédula</option>
                      <option value="2">Pasaporte</option>
                      <option value="3">RIF</option>
                    </select>
                    <input type="text" id="document_code" class="form-control"
                      i18n-placeholder placeholder="Document" autocomplete="document_code" maxlength="10">
                  </div>
                </div>
                <div class="form-floating mb-3">
                  <input type="email" class="form-control" id="webpage" placeholder="Webpage">
                  <label for="floatingInput">Webpage</label>
                </div>
                <div class="form-floating mb-3">
                  <select class="form-select" id="language">
                    <option value="1">Español</option>
                    <option value="2">English</option>
                  </select>
                  <label for="floatingInput">Language</label>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </main>

  </div>
</div>

<app-footer />