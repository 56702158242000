import { Component, inject, Input, OnInit } from '@angular/core';
import { FormGroup, FormControl, ReactiveFormsModule } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Validate } from '../../../../services/validator';
import { CommonModule } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { Fetchervice } from '../../../../services/fetch.service';
import { ActivityImage } from '../../../../models/activity_image';

@Component({
  selector: 'app-activity-image-show',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
  ],
  template: `
  <div class="modal-body">
    <img class="img-fluid w-100" [src]="image.path">
  </div>
    `,
})
export class ShowActivityImage {
  modal = inject(NgbActiveModal);
  @Input() image!: ActivityImage;
}