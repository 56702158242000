import { Component, inject, OnInit } from '@angular/core';
import {
  CommonModule,
} from '@angular/common'
import { AuthService, LoginResponse } from '../../../services/auth.service';
import { RouterModule } from '@angular/router';
import { HeaderComponent } from '../../../components/admin/header/component';
import { FooterComponent } from '../../../components/admin/footer/component';
import { SidebarComponent } from '../../../components/admin/sidebar/component';
import { LoadingService } from '../../../services/loading.service';
import { Fetchervice } from '../../../services/fetch.service';

@Component({
  selector: 'app-dashboard',
  standalone: true,
  imports: [
    CommonModule,
    HeaderComponent,
    SidebarComponent,
    FooterComponent,
  ],
  templateUrl: './component.html',
  styleUrl: './component.scss'
})
export class DashboardComponent implements OnInit {
  user: LoginResponse["user"] & {[key: string]: any};
  fetch: Fetchervice<any> = inject(Fetchervice);
  dashboard!: any

  constructor(
    private loading: LoadingService,
    private auth: AuthService
  ) {
    this.user = this.auth.user as LoginResponse["user"];
  }

  ngOnInit(): void {
    this.loading.hide();

    this.fetch
      .from("/api/v1/suppliers/dashboard")
      .get(() => {
        this.dashboard = this.fetch.items.pop();
      });
  }

  logout() {
    this.auth.logout();
  }
}
