import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { RouterModule } from '@angular/router';

@Component({
    selector: 'app-form-buttons',
    standalone: true,
    imports: [
        CommonModule,
        RouterModule,
    ],
    template: `
    <div class="btn-group" role="group">
        <ng-content></ng-content>
        @if (model) {
            <button class="btn btn-outline-success" (click)="model.save()" [disabled]="!model.form.valid" i18n>Save</button>
            <button class="btn btn-outline-secondary" (click)="model.updateForm()" i18n>Reset</button>
        }
        <a [routerLink]="[basePath]" class="btn btn-outline-danger" i18n>Back</a>
    </div>
    `
})
export class FormButtons {
    @Input() model!: any;
    @Input() basePath!: string;
}