import { Component, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { RouteNamesService } from '../../../services/route_name.service';

@Component({
	selector: 'app-footer',
	standalone: true,
	imports: [
		RouterModule,
	],
	templateUrl: './component.html',
})
export class FooterComponent {
	providersLoginRoute = this.routeName.path("suppliers.login");
	adminLoginRoute = this.routeName.path("admin.login");

	constructor(
		private routeName: RouteNamesService,
	) {

	}
}