<div class="d-flex flex-nowrap border rounded min-vh-100">

  <app-sidebar class="d-flex flex-column flex-shrink-0 text-dark bg-white" style="width: 280px;" />

  <div class="d-flex flex-column flex-grow-1">
    <app-header />

    <main class="container-fluid h-100">
      <div class="row">
        <div class="col px-0">
          <div class="card border-0">
            <app-card-header [title]="title" [model]="model" [basePath]="basePath" />
            <div class="card-body">
              <app-tabs-menu [links]="menu" [hide]="model.isNew" />
              <form class="form needs-validation" novalidate [formGroup]="form">
                <div class="row">
                  <div class="col-sm-8">
                    <div class="row">
                      <div class="col-sm-8">
                        <div class="form-floating mb-3">
                          <input type="text" class="form-control" id="title" placeholder="Title" formControlName="title"
                            [ngClass]="isInvalid('title') ? 'is-invalid' : ''">
                          <label for="title" i18n>Title</label>

                          <div *ngIf="isInvalid('title')" id="title-feedback" class="invalid-feedback">
                            <div *ngIf="form.get('title')?.errors?.['required']" i18n>
                              Title is required
                            </div>
                            <div *ngIf="form.get('title')?.errors?.['minlength']" i18n>
                              Title must be at least 3 characters long.
                            </div>
                            <div *ngIf="form.get('title')?.errors?.['maxlength']" i18n>
                              Title must be at most 124 characters long.
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-sm-4">
                        <div class="form-floating mb-3">
                          <select class="form-control" name="category" id="category" formControlName="category_id"
                            [ngClass]="isInvalid('category_id') ? 'is-invalid' : ''">
                            <option value="" selected>Select one</option>
                            @for (category of categories.items; track category.id) {
                            <option [value]="category.id">{{ category.name }}</option>
                            }
                          </select>
                          <label for="category_id" i18n>Category</label>

                          <div *ngIf="isInvalid('category_id')" id="category_id-feedback" class="invalid-feedback">
                            <div *ngIf="form.get('category_id')?.errors?.['required']" i18n>
                              Category is required
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-12">
                      <div class="mb-3">
                        <label class="w-100" for="summary">
                          <span class="text-muted small" i18n>Summary</span>
                          <span class="small float-end">{{ length("summary") ?? 0 }}</span>
                        </label>
                        <textarea class="form-control" name="summary" id="summary" formControlName="summary"
                          rows="5" [ngClass]="isInvalid('summary') ? 'is-invalid' : ''"></textarea>
                        <div *ngIf="isInvalid('summary')" id="summary-feedback" class="invalid-feedback">
                          <div *ngIf="form.get('summary')?.errors?.['required']" i18n>
                            Category is required
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-12">
                      <div class="mb-3">
                        <label class="text-muted small" for="description" i18n>Description</label>
                        <st-editor class="form-control p-0" [ngClass]="isInvalid('description') ? 'is-invalid' : ''"
                          formControlName="description" [config]="config"></st-editor>
                        <div *ngIf="isInvalid('description')" id="description-feedback" class="invalid-feedback">
                          <div *ngIf="form.get('description')?.errors?.['required']" i18n>
                            Description is required
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-sm-12">
                        <div class="form-floating mb-3">
                          <input type="text" class="form-control" id="departure_address" placeholder="Departure From"
                            formControlName="departure_address"
                            [ngClass]="isInvalid('departure_address') ? 'is-invalid' : ''">
                          <label for="departure_address">Departure From</label>
                        </div>
                      </div>
                      <div class="col-sm-4">
                        <div class="form-floating mb-3">
                          <select class="form-control" name="origin_id" id="origin_id"
                            formControlName="origin_id" [ngClass]="isInvalid('origin_id') ? 'is-invalid' : ''">
                            <option value="" selected>Select one</option>
                            <option *ngFor="let location of locations.items" [ngValue]="location.id">
                              {{ location.name }}
                            </option>
                          </select>
                          <label for="origin_id">Origin</label>
                        </div>
                      </div>
                      <div class="col-sm-4">
                        <div class="form-floating mb-3">
                          <select class="form-control" name="destination_id" id="destination_id"
                            formControlName="destination_id" [ngClass]="isInvalid('destination_id') ? 'is-invalid' : ''">
                            <option value="" selected>Select one</option>
                            <option *ngFor="let location of locations.items" [ngValue]="location.id">
                              {{ location.name }}
                            </option>
                          </select>
                          <label for="destination_id">Destination</label>
                        </div>
                      </div>
                      <div class="col-sm-4">
                        <div class="form-floating mb-3">
                          <select class="form-control" name="regularity_id" id="regularity_id"
                            formControlName="regularity_id" [ngClass]="isInvalid('regularity_id') ? 'is-invalid' : ''">
                            <option value="" selected>Select one</option>
                            <option *ngFor="let regularity of regularities.items" [ngValue]="regularity.id">
                              {{ regularity.name }}
                            </option>
                          </select>
                          <label for="regularity_id">Event Regularity</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-4">
                    <div class="form-floating mb-3">
                      <input type="number" class="form-control" id="base_price" placeholder="Base Price"
                        formControlName="base_price" [ngClass]="isInvalid('base_price') ? 'is-invalid' : ''" min="0">
                      <label for="base_price">Base Price</label>

                      <div *ngIf="isInvalid('base_price')" id="username-feedback" class="invalid-feedback">
                        <div *ngIf="form.get('base_price')?.errors?.['required']" i18n>Price is required</div>
                        <div *ngIf="form.get('base_price')?.errors?.['min']" i18n>Price must be at least 0</div>
                      </div>
                    </div>

                    <hr>
                    <p class="m-0 text-muted small">Duration</p>
                    <div class="input-group mb-3">
                      <input type="number" class="form-control" id="duration_quantity" placeholder="Days"
                        formControlName="duration_quantity" min="1">
                      <select class="form-control" name="duration_unit" id="duration_unit"
                        formControlName="duration_unit">
                        <option value="days">Days</option>
                        <option value="hours">Hours</option>
                        <option value="minutes">Minutes</option>
                      </select>
                    </div>
                    <hr>
                    <div class="form-floating mb-3">
                      <input type="number" class="form-control" id="book_before" placeholder="Book Before"
                        formControlName="book_before" min="0">
                      <label for="book_before">Book Before</label>
                    </div>
                    <div class="form-floating mb-3">
                      <input type="number" class="form-control" id="cancel_before" placeholder="Cancel Before"
                        formControlName="cancel_before" min="0">
                      <label for="cancel_before">Cancel Before</label>
                    </div>



                    <hr>
                    <!-- <p class="m-0 fw-bold">Participants</p>
                    <div class="input-group input-group-sm">
                      <label class="input-group-text col text-center rounded-bottom-0 border-bottom-0">Expected</label>
                      <label class="input-group-text col text-center rounded-bottom-0 border-bottom-0">Min</label>
                      <label class="input-group-text col text-center rounded-bottom-0 border-bottom-0">Max</label>
                    </div>
                    <div class="input-group mb-3">
                      <input type="number" class="form-control rounded-top-0" id="expected_participants"
                        placeholder="Expected" formControlName="expected_participants"
                        (change)="onChangeExpected($event)">
                      <input type="number" class="form-control rounded-top-0" id="min_participants" placeholder="Min"
                        formControlName="min_participants">
                      <input type="number" class="form-control rounded-top-0" id="max_participants" placeholder="Max"
                        formControlName="max_participants">
                    </div> -->
                    <div class="form-floating mb-3">
                      <input type="number" class="form-control" id="expected_participants" placeholder="Participants"
                        formControlName="expected_participants" min="1" (change)="onChangeExpected($event)">
                      <label for="expected_participants">Participants</label>
                    </div>
                    <div class="form-floating mb-3">
                      <select class="form-control" name="language_id" id="language_id" formControlName="language_id"
                        [ngClass]="isInvalid('language_id') ? 'is-invalid' : ''">
                        <option value="" selected i18n>Select one...</option>
                        @for (language of languages.items; track language.id) {
                        <option [value]="language.id">{{ language.abbr }} - {{ language.name }}</option>
                        }
                      </select>
                      <label for="language_id" i18n>Language</label>

                      <div *ngIf="isInvalid('language_id')" id="language_id-feedback" class="invalid-feedback">
                        <div *ngIf="form.get('language_id')?.errors?.['required']" i18n>
                          Language is required
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</div>

<app-footer />