import { Component, OnInit } from '@angular/core';
import {
  CommonModule,
  DatePipe,
} from '@angular/common'
import { RouterModule } from '@angular/router';
import { FooterComponent } from '../../../components/public/footer/component';
import { HeaderComponent } from '../../../components/public/header/component';
import { MetaService } from '../../../services/meta.service';

@Component({
  selector: 'app-suppliers-home',
  standalone: true,
  imports: [
    CommonModule,
    HeaderComponent,
    FooterComponent,
    RouterModule,
  ],
  providers: [
    DatePipe,
  ],
  templateUrl: './component.html',
  styleUrl: './component.scss'
})
export class SupplierHomeComponent implements OnInit {

  constructor(
    private meta: MetaService,
  ) {
    this.meta.title($localize`Become a supplier`);
  }

  ngOnInit(): void {
    //
  }
}
