import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import {
  CommonModule,
} from '@angular/common'
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { HeaderComponent } from '../../../../components/admin/header/component';
import { FooterComponent } from '../../../../components/admin/footer/component';
import { SidebarComponent } from '../../../../components/admin/sidebar/component';
import { ActivityCategory } from '../../../../models/activity_category';
import { HttpParams } from '@angular/common/http';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { Fetchervice } from '../../../../services/fetch.service';
import { Regularity } from '../../../../models/regularity';
import { EditorConfig, NgxSimpleTextEditorModule, UNDO_BUTTON, BOLD_BUTTON, ITALIC_BUTTON, SEPARATOR } from 'ngx-simple-text-editor';
import { Validate } from '../../../../services/validator';
import { RouteNamesService } from '../../../../services/route_name.service';
import { Supplier } from '../../../../models/supplier';
import { FormCardHeader } from '../../../../components/admin/common/form_card_header';
import { Link, TabsMenu } from '../../../../components/admin/common/tab_menu';
import { SupplierCategory } from '../../../../models/supplier_category';
import { Language } from '../../../../models/language';

@Component({
  selector: 'app-supplier-form',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    HeaderComponent,
    SidebarComponent,
    FooterComponent,
    NgxSimpleTextEditorModule,
    FormCardHeader,
    TabsMenu,
  ],
  templateUrl: './component.html',
  styleUrl: './component.scss'
})
export class SupplierFormComponent<T extends Supplier>  implements OnInit {
  id?: number;
  title = $localize `New Supplier`;
  form = new FormGroup({
    trade_name: new FormControl('', [Validate.required, Validate.minLength(3), Validate.maxLength(124)]),
    legal_name: new FormControl('', [Validate.required, Validate.minLength(3), Validate.maxLength(124)]),
    document_type_id: new FormControl('', []),
    document_code: new FormControl('', [Validate.required]),
    email: new FormControl('', [Validate.email]),
    webpage: new FormControl('', []),
    address: new FormControl('', []),
    description: new FormControl('', [Validate.required, Validate.minLength(12), Validate.maxLength(255)]),
    notes: new FormControl('', [Validate.maxLength(1024)]),
    fee: new FormControl('', [Validate.min(0.05), Validate.max(1)]),
    status: new FormControl('', [Validate.required]),
    user_id: new FormControl('', []),
    language_id: new FormControl(1, [Validate.required]),
    category_id: new FormControl(1, [Validate.required]),
    activated_at: new FormControl(),
  });

  config: EditorConfig = {
    placeholder: 'Description',
    buttons: [ UNDO_BUTTON, SEPARATOR, BOLD_BUTTON, ITALIC_BUTTON, SEPARATOR ],
  };
  
  model: Fetchervice<T>;
  categories: Fetchervice<SupplierCategory>;
  languages!: Fetchervice<Language>;

  basePath!: string;

  menu: Link[] = [
    //
  ];

  constructor(
    private route: ActivatedRoute,
    private routeNames: RouteNamesService
  ) {
    this.model = Fetchervice.new<T>(this.form);
    this.categories = Fetchervice.new<SupplierCategory>();
    this.languages = Fetchervice.new<Language>();

    this.basePath = this.routeNames.path("admin.supplier.index") as string;
  }

  ngOnInit(): void {
    this.id = this.route.snapshot.params['id'];

    this.model
      .from(Supplier)

    if (this.id) {
      this.menu = getSupplierMenu(this.basePath, `${this.id}`);

      this.model
        .find(this.id, () => {
          this.title = $localize `Update Supplier - ${this.model.attributes?.trade_name}`
        });
    }

    this.categories
      .from(SupplierCategory)
      .perPage(0)
      .cache()
      .get();

    this.languages
      .from(Language)
      .perPage(0)
      .cache()
      .get();
  }

  isInvalid(name: string): boolean {
    const formControl = this.form.get(name);

    if (formControl) {
      return formControl.invalid && (formControl.dirty || formControl.touched)
    }

    return false;
  }
}

export function getSupplierMenu(basePath: string, id: string): Link[] { 
  return [
    {
      path: [basePath, id, 'update'],
      label: "Summary",
    },
    {
      path: [basePath, id, 'contacts'],
      label: "Contacts",
    },
    {
      path: [basePath, id, 'alerts'],
      label: "Alerts",
    },
    {
      path: [basePath, id, 'comments'],
      label: "Internal Comments",
    }
  ]
}