import { Component, OnInit } from '@angular/core';
import {
  CommonModule, DatePipe,
  NgOptimizedImage,
} from '@angular/common'
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
} from '@angular/forms';
import { AuthService, LoginCredentialsI } from '../../../services/auth.service';
import { ToastService } from '../../../services/toats.service';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { HeaderComponent } from '../../../components/public/header/component';
import { FooterComponent } from '../../../components/public/footer/component';
import { LoadingService } from '../../../services/loading.service';
import { Validate } from '../../../services/validator';
import { RouteNamesService } from '../../../services/route_name.service';
import { MetaService } from '../../../services/meta.service';
import { Fetchervice } from '../../../services/fetch.service';
import { Activity } from '../../../models/activity';
import { getEnv } from '../../../services/config.service';
import { SquareArticle } from '../../../components/public/article/square';

@Component({
  selector: 'app-dashboard',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RouterModule,
    HeaderComponent,
    FooterComponent,
    NgOptimizedImage,
    SquareArticle,
  ],
  providers: [
    DatePipe,
  ],
  templateUrl: './component.html',
  styleUrl: './component.scss'
})
export class BookmarkComponent implements OnInit {
  model: Fetchervice<Activity>;
  fetch: Fetchervice<any>;

  constructor(
    private meta: MetaService,
  ) {
    this.meta.title($localize`Wishlists`);
    this.model = Fetchervice.new<Activity>();
    this.fetch = Fetchervice.new<any>();
  }

  ngOnInit(): void {
    this.load();
  }

  load(): void {
    this.model
      .from("/api/v1/bookmarked")
      .relations(["images", "category", "destination"])
      .where("bookmarked", "true")
      .get();
  }

  bookmark(activity: Activity) {
    activity.bookmarked = true;
    
    this.fetch.post(`${getEnv("API_URL")}/api/v1/activity/${activity.id}/bookmark`)
    .then(() => {
      this.load()
    })
  }

  unbookmark(activity: Activity) {
    activity.bookmarked = false;
    
    this.fetch.post(`${getEnv("API_URL")}/api/v1/activity/${activity.id}/unbookmark`)
    .then(() => {
      this.load()
    })
  }
}
