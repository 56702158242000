<div class="py-2 px-5 border-bottom">
  <nav class="navbar navbar-expand-lg navbar-light">
    <a class="navbar-brand">Xplora APP</a>
  </nav>
</div>
<ul class="nav nav-pills flex-column mb-auto px-3">
  @for (item of menu; track item.routeName) {
  <li class="nav-item">
    <a [routerLink]="[item.path]" routerLinkActive="bg-light" class="nav-link text-dark">
      {{ item.title }}
    </a>
  </li>
  }
</ul>
<hr>
<div class="dropdown">
  <a href="#" class="d-flex align-items-center text-white text-decoration-none dropdown-toggle"
    data-bs-toggle="dropdown" aria-expanded="false">
    <img src="https://github.com/mdo.png" alt="" width="32" height="32" class="rounded-circle me-2">
    <strong>mdo</strong>
  </a>
  <ul class="dropdown-menu dropdown-menu-dark text-small shadow">
    <li><a class="dropdown-item" href="#">New project...</a></li>
    <li><a class="dropdown-item" href="#">Settings</a></li>
    <li><a class="dropdown-item" href="#">Profile</a></li>
    <li>
      <hr class="dropdown-divider">
    </li>
    <li><a class="dropdown-item" href="#">Sign out</a></li>
  </ul>
</div>