@if (item.bookmarked) {
<span class="position-absolute z-1 top-0 end-0 pt-1 pe-3 text-main" (click)="unbookmark(item)">
  <i role="button" class="fa-solid fa-heart"></i>
</span>
}
@else {
<span class="position-absolute z-1 top-0 end-0 pt-1 pe-3 text-white" (click)="bookmark(item)">
  <i role="button" class="fa-regular fa-heart"></i>
</span>
}
<a class="text-decoration-none" [routerLink]="['/activity', item.id, item.slug]">
  <div class="card shadow-sm h-100 img-hover position-relative overflow-hidden">
    @if (item.regularity_id == 1 || item.tags && item.tags.length > 0) {
    <div class="position-absolute z-1 top-0 start-0 mt-3" style="margin-left: -8px;">
      @if (item.regularity_id == 1) {
      <span class="badge bg-light text-dark ps-5" i18n>Coming Soon</span>
      <br>
      }
      <!-- @else if (item.created_at) {
      <span class="badge bg-success bg-gradient ps-5 pe-3" i18n>New</span>
      <br>
    } -->
      @if (item.tags && item.tags.length > 0) {
      @for (tag of item.tags; track tag.id) {
      <span class="badge bg-black bg-gradient ps-5">{{ getTagName(tag.name) }}</span>
      <br>
      }
      }
    </div>
    }
    <picture class="overflow-hidden d-block">
      @if (item.images && item.images.length > 0) {
      <img class="card-img-top" [src]="item.images[0].path" [alt]="item.display_title" fetchpriority="high"
        rel="preload">
      }
      @else {
      <img class="card-img-top" src="https://loremflickr.com/270/180/nature" [alt]="item.display_title"
        fetchpriority="high" rel="preload">
      }
    </picture>

    <div class="card-body pb-0 pt-2">
      <p class="mb-0 text-uppercase text-secondary fw-bold small-3">
        {{ item.category?.name }}
      </p>
      <h3 class="card-title fs-5 fw-900">
        {{ item.display_title }}
      </h3>
      <p class="card-text fst-italic small-2">
        {{ item.duration_quantity }}
        {{ item.duration_unit }}
      </p>
    </div>

    <div class="card-footer bg-white border-0">
      <div class="d-flex justify-content-between align-items-center">
        <span class="fw-bold text-main small">
          <span>Desde</span><span>&nbsp;{{ item.base_price | number }}$&nbsp;</span>
          <small class="text-muted fst-italic">
            por persona
          </small>
        </span>
        <small class="fw-bold">
          {{ item.destination.name }}
        </small>
      </div>
    </div>
  </div>
</a>