<div class="d-flex flex-nowrap border rounded vh-100">
  <app-sidebar class="d-flex flex-column flex-shrink-0 text-dark bg-white" style="width: 280px;" />

  <div class="d-flex flex-column flex-grow-1">
    <app-header />

    <main class="container-fluid h-100">
      <div class="row">
        <div class="col px-0">
          <div class="card border-0">
            <div class="card-header d-flex justify-content-between p-2">
              <h3 class="card-title m-0" i18n>Administration</h3>
              <p>Hello {{ user.name }}</p>
            </div>
            <div class="card-body">
              
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</div>

<app-footer />