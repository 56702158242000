import { Injectable } from "@angular/core";
import { Route, Routes } from "@angular/router";

@Injectable({ providedIn: 'root' })
export class RouteNamesService {
    public static routes: Routes = [];

    path(
        name: string
    ): string | undefined {
        const namesArray = name.split('.');
        let path = '';
        let previousRoute: Route | undefined;

        for (let index = 0; index < namesArray.length; index++) {
            if (index == 0) {
                previousRoute = this.doFind(RouteNamesService.routes, namesArray[index]);
            } else {
                if (previousRoute?.children) {
                    previousRoute = this.doFind(previousRoute?.children, namesArray[index])
                } else {
                    previousRoute = undefined;
                }
            }

            if (previousRoute) {
                path += `/${previousRoute.path}`;
            } else {
                return;
            }
            
        }

        return path;
    }

    doFind(routes: Route[], name: string): Route | undefined {
        for (const route of routes) {
            if (route.data) {
                if (route.data["name"] == name) {
                    return route;
                }
            }
            
        }

        return;
    }
}
