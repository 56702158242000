import { Component, OnInit } from '@angular/core';
import {
  CommonModule, DatePipe,
} from '@angular/common'
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
} from '@angular/forms';
import { AuthService, LoginCredentialsI } from '../../services/auth.service';
import { ToastService } from '../../services/toats.service';
import { ActivatedRoute, Router } from '@angular/router';
import { HeaderComponent } from '../../components/public/header/component';
import { FooterComponent } from '../../components/public/footer/component';
import { LoadingService } from '../../services/loading.service';
import { Validate } from '../../services/validator';
import { RouteNamesService } from '../../services/route_name.service';

@Component({
  selector: 'app-internal-login',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    HeaderComponent,
    FooterComponent,
  ],
  providers: [
    DatePipe,
  ],
  templateUrl: './component.html',
  styleUrl: './component.scss'
})
export class InternalLoginComponent implements OnInit {
  form = new FormGroup({
    username: new FormControl('', [Validate.required, Validate.minLength(3)]),
    password: new FormControl('', [Validate.required, Validate.minLength(8)]),
  });

  model: LoginModel;

  constructor(
    private auth: AuthService,
    public toastService: ToastService,
    public loading: LoadingService,
    private route: ActivatedRoute,
    private router: Router,
    private routeNames: RouteNamesService,
    private date: DatePipe,
  ) {
    this.model = new LoginModel(this.form);
    // this.toastService.show({ header: "CONSTRUCTOR"})
  }

  ngOnInit(): void {
    this.loading.hide();
  }

  get username(): AbstractControl {
    return this.form.get('username') as AbstractControl;
  }

  get password(): AbstractControl {
    return this.form.get('password') as AbstractControl;
  }

  onSubmit() {
    this.loading.show();
    const scope = this.route.snapshot.data["scope"];

    this.auth.login(scope, this.form.value as LoginCredentialsI)
      .then(data => {

        if (data.user.last_login_at) {
          this.toastService.show({ header: $localize`Welcome ${data.user.name}`, body: $localize`Last login ${this.date.transform(data.user.last_login_at, "yyyy-MM-dd hh:mm:ss")}`, classname: "bg-success text-light" });
        } else {
          this.toastService.show({ header: $localize`Welcome ${data.user.name}`, body: $localize`We're excited to have you here.`, classname: "bg-success text-light" });
        }
        if (scope == "supplier") {
          this.router.navigate([this.routeNames.path("suppliers.dashboard")]);
        } else if (scope == "admin") {
          this.router.navigate([this.routeNames.path("admin.dashboard")]);
        }
      }, error => {
        this.toastService.show({ header: "ERROR", body: error.error?.message ?? "Error", classname: "bg-danger text-light", })
      }).finally(() => {
        this.loading.hide();
      })
  }
}

class LoginModel {
  constructor(
    public form: FormGroup<{
      username: FormControl<string | null>;
      password: FormControl<string | null>;
    }>
  ) {
    //
  }

  isInvalid(name: "username" | "password"): boolean {
    const formControl = this.form.get(name);

    if (formControl) {
      return formControl.invalid && (formControl.dirty || formControl.touched)
    }

    return false;
  }
}