import { Component, OnInit } from '@angular/core';
import {
  CommonModule,
} from '@angular/common'
import { AuthService, LoginResponse } from '../../../services/auth.service';
import { RouterModule } from '@angular/router';
import { HeaderComponent } from '../../../components/admin/header/component';
import { FooterComponent } from '../../../components/admin/footer/component';
import { SidebarComponent } from '../../../components/admin/sidebar/component';
import { LoadingService } from '../../../services/loading.service';

@Component({
  selector: 'app-suppliers-profile',
  standalone: true,
  imports: [
    CommonModule,
    HeaderComponent,
    SidebarComponent,
    FooterComponent,
  ],
  templateUrl: './component.html',
  styleUrl: './component.scss'
})
export class SuppliersProfileComponent implements OnInit {

  constructor(
  ) {
  }

  ngOnInit(): void {
  }
}
