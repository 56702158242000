import { Component, inject, Input, OnInit } from '@angular/core';
import { FormGroup, FormControl, ReactiveFormsModule } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Validate } from '../../../../services/validator';
import { CommonModule } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { Fetchervice } from '../../../../services/fetch.service';
import { SupplierContact } from '../../../../models/supplier_contacts';
import { ActivityDetail } from '../../../../models/activity_detail';
import { ActivityAttribute } from '../../../../models/activity_attribute';

@Component({
  selector: 'app-supplier-attributes-form',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
  ],
  template: `
<div *ngIf="isReady">
  <div class="modal-header">
    <h4 class="modal-title nowrap">{{ title }}</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <form class="form needs-validation" novalidate [formGroup]="form">
      <div class="form-group mb-3">
        <label for="title" i18n>Template</label>
        <select id="attribute" name="attribute" class="form-control" formControlName="attribute" (change)="onAttributeChange($event)">
          <option value="" i18n>Enable editing</option>
        @for (attr of attributes.items; track attr.id) {
          <option [value]="attr.id">{{ (attr.name + ' - ' + attr.description).substring(0, 56) }}</option>
        }
        </select>
      </div>
      <div class="form-group mb-3">
        <label for="title" i18n>Title</label>
        <input type="text" class="form-control" id="title" placeholder="Name" formControlName="title"
          [ngClass]="isInvalid('title') ? 'is-invalid' : ''" [readonly]="readOnly.title ? 'true' : '' ">
      </div>
      <div class="form-group mb-3">
        <label for="description" i18n>Description</label>
        <textarea class="form-control" id="description" placeholder="Description" formControlName="description"
          [ngClass]="isInvalid('description') ? 'is-invalid' : ''" rows="3" [readonly]="readOnly.description"></textarea>
      </div>
      <div class="form-group mb-3">
        <div class="form-check form-switch">
          <input class="form-check-input" type="checkbox" role="switch" id="value" formControlName="value">
          <label class="form-check-label" for="value">
          Yes or No
          </label>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss()" i18n>Cancel</button>
    <button type="button" class="btn btn-danger" (click)="modal.close(model)" [disabled]="!form.valid"
      i18n>Save</button>
  </div>
</div>
    `,
})
export class FormActivityDetail<T extends ActivityDetail> implements OnInit {
  modal = inject(NgbActiveModal);
  isReady = false;

  title = $localize`Create Activity Attribute`;

  @Input() id?: number;
  @Input() parentId?: number;

  form = new FormGroup({
    attribute: new FormControl(''),
    title: new FormControl(''),
    description: new FormControl(''),
    value: new FormControl(false, [Validate.required]),
    type: new FormControl("attribute"),
  });
  readOnly = {
    title: false,
    description: false,
  }
  model = Fetchervice.new<T>(this.form);
  attributes = Fetchervice.new<ActivityAttribute>();

  constructor(
    // private route: ActivatedRoute,
  ) {
  }

  ngOnInit(): void {
    this.model
      .from(`/api/v1/suppliers/activity/${this.parentId}/details`)
      .where("type", "attribute")
    
    this.attributes
      .from(ActivityAttribute)
      .perPage(0)
      .cache(true)
      .get();

    if (this.id) {
      this.model
        .find(this.id, () => {
          this.title = $localize`Update Activity Question - #${this.model.attributes.id}`;
          this.form.get("title")?.setValue(this.model.attributes.title);
          this.form.get("description")?.setValue(this.model.attributes.description);
          this.form.get("value")?.setValue(this.model.attributes.value);
          this.isReady = true;

          const title = this.model.attributes.title;

          const attr = this.attributes.items.find(i => i.name == title);
          
          if (attr) {
            this.form.get("attribute")?.setValue(`${attr.id}`);
            this.readOnly.title = true;
            this.readOnly.description = true;
          }
        });
    } else {
      this.form.get("title")?.enable();
      this.form.get("description")?.enable();
      this.isReady = true;
    }
  }

  isInvalid(name: string): boolean {
    const formControl = this.form.get(name);

    if (formControl) {
      return formControl.invalid && (formControl.dirty || formControl.touched)
    }

    return false;
  }

  onAttributeChange(event: any) {
    const value = event.target.value;
    const title = this.form.get("title");
    const description = this.form.get("description");

    if (value) {
      const attr = this.attributes.items.find(i => i.id == value);
      if (attr) {
        this.readOnly.title = true;
        this.readOnly.description = true;

        title?.setValue(attr.name);
        description?.setValue(attr.description);
      }
    } else {
      this.readOnly.title = false;
      this.readOnly.description = false;
    }

    console.log(value, value);
    
  }
}