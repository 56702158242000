import { AbstractControl, ValidationErrors, ValidatorFn, Validators } from "@angular/forms";

export class Validate extends Validators {
    static noWhitespaceValidator(control: AbstractControl): ValidationErrors | null {
        return (control.value || '').trim().length? null : { 'hitespace': true };       
    }
    static in(values: any[]): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            return values.includes(control.value) ? null : { in: true }
        }
    }
    static orLength(lengths: number[]): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            console.log(control.value, control.value.length);
            for (const l of lengths) {
                
                if (control.value.length == l) {
                    return null;
                }
            }
            return { orLength: true }
        }
    }
}