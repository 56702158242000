<main>
  <section class="container-fluid p-0 d-none d-md-block ">
    <div class="min-vh-100 position-relative">
      <img class="position-absolute top-0 start-0 endo-0 w-100 h-100 object-fit-cover z-n1"
        src="assets/img/inicio.png">
      <app-header color="text-white" />
      <div class="container-md pt-5">
        <h1 id="main-title" class="text-center text-white">
          Xplora con nosotros
        </h1>
        <p class="text-center text-white">
          todo lo que tu ciudad favorita tiene para ofrecer
        </p>
        <div class="px-lg-5 pb-5 mt-5 mx-lg-5">
          <div class="rounded-pill bg-white hstack">
            <div class="mx-auto text-center" role="button" ngbDropdown>
              <div ngbDropdownToggle>
                @if (category_id) {
                <p class="m-0 fw-bold">{{ getCategoryName(category_id) }}</p>
                }
                @else {
                <p class="m-0 fw-bold">Categoría</p>
                }
                <p class="m-0 text-secondary small fst-italic">¿Qué quieres hacer?</p>
              </div>
              <div ngbDropdownMenu>
                <ul class="list-group list-group-flush">
                  @for (item of categories.items; track item.id) {
                  <li class="list-group-item px-5" ngbDropdownItem (click)="setCategoryId(item.id)"
                    [class]="item.id == category_id ? 'active' : ''">
                    <i class="fa-solid fa-compass pe-2"></i>
                    <span class="fw-bold">{{ item.name }}</span>
                    <br>
                    <span class="fst-italic small text-muted">{{ item.description }}</span>
                  </li>
                  }
                </ul>
              </div>
            </div>
            <div class="vr"></div>
            <div class="mx-auto text-center" role="button" ngbDropdown>
              <div ngbDropdownToggle>
                @if (location_id) {
                <p class="m-0 fw-bold">{{ getLocationName(location_id) }}</p>
                }
                @else {
                <p class="m-0 fw-bold">Ciudad</p>
                }
                <p class="m-0 text-secondary small fst-italic">¿Dónde lo quieres hacer?</p>
              </div>
              <div ngbDropdownMenu>
                <ul class="list-group list-group-flush">
                  @for (item of locations.items; track item.id) {
                  <li class="list-group-item px-5" ngbDropdownItem (click)="setLocationId(item.id)"
                    [class]="item.id == location_id ? 'active' : ''">
                    <i class="fa-solid fa-location-dot pe-2"></i>
                    <span class="fw-bold">{{ item.name }}</span>
                  </li>
                  }
                </ul>
              </div>
            </div>
            <div class="vr"></div>
            <div class="mx-auto text-center p-0" role="button" ngbDropdown>
              <div ngbDropdownToggle>
                @if (date) {
                <p class="m-0 fw-bold">{{ date }}</p>
                }
                @else {
                <p class="m-0 fw-bold">Calendario</p>
                }
                <p class="m-0 text-secondary small fst-italic">¿Cuándo lo quieres hacer?</p>
              </div>
              <div ngbDropdownMenu>
                <ngb-datepicker #dp [(ngModel)]="date" [minDate]="startDate" />
              </div>
            </div>
            <div class="ms-3 m-2">
              <button class="btn btn-primary rounded-pill py-3 px-4" (click)="search()">
                <i class="fa-solid fa-magnifying-glass me-2"></i>
                Buscar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="container-fluid p-0 d-block d-md-none">
    <app-header color="text-dark" />
  </section>

  <!-- <section class="container-fluid p-5 pt-0">
    <div class="p-5 bg-body-tertiary rounded-3 shadow my-5">
      <h1 class="text-body-emphasis">
        
        <br>
        
        <br>
        tiene para ofrecer
      </h1>
      <p class="col-lg-8 fs-5 text-muted">
        Full Days, Trekking, Recorridos Históricos,
        <br>
        Actividades Culturales, Deportivas y mucho más.
      </p>
      <div class="mb-5">
        <p class="mb-0 fst-italic text-muted">Descarga la APP y conviértete en un Xplorador</p>
        <button class="btn btn-outline-secondary btn-lg px-4 rounded-pill px-5 me-3" type="button">
          <i class="fa-brands fa-google-play"></i>&nbsp;&nbsp;Google Play
        </button>
        <button class="btn btn-outline-secondary btn-lg px-4 rounded-pill px-5" type="button">
          <i class="fa-brands fa-apple"></i>&nbsp;&nbsp;App Store
        </button>
      </div>
    </div>
  </section> -->

  <section class="container-fluid bg-white pb-5 pt-3">
    <div class="container-md my-5">
      <hr>
      <h2 i18n>The Most Xplored</h2>
      <hr>
      <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 g-3">
        @for (item of main.items; track item.id) {
        <article square [item]="item"></article>
        }
      </div>
    </div>
  </section>

  <section class="container-fluid pb-5 pt-3">
    <div class="container-md my-5">
      <hr>
      <h2 i18n>Top Xplorations on Caracas</h2>
      <hr>
      <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 g-3">
        @for (item of caracas.items; track item.id) {
        <article square [item]="item"></article>
        }
      </div>
    </div>
  </section>

  <section class="container-fluid bg-white pb-5 pt-3">
    <div class="container-md my-5">
      <hr>
      <h2 i18n>Top Xplorations on La Guaira</h2>
      <hr>
      <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 g-3">
        @for (item of laGuaira.items; track item.id) {
        <article square [item]="item"></article>
        }
      </div>
    </div>
  </section>
</main>

<app-footer />