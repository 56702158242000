import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';

@Component({
	selector: 'app-footer',
	standalone: true,
	imports: [ CommonModule ],
	templateUrl: './component.html',
})
export class FooterComponent {
	currentDate = new Date();
}