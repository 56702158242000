import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class CacheService {
  // BehaviorSubject that will contain the updated cache data.
  public cache$ = new BehaviorSubject<any[] | undefined>(undefined);

  constructor(
    private storage: StorageService
  ) {
    //
  }

  // The 'set' method for storing data in the cache.
  set(key: string, data: any[]): void {
    // If there is no data for this key, we store it in the cache and update the BehaviorSubject.
    this.storage.set(key, data);
    this.cache$.next(this.storage.get(key));
  }

  // The 'get' method for retrieving data from the cache.
  get(key: string): any[] | [] {
    // We retrieve the data from the cache and update the BehaviorSubject.
    const data = this.storage.get(key);
    this.cache$.next(data);
    return data ?? [];
  }

  has(key: string): boolean {
    return this.storage.has(key);
  }

  // The 'clear' method to clear data from the cache.
  clear(key: string): void {
    // We remove the data from the cache and update the BehaviorSubject.
    this.storage.delete(key);
    this.cache$.next(undefined);
  }
}