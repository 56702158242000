import {
  Component,
  OnInit,
  QueryList,
  ViewChildren,
  inject
} from '@angular/core';
import {
  CommonModule,
} from '@angular/common'
import { AuthService } from '../../../../services/auth.service';
import { Router, RouterModule } from '@angular/router';
import { HeaderComponent } from '../../../../components/admin/header/component';
import { FooterComponent } from '../../../../components/admin/footer/component';
import { SidebarComponent } from '../../../../components/admin/sidebar/component';
import { Activity } from '../../../../models/activity';
import { SortEvent, SortableHeaderDirective } from '../../../../directive/sorteable_header.directive';
import { HttpErrorResponse, HttpParams } from '@angular/common/http';
import { ToastService } from '../../../../services/toats.service';
import { LoadingService } from '../../../../services/loading.service';
import { Fetchervice } from '../../../../services/fetch.service';
import { RouteNamesService } from '../../../../services/route_name.service';
import { Confirm } from '../../../../components/admin/common/confirm';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-activity',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    HeaderComponent,
    SidebarComponent,
    FooterComponent,
    SortableHeaderDirective,
  ],
  templateUrl: './component.html',
  styleUrl: './component.scss'
})
export class ActivityIndexComponent<T extends Activity> implements OnInit {
  model!: Fetchervice<T>;
  @ViewChildren(SortableHeaderDirective) headers!: QueryList<SortableHeaderDirective>;
  basePath!: string;

  table = {
    header: [
      {
        name: "id",
        label: "#"
      },
      {
        name: "title",
        label: $localize`Title`
      },
      {
        name: "category.name",
        label: $localize`Cartegory`
      },
      {
        name: "base_price",
        label: $localize`Price`
      },
      {
        name: "status",
        label: $localize`Status`
      },
    ]
  }

  constructor(
    public toastService: ToastService,
    private loading: LoadingService,
    private routeNames: RouteNamesService,
    private modalService: NgbModal,
  ) {
    this.model = Fetchervice.new();
    this.basePath = this.routeNames.path("suppliers.activity.index") as string;
  }

  ngOnInit(): void {
    this.loading.hide();
    this.model
      .from("/api/v1/suppliers/activity")
      .orderBy("id", "asc")
      .relations(["category"])
      .get()
      ;
  }

  onSort(event: SortEvent<T>) {
    this.model
      .orderBy(event.column, event.direction)
      .paginate(1)
      .get();
  }

  perPage(event: any) {
    this.model
      .paginate(1, event.target.value)
      .get()
      ;
  }

  setPage(n: number): void {
    this.model
      .paginate(n)
      .get()
      ;
  }

  delete(id: number) {
    const modalRef = this.modalService.open(Confirm);

    modalRef.result.then(() => {
      this.model.delete(id);
    }).catch((error) => {
      console.log(error);
    });
  }

  pause(id: number) {
    const modalRef = this.modalService.open(Confirm);
    modalRef.componentInstance.description = $localize`By deactivating the activity, it is no longer published on our platform. To reactivate it, it must be reviewed and approved again by our administrators.`;

    modalRef.result.then(() => {
      this.model.update(id, {
        status: 0,
      }, () => {
        this.model.get();
      });
    }).catch((error) => {
      console.log(error);
    });
  }

  play(id: number) {
    this.model.update(id, {
      status: 1,
    }, () => {
      this.model.get();
    });
  }
}
