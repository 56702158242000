import {
	Entity,
	Listable,
	Metadata,
	ModelI
} from "./model";

@Entity({
    url: "/api/v1/supplier_comment"
})
export class SupplierComment implements ModelI {
	id!: number;
	message!: string;
	author_id!: string;
	created_at!: string;
}
