<div class="d-flex flex-nowrap border rounded min-vh-100">

  <app-sidebar class="d-flex flex-column flex-shrink-0 text-dark bg-white border-end" style="width: 280px;" />

  <div class="d-flex flex-column flex-grow-1">
    <app-header />

    <main class="container-fluid h-100">
      <div class="row">
        <div class="col px-0">
          <div class="card border-0">
            <div class="card-header d-flex justify-content-between p-2">
              <h3 class="card-title m-0" i18n>Activities</h3>
              <div class="btn-group" role="group">
                <a [routerLink]="[basePath, 'create']" class="btn btn-outline-success" i18n>New</a>
              </div>
            </div>
            <div class="card-body">
              <div class="table-responsive">
                <table class="table table-hover table-striped">
                  <thead>
                    <tr>
                      @for (th of table.header; track th.name) {
                      <th role="button" scope="col" [sortable]="th.name"
                        (sort)="model.orderBy($event.column, $event.direction).paginate(1).get()">
                        <span class="pe-2">{{ th.label }}</span>
                        @if (model.getOrderBy() == th.name + ':asc') {
                        <i class="fa-solid fa-sort-up"></i>
                        }
                        @else if (model.getOrderBy() == th.name + ':desc') {
                        <i class="fa-solid fa-sort-down"></i>
                        }
                        @else {
                        <i class="fa-solid fa-sort"></i>
                        }
                      </th>
                      }
                      <th i18n>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    @for (item of model.items; track item.id) {
                    <tr>
                      <th scope="row">{{ item.id | number:'3.0' }}</th>
                      <td><a [routerLink]="[basePath, item.id, 'update']">{{ item.title }}</a></td>
                      <td>{{ item.category.name }}</td>
                      <td>{{ item.base_price | number: '1.2-2' }}</td>
                      <td>{{ item.status == 1 ? "Active" : "Inactive" }}</td>
                      <td class="text-center">
                        @if (item.status == 1) {
                        <span class="text-secondary small mx-1" role="button" (click)="pause(item.id)">
                          <i class="fa-solid fa-circle-pause"></i>
                        </span>
                        }
                        @else {
                        <span class="text-success small mx-1" role="button" (click)="play(item.id)">
                          <i class="fa-solid fa-circle-play"></i>
                        </span>
                        }
                        <span class="text-danger small mx-1" role="button" (click)="delete(item.id)">
                          <i class="fa-solid fa-trash"></i>
                        </span>
                      </td>
                    </tr>
                    } @empty {
                    <tr>
                      <td colspan="6" style="text-align: center">No items found</td>
                    </tr>
                    }
                  </tbody>
                  <tfoot>
                    <tr>@for (th of table.header; track th.name) {
                      <th>{{ th.label }}</th>
                      }
                      <th i18n>Actions</th>
                    </tr>
                  </tfoot>
                </table>

                @if (model.items.length > 0) {
                <div class="d-flex justify-content-between p-2">
                  <nav>
                    <ul class="pagination">
                      @if (model.pagination.current == 1) {
                      <li class="page-item disabled">
                        <span class="page-link" role="button">&laquo;</span>
                      </li>
                      }
                      @else {
                      <li class="page-item" (click)="setPage(1)">
                        <span class="page-link" role="button">&laquo;</span>
                      </li>
                      }
                      @for (page of model.pagination.pages; track page) {
                      @if (page == model.pagination.current) {
                      <li class="page-item active">
                        <span class="page-link" role="button">{{ page }}</span>
                      </li>
                      } @else {
                      <li class="page-item" (click)="setPage(page)">
                        <span class="page-link" role="button">{{ page }}</span>
                      </li>
                      }
                      }
                      @if (model.pagination.current == model.pagination.last) {
                      <li class="page-item disabled">
                        <span class="page-link" role="button">&raquo;</span>
                      </li>
                      }
                      @else {
                      <li class="page-item" (click)="setPage(model.pagination.last)">
                        <span class="page-link" role="button">&raquo;</span>
                      </li>
                      }
                    </ul>
                  </nav>
                  <select class="form-select w-auto" (change)="perPage($event)">
                    <option value="15">15 items per page</option>
                    <option value="30">30 items per page</option>
                    <option value="50">50 items per page</option>
                    <option value="100">100 items per page</option>
                  </select>
                </div>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</div>

<app-footer />