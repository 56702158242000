import {
  Component,
  OnInit,
  QueryList,
  ViewChildren
} from '@angular/core';
import {
  CommonModule,
} from '@angular/common'
import { RouterModule } from '@angular/router';
import { HeaderComponent } from '../../../../components/admin/header/component';
import { FooterComponent } from '../../../../components/admin/footer/component';
import { SidebarComponent } from '../../../../components/admin/sidebar/component';
import { SortEvent, SortableHeaderDirective } from '../../../../directive/sorteable_header.directive';
import { ToastService } from '../../../../services/toats.service';
import { LoadingService } from '../../../../services/loading.service';
import { Fetchervice } from '../../../../services/fetch.service';
import { RouteNamesService } from '../../../../services/route_name.service';
import { Supplier } from '../../../../models/supplier';
import { IndexCardHeader } from '../../../../components/admin/common/index_card_header';
import { Table } from '../../../../components/admin/common/table';

@Component({
  selector: 'app-supplier-index',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    HeaderComponent,
    SidebarComponent,
    FooterComponent,
    IndexCardHeader,
    Table,
  ],
  templateUrl: './component.html',
  styleUrl: './component.scss'
})
export class SupplierIndexComponent<T extends Supplier> implements OnInit {
  model!: Fetchervice<T>;
  @ViewChildren(SortableHeaderDirective) headers!: QueryList<SortableHeaderDirective>;
  basePath!: string;
  title = $localize `Suppliers`;

  constructor(
    public toastService: ToastService,
    private loading: LoadingService,
    private routeNames: RouteNamesService
  ) {
    this.model = Fetchervice.new<T>();

    this.basePath = this.routeNames.path("admin.supplier.index") as string;
  }

  ngOnInit(): void {
    this.loading.hide();
    this.model
      .from(Supplier)
      .orderBy("id", "asc")
      .get()
    ;
  }
}
