import { Injectable } from '@angular/core';
import { getEnv } from './config.service';
import { Title } from '@angular/platform-browser';

@Injectable({
    providedIn: 'root'
})
export class MetaService {
    private baseTitle = getEnv("APP_NAME");

    constructor(private titleService: Title) {

    }

    public title(str: string): void {
        this.titleService.setTitle(`${this.baseTitle} | ${str}`);
    }
}