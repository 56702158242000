import { Component, inject, Input, OnInit } from '@angular/core';
import { FormGroup, FormControl, ReactiveFormsModule } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Validate } from '../../../../services/validator';
import { CommonModule } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { Fetchervice } from '../../../../services/fetch.service';
import { SupplierContact } from '../../../../models/supplier_contacts';
import { ActivityAttribute } from '../../../../models/activity_attribute';
import { ActivityDate } from '../../../../models/activity_date';

@Component({
  selector: 'app-activity-dates-form',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
  ],
  template: `
  <div class="modal-header">
    <h4 class="modal-title nowrap">{{ title }}</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss()"></button>
  </div>
  <div class="modal-body">
    <form class="form needs-validation" novalidate [formGroup]="form">
      <div class="row gx-3">
        <div class="form-group mb-3 col-sm-6">
          <label for="starts_at" i18n>Starts At</label>
          <input type="date" class="form-control" id="starts_at" placeholder="Starts At" formControlName="starts_at"
            [ngClass]="isInvalid('starts_at') ? 'is-invalid' : ''">
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss()" i18n>Cancel</button>
    <button type="button" class="btn btn-danger" (click)="modal.close(model)" [disabled]="!form.valid"
      i18n>Save</button>
  </div>
    `,
})
export class FormActivityDate<T extends ActivityDate> implements OnInit {
  modal = inject(NgbActiveModal);

  title = $localize`Create Activity Date`;

  @Input() id?: number;
  @Input() parentId?: number;

  form = new FormGroup({
    starts_at: new FormControl('', [Validate.required]),
  });
  model = Fetchervice.new<T>(this.form);

  constructor(
    // private route: ActivatedRoute,
  ) {
  }

  ngOnInit(): void {
    this.model
      .from(`/api/v1/suppliers/activity/${this.parentId}/dates`)

    if (this.id) {
      this.model
        .find(this.id, () => {
          this.title = $localize`Update Activity Question - #${this.model.attributes.id}`;
          this.form.get("current_available")?.disable();
        });
    }
  }

  isInvalid(name: string): boolean {
    const formControl = this.form.get(name);

    if (formControl) {
      return formControl.invalid && (formControl.dirty || formControl.touched)
    }

    return false;
  }
}