import { CommonModule } from '@angular/common';
import { Component, HostListener, Inject, InjectionToken, Input, OnInit } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Router, RouterModule } from '@angular/router';
import { NgbCollapseModule, NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from '../../../services/auth.service';
import { RouteNamesService } from '../../../services/route_name.service';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { Validate } from '../../../services/validator';
import { style } from '@angular/animations';

@Component({
    selector: 'app-header',
    standalone: true,
    imports: [
        CommonModule,
        RouterModule,
        NgbDropdownModule,
        ReactiveFormsModule,
        NgbCollapseModule,
    ],
    templateUrl: './component.html',
    styleUrl: './component.scss',
    // host: {
    //     class: "bg-main fixed-top",
    // }
})
export class HeaderComponent {
    @Input() color = "text-dark";
    window = new InjectionToken<Window>('WindowToken');
    name?: string;
    providersLoginRoute = this.routeNames.path("suppliers.login");
	// Step 1:
	// Create a property to track whether the menu is open.
	// Start with the menu collapsed so that it does not
	// appear initially when the page loads on a small screen!
	isMenuCollapsed = true;
	isMenuSticky = false;
    constructor(
        private auth: AuthService,
        private router: Router,
        private routeNames: RouteNamesService,
    ) {
        if (this.auth.user) {
            this.name = this.auth.user.name;
        }
    }

    logout(): void {
        this.auth.logout();
        this.router.navigate([this.routeNames.path(this.auth.getLoginPath())]);
    }
    
    @HostListener('document:scroll', ['$event'])
    onScroll($e: any) {
        const offset = ($e.target as Document).documentElement.scrollTop;

        if (offset > 100) {
            this.isMenuSticky = true;
        } else {
            this.isMenuSticky = false;
        }

        console.log(offset);
        
    }
}