import { CommonModule } from "@angular/common";
import { Component, Input, OnInit, inject } from "@angular/core";
import { FormControl, FormGroup, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { Fetchervice } from "../../../services/fetch.service";
import { Validate } from "../../../services/validator";
import { NgxMaskDirective, provideNgxMask } from "ngx-mask";
import { AuthService } from "../../../services/auth.service";

@Component({
  selector: 'app-modal-confirm',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    NgxMaskDirective,
  ],
  // template: ``
  templateUrl: './component_bpay.html',
})
export class PagoInmediato implements OnInit {
  @Input() reference!: string;
  form = new FormGroup({
    reference: new FormControl(),
    transaction: new FormControl(),
    name: new FormControl("", [Validate.required, Validate.minLength(3), Validate.maxLength(140)]),
    bank: new FormControl("0169", [Validate.required]),
    id_type: new FormControl('SCID', [Validate.required, Validate.in(["SCID", "SRIF", "SPAS"])]),
    identifier: new FormControl("", [Validate.required, Validate.min(100000), Validate.max(999999999)]),
    account: new FormControl("", [Validate.required, Validate.orLength([11, 20])]),
    otp: new FormControl("")
  });

  fetch = inject(Fetchervice);
  modal = inject(NgbActiveModal);
  public namePattern = {
    A: { pattern: new RegExp('[0-9A-Za-z-ÁáÉéÍíÓóÚúÜüÑñ ,.]') },
  };

  requestOTP = true;

  banks = [
    // { code: '0001', name: "Banco Central de Venezuela", },
    { code: '0102', name: "Banco de Venezuela, S.A. Banco Universal", display_name: "Venezuela" },
    { code: '0104', name: "Banco Venezolano de Crédito, S.A. Banco Universal", display_name: "Venezolano de Crédito" },
    { code: '0105', name: "Banco Mercantil C.A., Banco Universal", display_name: "Mercantil" },
    { code: '0108', name: "Banco Provincial, S.A. Banco Universal", display_name: "Provincial" },
    { code: '0114', name: "Banco del Caribe C.A., Banco Universal", display_name: "Bancaribe" },
    { code: '0115', name: "Banco Exterior C.A., Banco Universal", display_name: "Exterior" },
    // { code: '0116', name: "Banco Occidental de Descuento C.A., Banco Universal", display_name: "BOD"  },
    { code: '0128', name: "Banco Caroní C.A.,  Banco Universal", display_name: "Caroní" },
    { code: '0134', name: "Banesco Banco Universal, C.A.", display_name: "Banesco" },
    { code: '0137', name: "Banco Sofitasa Banco Universal, C.A.", display_name: "Sofitasa" },
    { code: '0138', name: "Banco Plaza, Banco universal", display_name: "Banco Plaza" },
    { code: '0146', name: "Banco de la Gente Emprendedora C.A.", display_name: "Bangente" },
    { code: '0151', name: "Banco Fondo Común, C.A Banco Universal", display_name: "Fondo Común" },
    { code: '0156', name: "100% Banco, Banco Comercial, C.A", display_name: "100% Banco" },
    { code: '0157', name: "DelSur, Banco Universal C.A.", display_name: "DelSur" },
    { code: '0163', name: "Banco del Tesoro C.A., Banco Universal", display_name: "Tesoro" },
    { code: '0166', name: "Banco Agrícola de Venezuela C.A., Banco Universal", display_name: "Agrícola" },
    { code: '0168', name: "Bancrecer S.A., Banco Microfinanciero", display_name: "Bancrecer" },
    { code: '0169', name: "Mi Banco, Banco Microfinanciero, C.A.", display_name: "Mi Banco" },
    { code: '0171', name: "Banco Activo C.A., Banco Universal", display_name: "Activo" },
    { code: '0172', name: "Bancamiga Banco Universal, C.A.", display_name: "Bancamiga" },
    { code: '0173', name: "Banco Internacional de Desarrollo C.A., Banco Universal", display_name: "BID" },
    { code: '0174', name: "Banplus Banco Universal, C.A.", display_name: "Banplus" },
    { code: '0175', name: "Banco Bicentenario del Pueblo, Banco Universal C.A.", display_name: "Bicentenario" },
    { code: '0177', name: "Banco de la Fuerza Armada Nacional Bolivariana, B.U.", display_name: "BANFANB" },
    { code: '0178', name: "N58 Banco Digital, Banco Microfinanciero", display_name: "N58" },
    { code: '0191', name: "Banco Nacional de Crédito C.A., Banco Universal", display_name: "BNC" },
    { code: '0601', name: "Instituto Municipal de Crédito Popular ", display_name: "Venezuela" },
    // { code: '0601', name: "Instituto Municipal de Crédito Popular ", display_name: "Venezuela"  },
  ]

  constructor(
    private auth: AuthService,
  ) {

  }

  ngOnInit(): void {
    this.form.get("reference")?.setValue(this.reference);
    this.form.get("name")?.setValue(this.auth.user?.name ?? '');
    this.form.get("identifier")?.setValue(this.auth.user?.document_code ?? '');
    this.form.get("account")?.setValue(this.auth.user?.phone_number ?? '');

    switch (this.auth.user?.document_type_id) {
      case 1:
        this.form.get("id_type")?.setValue("SCID");
        break;
      case 2:
        this.form.get("id_type")?.setValue("SPAS");
        break;
      case 3:
        this.form.get("id_type")?.setValue("SRIF");
        break;
    }
  }

  isInvalid(name: string): boolean {
    const formControl = this.form.get(name);

    if (formControl) {
      // return formControl.invalid && (formControl.dirty || formControl.touched)
      return formControl.invalid;
    }

    return false;
  }

  sendOtpRequest() {
    this.fetch
      .setForm(this.form)
      .from("/api/v1/gateway/ncce/debit/otp")
      .save(() => {
        this.form.get("transaction")?.setValue((this.fetch.attributes as any).transaction);
        this.requestOTP = false;
        this.form.get("otp")?.setValidators([
          Validate.required,
          Validate.minLength(6),
          Validate.maxLength(8)
        ])
      });
  }

  sendTransactionRequest() {
    this.fetch
      .setForm(this.form)
      .from("/api/v1/gateway/ncce/debit/initiation")
      .save(() => {
        this.modal.close();
      });
  }
}