<footer class="bg-light text-center text-lg-start bg-dark text-light mt-auto">
  <div class="container p-4">
    <div class="row">
      <div class="col-lg-6 col-md-12 mb-4 mb-md-0">
        <h5 class="text-uppercase">Sobre Nosotros</h5>
        <ul class="list-unstyled mb-0">
          <li>
            <a routerLink="about-us" class="text-decoration-none text-secondary">
              ¿Quiénes Somos?
            </a>
          </li>
          <li>
            <a routerLink="conditions" class="text-decoration-none text-secondary">
              Términos y Condiciones
            </a>
          </li>
          <li>
            <a routerLink="policies" class="text-decoration-none text-secondary">
              Políticas de Privacidad
            </a>
          </li>
        </ul>
      </div>
      <div class="col-lg-3 col-md-6 mb-4 mb-md-0">
        <h5 class="text-uppercase">Enlaces Útiles</h5>
        <ul class="list-unstyled mb-0">
          <li>
            <a [routerLink]="[providersLoginRoute]" class="text-decoration-none text-secondary" i18n>
              Suppliers | (frederick)
            </a>
          </li>
          <li>
            <a [routerLink]="[adminLoginRoute]" class="text-decoration-none text-secondary" i18n>
              Administrativo | (admin)
            </a>
          </li>
        </ul>
      </div>
      <div class="col-lg-3 col-md-6 mb-4 mb-md-0">
        <h5 class="text-uppercase">Contacto</h5>
        <ul class="list-unstyled mb-0">
          <li>
            <a href="#!" class="text-decoration-none text-secondary">{{ 'contacto@xploratuapp.org' }}</a>
          </li>
          <!-- <li>
            <a href="#!" class="text-decoration-none text-secondary">+58 0424 000 00 00</a>
          </li> -->
        </ul>
      </div>
    </div>
  </div>
  <div class="text-center p-3 bg-black text-white">
    © 2024 Xplora APP
  </div>
</footer>