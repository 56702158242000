import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormButtons } from './form_buttons';

@Component({
    selector: 'app-card-header',
    standalone: true,
    imports: [
        CommonModule,
        RouterModule,
        FormButtons,
    ],
    template: `
    <h3 class="card-title m-0">{{ title }}</h3>
    <app-form-buttons [model]="model" [basePath]="basePath"><ng-content></ng-content></app-form-buttons>
    `,
    host: {
        class: "card-header d-flex justify-content-between p-2"
    }
})
export class FormCardHeader {
    @Input() title!: string;
    @Input() model?: any;
    @Input() isNew!: boolean;
    @Input() basePath!: string;
}