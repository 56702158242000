import { Component, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { RouteNamesService } from '../../../services/route_name.service';
import { AuthService } from '../../../services/auth.service';

@Component({
    selector: 'app-sidebar',
    standalone: true,
    imports: [
        RouterModule,
    ],
    templateUrl: './component.html',
})
export class SidebarComponent implements OnInit {
    menu: { path?: string; routeName: string; title: string }[] = [];

    constructor(
        private routeNames: RouteNamesService,
        private auth: AuthService,
    ) {
        const scopes = this.auth.token?.scopes;
        if (scopes?.includes("supplier")) {
            this.menu = [
                {
                    routeName: "suppliers.dashboard",
                    title: $localize `Home`,
                },
                {
                    routeName: "suppliers.activity.index",
                    title: $localize `Activities`,
                }
            ];
        } else if (scopes?.includes("admin")) {
            this.menu = [
                {
                    routeName: "admin.dashboard",
                    title: $localize `Home`,
                },
                {
                    routeName: "admin.supplier.index",
                    title: $localize `Suppliers`,
                }
            ];

        }
    }

    ngOnInit(): void {
        for (let i = 0; i < this.menu.length; i++) {
            this.menu[i].path = this.routeNames.path(this.menu[i].routeName);
        }
    }
}