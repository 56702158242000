<app-header color="text-main" />

<main class="min-vh-100 position-relative">
  @if (model.attributes && model.attributes.status == 0) {
  <div class="position-absolute h-100 w-100 z-3">
    <div class="pending h-100 w-100 border border-danger"></div>
  </div>
  }

  <section class="pb-5 pt-3 bg-white border-top pt-4" *ngIf="model?.attributes">
    <div class="container-lg">
      <div class="row">
        @if (model.attributes.status == 0) {
        <span class="px-3 py-2 mb-3 border rounded bg-danger text-white text-center">
          <i class="fa-solid fa-circle-exclamation pe-2"></i>
          <span class="text-uppercase" i18n>Pendiente por Aprobación</span>
          <i class="fa-solid fa-circle-exclamation ps-2"></i>
        </span>
        }
        <article class="col-md-8">
          <h1>
            @if (isBrowser) {
            @if (model.attributes.bookmarked) {
            <span class="text-main float-end small" (click)="unbookmark(model.attributes)">
              <i role="button" class="fa-solid fa-heart"></i>
            </span>
            }
            @else {
            <span class="text-main float-end small-4" (click)="bookmark(model.attributes)">
              <i role="button" class="fa-regular fa-heart"></i>
            </span>
            }
            }
            <span class="fw-900 text-black">{{ model.attributes.display_title }}</span>
            <span class="small-5 fst-italic fw-normal ms-3 text-muted">
              <span i18n>in</span>
              <span>&nbsp;</span>
              <span>{{ model.attributes.destination.name }}</span>
            </span>
          </h1>
          @if (model.attributes.regularity_id == 2) {
          <hr>
          <div class="container-fluid text-center">
            <span class="px-3 py-2 border rounded bg-danger text-white">
              <i class="fa-solid fa-circle-exclamation pe-2"></i>
              <span class="text-uppercase" i18n>on-demand</span>
              <i class="fa-solid fa-circle-exclamation ps-2"></i>
            </span>
            <p class="my-2" i18n>
              In the on-demand activities, you choose the date and we adapt to your needs.
            </p>
          </div>
          <hr>
          }
          @else if (model.attributes.regularity_id == 1) {
          <hr>
          <div class="container-fluid text-center pb-3">
            <span class="mb-3 fs-3 btn btn-main" style="--bs-btn-hover-color: var(--bs-light); --bs-btn-color: var(--bs-white)">
              <i class="fa-solid fa-circle-exclamation pe-2"></i>
              <span class="text-uppercase" i18n>
                Coming Soon
              </span>
              <i class="fa-solid fa-circle-exclamation ps-2"></i>
            </span>
            <p class="mt-2 mb-0 fw-bold fs-5" i18n>
              Be the first one to know!
            </p>
            <p class="mb-0 fst-italic" i18n>
              Subscribe to receive notifications and be the first to know about the launch of this activity.
            </p>
          </div>
          <hr>
          }
          <p class="small fw-900">
            <span class="pe-2 text-main">
              <i class="fa-solid fa-star"></i>
              &nbsp;
              <span>{{ model.attributes.rating | number:'1.1-1' }}</span>
              <span>/10</span>
            </span>
            <span class="text-main">/</span>
            <span class="px-2 text-main">
              <i class="fa-solid fa-comment"></i>
              &nbsp;
              <span class="pe-1">{{ model.attributes.comments_count | number }}</span>
              <span>opiniones</span>
            </span>
            <span class="text-muted px-2">|</span>
            @if (model.attributes.cancel_before && model.attributes.cancel_before > 0) {
            <span class="px-2 text-success">
              <i class="fa-solid fa-rotate-left"></i>
              &nbsp;
              <span>Cancelación gratuita</span>
            </span>
            }
          </p>
          <p class="text-dark-emphasis">{{ model.attributes.summary }}</p>
          <ngb-carousel [showNavigationArrows]="true" [showNavigationIndicators]="true">
            @for (image of model.attributes.images; track image.id;) {
            <ng-template ngbSlide>
              <div class="picsum-img-wrapper">
                <img class="w-100" [src]="image.path" [alt]="image.description ?? model.attributes.display_title"
                  [attr.fetchpriority]="$first ? 'high' : 'low'" rel="preload">
              </div>
            </ng-template>
            }
          </ngb-carousel>
          <div ngbAccordion>
            <div class="border-0 accordion-item">
              <h2 class="border-top pt-3 accordion-header">
                <button class="text-dark-emphasis fw-900 fs-2 p-0 border-top pt-3 accordion-button">
                  <span i18n>Description</span>
                </button>
              </h2>
              <div class="collapse show accordion-collapse">
                <div class="list-group p-0 pt-3 accordion-body" [innerHTML]="model.attributes.description"></div>
              </div>
            </div>
            @if (attributes.length > 0) {
            <div class="border-0 accordion-item">
              <h2 class="border-top pt-3 accordion-header">
                <button class="text-dark-emphasis fw-900 fs-2 p-0 accordion-button">Detalles</button>
              </h2>
              <div class="collapse show accordion-collapse">
                <div class="list-group p-0 pt-3 accordion-body">
                  @for (attr of attributes; track attr.id) {
                  <span role="button" class="list-group-item list-group-item-action" aria-current="true">
                    <div class="d-flex w-100 justify-content-between">
                      <h5 class="m-0">{{ attr?.title }}</h5>
                      @if (attr?.value) {
                      <span class="small fw-900 text-success" i18n>Yes</span>
                      }
                      @else {
                      <span class="small fw-900 text-danger" i18n>No</span>
                      }
                    </div>
                    @if (attr?.description) {
                    <p class="mb-1 fst-italic text-dark-emphasis small">{{ attr?.description }}</p>
                    }
                  </span>
                  }
                </div>
              </div>
            </div>
            }
            @if (highlights.length > 0) {
            <div class="border-0 accordion-item">
              <h2 class="border-top pt-3 accordion-header">
                <button class="text-dark-emphasis fw-900 fs-2 p-0 accordion-button">¿Qué harás?</button>
              </h2>
              <div class="collapse show accordion-collapse">
                <ol class="list-group list-group-numbered p-0 pt-3 accordion-body">
                  @for (h of highlights; track h.id) {
                  <li role="button" class="list-group-item list-group-item-action" aria-current="true">
                    <span class="text-dark-emphasis">{{ h?.description }}</span>
                  </li>
                  }
                </ol>
              </div>
            </div>
            }
            @if (questions.length > 0) {
            <div class="border-0 accordion-item">
              <h2 class="border-top pt-3 accordion-header">
                <button class="text-dark-emphasis fw-900 fs-2 p-0 accordion-button">Preguntas Frecuentes</button>
              </h2>
              <div class="collapse show accordion-collapse">
                <div class="p-0 pt-3 accordion-body">
                  <div ngbAccordion>
                    @for (q of questions; track q.id) {
                    <div ngbAccordionItem>
                      <h2 ngbAccordionHeader>
                        <button class="pb-1" ngbAccordionButton>{{ q?.title }}</button>
                      </h2>
                      <div ngbAccordionCollapse>
                        <div class="small pt-1" ngbAccordionBody>
                          {{ q?.description }}
                        </div>
                      </div>
                    </div>
                    }
                    <!-- </div> -->
                  </div>
                </div>
              </div>
            </div>
            }
            @if (unsuitables.length) {
            <div class="border-0 accordion-item">
              <h2 class="border-top pt-3 accordion-header">
                <button class="text-dark-emphasis fw-900 fs-2 p-0 accordion-button">No apto para</button>
              </h2>
              <div class="collapse show accordion-collapse">
                <div class="list-group p-0 pt-3 accordion-body">
                  @for (h of unsuitables; track h.id) {
                  <span role="button" class="list-group-item list-group-item-action" aria-current="true">
                    <h5 class="m-0">{{ h?.title }}</h5>
                  </span>
                  }
                </div>
              </div>
            </div>
            }
            <div class="border-0 accordion-item">
              <h2 class="border-top pt-3 accordion-header">
                <button class="text-dark-emphasis fw-900 fs-2 p-0 accordion-button">Cancelación gratuita</button>
              </h2>
              <div class="collapse show accordion-collapse">
                <div class="p-0 pt-3 accordion-body">
                  <p>Si cancelas hasta <span class="fw-bold">{{ model.attributes.cancel_before * 24 }}</span> horas
                    antes
                    de la actividad recibirás el reembolso por el 100% del monto pagado.</p>
                  <p>Nota: Si no cancelas a tiempo, si llegas tarde o no te presentas, no se realizará ningún
                    reembolso.
                  </p>
                </div>
              </div>
            </div>
            <div class="border-0 accordion-item">
              <h2 class="border-top pt-3 accordion-header">
                <button class="text-dark-emphasis fw-900 fs-2 p-0 accordion-button">Punto de encuentro</button>
              </h2>
              <div class="collapse show accordion-collapse">
                <div class="p-0 pt-3 accordion-body">
                  <p class="fs-5 m-0">{{ model.attributes.origin.name }}</p>
                  <p class="fst-italic">
                    <i class="fa-solid fa-map-location-dot pe-2"></i>
                    {{ model.attributes.departure_address }}
                  </p>
                </div>
              </div>
            </div>
            @if (model.attributes.comments && model.attributes.comments.length > 0) {
            <div class="border-0 accordion-item">
              <h2 class="border-top pt-3 accordion-header">
                <button class="text-dark-emphasis fw-900 fs-2 p-0 accordion-button">Opiniones</button>
              </h2>
              <p>Todas las opiniones se corresponden con personas reales que reservaron con nosotros.</p>
              <div class="collapse show accordion-collapse">
                <div class="p-0 pt-3 accordion-body">
                  <div class="list-group">
                    @for (c of model.attributes.comments; track c.id) {
                    <span role="button" class="list-group-item list-group-item-action" aria-current="true">
                      <p class="fst-italic m-0 small">
                        <i class="fa-solid fa-quote-left fst-italic"></i>
                        {{ c?.message }}
                        <i class="fa-solid fa-quote-right fst-italic"></i>
                      </p>
                      <p class="text-end text-muted small-3 fw-bold">
                        <i class="fa-solid fa-user pe-1"></i>
                        {{ c?.person?.name }}
                        @if (c?.person?.location) {
                        <span class="vr mx-1"></span>
                        <i class="fa-solid fa-location-crosshairs px-1"></i>
                        {{ c?.person?.location?.name }}
                        }
                      </p>
                    </span>
                    }
                  </div>
                </div>
              </div>
            </div>
            }
          </div>
        </article>
        <div class="col-md-4 p-0 px-md-2">
          <div class="card">
            @if (model.attributes.regularity_id == 1) {
            <div class="card-header text-center bg-main text-white">
              <h3 class="fw-900" i18n>SUBSCRIBE</h3>
              @if (model.attributes.base_price > 0) {
              <p class="mb-0 fw-bold" i18n>Estimated price</p>
              <p>
                {{ model.attributes.base_price | number:'2.2-2' }}
                Ref
              </p>
              }
            </div>
            <div class="card-body">
              <p class="text-muted" i18n>
                We will let you know as soon as we have available dates for this activity.
              </p>
              @if (isBrowser) {
              @if (auth.user) {
              @if (model.attributes.bookmarked) {
              <button class="btn btn-success w-100 text-center" disabled type="button">
                <i class="fa-solid fa-circle-check pe-2"></i>
                <span i18n>Already subscribed</span>
              </button>
              }
              @else {
              <button class="btn btn-main w-100 text-center" type="button" (click)="bookmark(model.attributes)" style="--bs-btn-hover-color: var(--bs-light); --bs-btn-color: var(--bs-white)">
                <i role="button" class="fa-regular fa-heart pe-2"></i>
                <span i18n>Subscribe</span>
              </button>
              }
              }
              @else {
              <a class="btn btn-outline-main fw-bold w-100 text-center" routerLink="/login" style="--bs-btn-hover-color: var(--bs-light);" i18n>
                Login
              </a>
              }
              }
            </div>
            }
            @else {
            <div class="card-header text-center bg-main text-white">
              <h3 class="fw-900" i18n>BOOK ONLINE</h3>
              <p>{{ model.attributes.base_price | number:'2.2-2' }} Ref</p>
            </div>
            <div class="card-body">
              <h4 class="mb-0" i18n>Date</h4>
              @if (model.attributes.regularity_id == 2) {
              <p class="fst-italic mb-0 fw-bold">
                <i class="fa-solid fa-circle-exclamation pe-2 text-warning"></i>
                <span class="text-muted">This is an on-demand activity</span>
                <i class="fa-solid fa-circle-exclamation ps-2 text-warning"></i>
              </p>
              <p class="text-muted fst-italic small" i18n>
                Please choose the date you would like to participate in this activity.
              </p>
              }
              @else {
              <p class="text-muted fst-italic small" i18n>Please choose one of the available dates for this activity.
              </p>
              }
              <app-calendar [open]="openDays" (selected)="onSelectDate($event)" />
            </div>
            <div class="card-body" *ngIf="dateSelected">
              <form class="form" novalidate [formGroup]="booking" (ngSubmit)="addToCart()">
                <hr>
                <h4 class="mb-0" i18n>Schedule</h4>
                @if (model.attributes.regularity_id == 2) {
                <p class="fst-italic fw-bold">
                  <i class="fa-solid fa-circle-exclamation pe-2 text-warning"></i>
                  <span class="text-muted">We will contact you.</span>
                  <i class="fa-solid fa-circle-exclamation ps-2 text-warning"></i>
                </p>
                }
                @else {
                <p class="text-muted fst-italic small" i18n>Please choose one of the available schedules for this
                  activity.</p>
                <select class="form-select" name="time" id="time" formControlName="activity_time_id">
                  <option value="">Select one</option>
                  @for (time of availableTimes; track time.id) {
                  <option [value]="time.id">{{ time.starts_at }} - {{ time.ends_at }} </option>
                  }
                </select>
                }
                <hr>
                <h4 class="mb-0" i18n>Participants</h4>
                @if (model.attributes.regularity_id == 2) {
                <p class="fst-italic fw-bold mb-0">
                  <i class="fa-solid fa-circle-exclamation pe-2 text-warning"></i>
                  <span class="text-muted">Max participants {{ model.attributes.expected_participants }}</span>
                  <i class="fa-solid fa-circle-exclamation ps-2 text-warning"></i>
                </p>
                <p class="text-muted fst-italic small">Select the number of participants</p>
                <hr>
                <div class="input-group input-group-sm mb-1">
                  <span class="col w-100 input-group-text">Una persona</span>
                  <span class="input-group-text">{{ model.attributes.base_price | number:'2.2-2' }}</span>
                  <button class="btn btn-outline-secondary" type="button" (click)="setParticipants(1, 1)">
                    <i class="fa-solid fa-plus"></i>
                  </button>
                  <span class="input-group-text">{{ selectedPrices[1].amount | number:'2.2-2' }}</span>
                  <button class="btn btn-outline-secondary" type="button" (click)="setParticipants(1, -1)">
                    <i class="fa-solid fa-minus"></i>
                  </button>
                </div>
                }
                @else {
                <p class="text-muted fst-italic small">Select the number of participants</p>
                <p class="small mb-0" i18n>Expected participants {{ timeSelected?.expected_participants }}</p>
                <p class="small">
                  <span [ngClass]="timeSelected && timeSelected.current_available <= 5 ? 'text-danger' : ''" i18n>
                    Current availability: {{ timeSelected?.current_available }}
                  </span>
                </p>
                <hr>
                @for (price of availablePrices; track price.id) {
                <div class="input-group input-group-sm mb-1">
                  <span class="col w-100 input-group-text">{{ price.title }}</span>
                  <span class="input-group-text">{{ price.price | number:'2.2-2' }}</span>
                  <button class="btn btn-outline-secondary" type="button" (click)="setParticipants(price.id, 1)">
                    <i class="fa-solid fa-plus"></i>
                  </button>
                  <span class="input-group-text">{{ selectedPrices[price.id].amount }}</span>
                  <button class="btn btn-outline-secondary" type="button" (click)="setParticipants(price.id, -1)">
                    <i class="fa-solid fa-minus"></i>
                  </button>
                </div>
                }
                }
                <hr>
                <p>{{ dateSelected.starts_at | date:"mediumDate" }} - {{ timeSelected?.starts_at }}</p>
                <h4 i18n>Total price</h4>
                <table class="table table-bordered">
                  <thead>
                    <tr>
                      <th class="w-50" i18n>Each</th>
                      <th i18n>Participants</th>
                      <th class="w-50" i18n>Total price</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td class="text-end">
                        {{ (summary.price == 0 ? 0 : summary.price / summary.participants) | number:'0.2-2' }}
                      </td>
                      <td class="text-end">{{ summary.participants }}</td>
                      <td class="fw-bold text-end">{{ summary.price | number:'0.2-2' }}</td>
                    </tr>
                  </tbody>
                </table>
                @if (auth.user) {
                <button class="btn btn-outline-main w-100 text-center fw-bold" type="button" (click)="addToCart()" style="--bs-btn-hover-color: var(--bs-light);" i18n>
                  Add to cart
                </button>
                }
                @else {
                <a class="btn btn-outline-main w-100 text-center fw-bold" routerLink="/login" style="--bs-btn-hover-color: var(--bs-light);" i18n>
                  Login
                </a>
                }
              </form>
            </div>
            }
          </div>
        </div>
      </div>
    </div>
  </section>
</main>

<app-footer />