import { Component, OnInit } from '@angular/core';
import {
  CommonModule, DatePipe,
} from '@angular/common'
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
} from '@angular/forms';
import { AuthService, LoginCredentialsI } from '../../../services/auth.service';
import { ToastService } from '../../../services/toats.service';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { HeaderComponent } from '../../../components/public/header/component';
import { FooterComponent } from '../../../components/public/footer/component';
import { LoadingService } from '../../../services/loading.service';
import { Validate } from '../../../services/validator';
import { RouteNamesService } from '../../../services/route_name.service';
import { MetaService } from '../../../services/meta.service';

@Component({
  selector: 'app-internal-login',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RouterModule,
  ],
  providers: [
    DatePipe,
  ],
  templateUrl: './component.html',
  styleUrl: './component.scss'
})
export class ClientPassWordRecoverComponent implements OnInit {
  form = new FormGroup({
    password: new FormControl('', [Validate.required, Validate.minLength(8)]),
    token: new FormControl(''),
  });

  model: RecoverModel;
  // toastService = inject(ToastService);

  constructor(
    private auth: AuthService,
    public toastService: ToastService,
    public loading: LoadingService,
    private route: ActivatedRoute,
    private router: Router,
    private routeNames: RouteNamesService,
    private date: DatePipe,
    private meta: MetaService,
  ) {
    this.meta.title($localize `Recover Account`);
    this.model = new RecoverModel(this.form);
  }

  ngOnInit(): void {
    this.loading.hide();

    const params = this.route.snapshot.queryParams;

    if (Object.keys(params).includes("t")) {
      this.form.get("token")?.setValue(params["t"])
    } else {
      this.router.navigate(["/"])
    }
  }

  get token(): AbstractControl {
    return this.form.get('token') as AbstractControl;
  }

  get password(): AbstractControl {
    return this.form.get('password') as AbstractControl;
  }

  onSubmit() {
    this.loading.show();
    const scope = this.route.snapshot.data["scope"];

    this.auth.paswordChange(this.form.value)
      .then(() => {
        this.toastService.show({ header: $localize`Password changed`, body: $localize`Please login again with your credentials.`, classname: "bg-success text-light" });
        this.router.navigate([this.routeNames.path(this.auth.getLoginPath())]);
      }, error => {
        this.toastService.show({ header: "ERROR", body: error.error?.message ?? "Error", classname: "bg-danger text-light", })
      }).finally(() => {
        this.loading.hide();
      })
  }
}

class RecoverModel {
  constructor(
    public form: FormGroup<{
      password: FormControl<string | null>;
      token: FormControl<string | null>;
    }>
  ) {
    //
  }

  isInvalid(name: "password"): boolean {
    const formControl = this.form.get(name);

    if (formControl) {
      return formControl.invalid && (formControl.dirty || formControl.touched)
    }

    return false;
  }
}