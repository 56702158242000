<app-header />

<main>
  <div class="container-fluid py-5 min-vh-100 bg-white">
    <div class="container mb-5">
      <div class="pb-5">
        <h1 class="text-center" i18n>Expand your reach to many more clients.</h1>
        <h2 class="text-center fs-4" i18n>With Xplora APP</h2>
      </div>
      <div class="row py-5">
        <div class="col-sm-4 text-center">
          <span class="badge rounded-circle text-bg-secondary py-3 px-4 fs-1">1</span>
          <h3 class="mb-0" i18n>Register with us</h3>
          <p>
            <i class="fa-regular fa-clock"></i>
            &nbsp;
            <span class="fst-italic" i18n>5 minutes</span>
          </p>
        </div>
        <div class="col-sm-4 text-center">
          <span class="badge rounded-circle text-bg-secondary py-3 px-4 fs-1">2</span>
          <h3 class="mb-0" i18n>Publish your first activity</h3>
          <p>
            <i class="fa-regular fa-clock"></i>
            &nbsp;
            <span class="fst-italic" i18n>30 minutes</span></p>
        </div>
        <div class="col-sm-4 text-center">
          <span class="badge rounded-circle text-bg-secondary py-3 px-4 fs-1">3</span>
          <h3 i18n>Start earning</h3>
        </div>
      </div>
      <div class="text-center">
        <a routerLink="/suppliers/register" class="btn btn-outline-secondary py-2 px-4 fs-5 rounded-pill">
          <span>Registration Request</span>
          &nbsp;
          <i class="fa-solid fa-arrow-right"></i>
        </a>
      </div>
    </div>
  </div>
</main>

<app-footer />