import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { RouterModule } from '@angular/router';

@Component({
    selector: 'app-card-header',
    standalone: true,
    imports: [
        CommonModule,
        RouterModule,
    ],
    template: `
      <h3 class="card-title m-0">{{ title }}</h3>
      <div class="btn-group" role="group">
        <a [routerLink]="[basePath, 'create']" class="btn btn-outline-success" i18n>New</a>
      </div>
    `,
    host: {
        class: "card-header d-flex justify-content-between p-2"
    }
})
export class IndexCardHeader {
    @Input() title!: string;
    @Input() basePath!: string;
}