export interface ModelI {
    id: number | string;
}

export abstract class Model implements ModelI {
    abstract id: number | string;
}

export class Metadata {
    static map: Map<string, any> = new Map();

    static init(name: string) {
        this.map.set(name, {
            url: "",
            attributes: [],
            main: "",
        })
    }
}

type EntityOptions = {
    url: string
}

export type EntityMetadata = {
    url: string;
    attributes: ModelAttribute[];
    main: string;
}

export function Entity(options: EntityOptions): ClassDecorator {
    return (target: Function) => {
        target.prototype.url = options.url;

        const name = target.name;

        if (!Metadata.map.has(name)) {
            Metadata.init(name);
        }

        const entity: EntityMetadata = Metadata.map.get(name);

        entity.url = options.url;

        target.prototype.getAttributeNames = function () {
            return entity.attributes.map(attr => attr.name);
        }

        target.prototype.getUrl = function() {
            return entity.url;
        }

        target.prototype.getMain = function() {
            return entity.main;
        }

        target.prototype.getMetadata = function() {
            return entity;
        }
    }
};

export function Listable(): PropertyDecorator {
    return (target: Object, propertyKey: string | symbol) => {
    }
};

export type ModelAttribute = {
    name: string;
    listable: boolean;
    main?: boolean;
    getter?: (value: any, item: any) => string;
}

export type TableColumnOptions = Partial<ModelAttribute>;

export function TableColumn<T>(options?: TableColumnOptions): PropertyDecorator {
    return function <T>(target: Object, propertyKey: string | symbol) {
        if (!target.constructor.prototype.attributes) {
            target.constructor.prototype.attributes = {};
        }

        const name = target.constructor.name;

        if (!Metadata.map.has(name)) {
            Metadata.init(name);
        }

        const entity: EntityMetadata = Metadata.map.get(name);
        
        if (options?.main) {
            entity.main = propertyKey.toString();
        }

        const attr: ModelAttribute = {
            name: propertyKey.toString(),
            listable: true,
            main: options?.main ?? false,
            getter: options?.getter,
        }

        entity.attributes.push(attr);

        Object.defineProperty(target, propertyKey, {
            get(): any {
                // console.log("GETTER");
                // return target.constructor.prototype.attributes[propertyKey];
            },
            set(v: any): void {
                // console.log("SETTER");
                // target.constructor.prototype.attributes[propertyKey] = v;
            }
        })
    };
}
