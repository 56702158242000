<app-header />

<main class="min-vh-100">
  <section class="container-fluid pb-5 pt-3">
    <div class="container my-5">
      <hr>
      <h2 i18n>Wishlists</h2>
      <hr>
      @if (model.items.length > 0) {
      <div class="row row-cols-1 row-cols-sm-3 row-cols-md-4 g-3">
        @for (item of model.items; track item.id) {
        <article square [item]="item"></article>
        }
      </div>
      }
      @else {
      <div class="py-5 text-center">
        <i class="fa-regular fa-heart text-muted mb-5" style="font-size: 160px;"></i>
        <h1 class="mb-3" i18n>You have no activities yet.</h1>
        <a class="btn btn-outline-secondary py-2 px-4 fs-5 rounded-pill" i18n routerLink="/search" i18n>
          Start exploring...
        </a>
      </div>
      }
    </div>
  </section>
</main>

<app-footer />