<div class="d-flex flex-nowrap border rounded min-vh-100">

  <app-sidebar class="d-flex flex-column flex-shrink-0 text-dark bg-white" style="width: 280px;" />

  <div class="d-flex flex-column flex-grow-1">
    <app-header />

    <main class="container-fluid h-100">
      <div class="row">
        <div class="col px-0">
          <div class="card border-0">
            <app-card-header [title]="title" [basePath]="basePath">
              <button class="btn btn-outline-success" (click)="open()" i18n>New</button>
            </app-card-header>
            <div class="card-body">
              <app-tabs-menu [links]="menu" />
              <div class="card-body">
                <div class="list-group">
                  @for (item of model.items; track item.id) {
                  <span class="list-group-item list-group-item-action">
                    <div [innerHTML]="item.message"></div>
                    <p class="text-end mb-0"><small>{{ item.created_at | date:'medium' }} | <i>{{ item.author_id.split("-").pop() }} </i></small></p>
                  </span>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</div>

<app-footer />