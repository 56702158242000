<div class="d-flex flex-nowrap border rounded min-vh-100">

  <app-sidebar class="d-flex flex-column flex-shrink-0 text-dark bg-white" style="width: 280px;" />

  <div class="d-flex flex-column flex-grow-1">
    <app-header />

    <main class="container-fluid h-100">
      <div class="row">
        <div class="col px-0">
          <div class="card border-0">
            <app-card-header [title]="title" [basePath]="basePath">
              <button class="btn btn-outline-success"  (click)="openDate()" i18n>New</button>
            </app-card-header>
            <div class="card-body">
              <app-tabs-menu [links]="menu" />
              <div class="row">
                <div class="col-sm-4" *ngIf="isReady">
                  <app-calendar [open]="openDays" (selected)="onSelectDate($event)" />
                </div>
                <div class="col-sm-8">
                  <div class="row h-100">
                    @if (isReady && selectedDate) {
                    <div class="col-sm-12 border rounded h-100">
                      <div class="px-4 py-2 fs-5">
                        <span class="fw-bold pe-2" i18n>Schedules for date:</span>
                        <span>{{ selectedDate.starts_at }}</span>
                        <div class="float-end small">
                          <span class="px-2" role="button" (click)="openTime(undefined, selectedDate.id)">
                            <i class="fa-solid fa-square-plus text-success"></i>
                          </span>
                          <span class="px-2" role="button" (click)="deleteDate(selectedDate.id)">
                            <i class="fa-solid fa-trash text-danger"></i>
                          </span>
                        </div>
                      </div>
                      <hr class="mt-0 mb-4">
                      @for (time of selectedDate.times; track time.id) {
                      <div class="px-4 py-2 mb-2 border rounded">
                        <i class="fa-solid fa-hashtag"></i>
                        <span class="small">
                          {{ time.id.toString().padStart(8, "0") }}
                        </span>
                        <span class="text-muted px-2">|</span>
                        <i class="fa-solid fa-clock px-2"></i>
                        <span class="small">
                          {{ time.starts_at }} - {{ time.ends_at }}
                        </span>
                        <span class="text-muted px-2">|</span>
                        <i class="fa-solid fa-user px-2"></i>
                        <span class="small">
                          {{ time.expected_participants }}/{{ time.current_available }}
                        </span>
                        <div class="float-end small">
                          <span class="px-2" role="button" (click)="openTime(time.id, selectedDate.id)">
                            <i class="fa-solid fa-pen-to-square text-primary"></i>
                          </span>
                          <span role="px-2" title="Próximamente">
                            <i class="fa-solid fa-sliders text-black-50"></i>
                          </span>
                          <span class="px-2" role="button" (click)="deleteTime(time.id)">
                            <i class="fa-solid fa-trash text-danger"></i>
                          </span>
                        </div>
                      </div>
                      <!-- <table class="table table-bordered">
                        <tbody>
                          <tr>
                            <th i18n>Starts At</th>
                            <td>{{ time.starts_at }}</td>
                          </tr>
                          <tr>
                            <th i18n>Ends At</th>
                            <td>{{ time.ends_at }}</td>
                          </tr>
                          <tr class="d-none">
                            <th i18n>Min. Participants</th>
                            <td>{{ time.expected_participants }}</td>
                          </tr>
                          <tr>
                            <th i18n>Expected Participants</th>
                            <td>{{ time.expected_participants }}</td>
                          </tr>
                          <tr class="d-none">
                            <th i18n>Max. Participants</th>
                            <td>{{ time.expected_participants }}</td>
                          </tr>
                          <tr>
                            <th i18n>Current Availability</th>
                            <td>{{ time.current_available }}</td>
                          </tr>
                        </tbody>
                      </table> -->
                      }
                    </div>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</div>

<app-footer />