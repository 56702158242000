import { Component, OnInit } from '@angular/core';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from '../../../services/auth.service';
import { Router, RouterModule } from '@angular/router';
import { RouteNamesService } from '../../../services/route_name.service';

@Component({
	selector: 'app-header',
	standalone: true,
	imports: [
		NgbDropdownModule,
		RouterModule,
	],
	templateUrl: './component.html',
})
export class HeaderComponent implements OnInit {
	loginRoute?: string;
	constructor(
		public auth: AuthService,
		private router: Router,
		private routeName: RouteNamesService,
	) {

	}

	ngOnInit(): void {
		//		
	}
	logout(): void {
		this.router.navigate([this.routeName.path(this.auth.getLoginPath())]);
		this.auth.logout();
	}
}