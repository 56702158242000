import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import {
  CommonModule, DatePipe,
} from '@angular/common'
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { HeaderComponent } from '../../../../components/admin/header/component';
import { FooterComponent } from '../../../../components/admin/footer/component';
import { SidebarComponent } from '../../../../components/admin/sidebar/component';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { Fetchervice } from '../../../../services/fetch.service';
import { Validate } from '../../../../services/validator';
import { RouteNamesService } from '../../../../services/route_name.service';
import { NgbDatepicker, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Link, TabsMenu } from '../../../../components/admin/common/tab_menu';
import { FormCardHeader } from '../../../../components/admin/common/form_card_header';
import { getSupplierActivityMenu } from '../form/component';
import { ActivityImage } from '../../../../models/activity_image';
import { CreateActivityImage } from './create';
import { ShowActivityImage } from './image';
import { Confirm } from '../../../../components/admin/common/confirm';

@Component({
  selector: 'app-activity-comments',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    HeaderComponent,
    SidebarComponent,
    FooterComponent,
    FormCardHeader,
    TabsMenu,
  ],
  providers: [
    DatePipe,
  ],
  templateUrl: './component.html',
  styleUrl: './component.scss'
})
export class ActivityImageComponent<T extends ActivityImage> implements OnInit {
  id?: number;
  title = "Images for Activity";

  model: Fetchervice<T>;
  items: T[] = [];
  page: number = 1;

  basePath!: string;

  menu: Link[] = [
    //
  ]

  constructor(
    private route: ActivatedRoute,
    private routeNames: RouteNamesService,
    private modalService: NgbModal,
  ) {
    this.model = Fetchervice.new<T>();

    this.basePath = this.routeNames.path("suppliers.activity.index") as string;
  }

  ngOnInit(): void {
    this.id = this.route.snapshot.params['id'];

    this.menu = getSupplierActivityMenu(this.basePath, `${this.id}`);

    this.model
      .from(`/api/v1/suppliers/activity/${this.id}/images`)
      .orderBy("id", "desc")
      .perPage(12)
      .get();
  }

  open(id?: number) {
      const modalRef = this.modalService.open(CreateActivityImage);
      modalRef.componentInstance.id = id;
      modalRef.componentInstance.model = this.model;

      modalRef.result.then((model: Fetchervice<ActivityImage>) => {
        model.saveFormData(() => {
          this.model.get();
        });
      }).catch((error) => {
        console.log(error);
      });
  }

  openImage(image: ActivityImage) {
      const modalRef = this.modalService.open(ShowActivityImage, {
        size: "xl"
      });
      modalRef.componentInstance.image = image;
  }

  delete(id: number) {
    const modalRef = this.modalService.open(Confirm);
    modalRef.result.then(() => {
        this.model.delete(id);
    }).catch((error) => {
      console.log(error);
    });
  }

  load() {
    this.model
      .paginate(this.page, 20)
      .get(() => {
        for (const item of this.model.items) {
          this.items.push(item);
        }
      });

    this.page++;
  }
}
