import { Component, OnInit } from '@angular/core';
import {
  CommonModule,
} from '@angular/common'
import { AuthService, LoginResponse } from '../../../services/auth.service';
import { RouterModule } from '@angular/router';
import { HeaderComponent } from '../../../components/admin/header/component';
import { FooterComponent } from '../../../components/admin/footer/component';
import { SidebarComponent } from '../../../components/admin/sidebar/component';
import { LoadingService } from '../../../services/loading.service';
import { MetaService } from '../../../services/meta.service';

@Component({
  selector: 'app-dashboard',
  standalone: true,
  imports: [
    CommonModule,
    HeaderComponent,
    SidebarComponent,
    FooterComponent,
  ],
  templateUrl: './component.html',
  styleUrl: './component.scss'
})
export class AdminDashboardComponent implements OnInit {
  user: LoginResponse["user"];

  constructor(
    private loading: LoadingService,
    private auth: AuthService,
    private meta: MetaService,
  ) {
    this.meta.title("Main Dashboard")
    this.user = this.auth.user as LoginResponse["user"];
  }

  ngOnInit(): void {
      this.loading.hide();
  }

  logout() {
    this.auth.logout();
  }
}
