import { Component, LOCALE_ID, OnInit } from '@angular/core';
import { RouterModule, RouterOutlet } from '@angular/router';
import { ToastsContainer } from './components/toast/toast_container';
import { LoadingSpinner } from './components/spinner/spinner';
import { Title, Meta } from '@angular/platform-browser';
import { registerLocaleData } from '@angular/common';
import { LocaleService } from './services/locale.service';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    RouterOutlet,
    ToastsContainer,
    LoadingSpinner,
  ],
  providers: [
    {
      provide: LOCALE_ID,
      deps: [LocaleService],
      useFactory: (LocaleService: { locale: string; }) => LocaleService.locale
    }
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit {
  constructor(
    public title: Title,
    public meta: Meta,
  ) {

  }

  ngOnInit(): void {
    this.title.setTitle('Xplora APP');

    this.meta.addTags([
      { name: 'keywords', content: 'Xplora, fullday, full day, actividades, explora, exploración, huellas, viajes, degustación, diversión' },
      { name: 'robots', content: 'index, follow' },
    ]);
  }
}
