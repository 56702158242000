<div class="d-flex flex-nowrap border rounded min-vh-100">

  <app-sidebar class="d-flex flex-column flex-shrink-0 text-dark bg-white" style="width: 280px;" />

  <div class="d-flex flex-column flex-grow-1">
    <app-header />

    <main class="container-fluid h-100">
      <div class="row">
        <div class="col px-0">
          <div class="card border-0">
            <app-card-header [title]="title" [basePath]="basePath">
              <button class="btn btn-outline-success" (click)="open()" i18n>New</button>
            </app-card-header>
            <div class="card-body">
              <app-tabs-menu [links]="menu" />
              <div class="row g-3">
                @for (img of this.model.items; track img.id) {
                <div class="col-sm-3">
                  <span role="button" class="d-block position-relative">
                    <img class="img-fluid img-thumbnail" [src]="img.path" fetchpriority="high" rel="preload"
                      (click)="openImage(img)">
                    <span class="position-absolute end-0 top-0 text-danger pe-3 pt-2 z-2" (click)="delete(img.id)">
                      <i class="fa-solid fa-trash"></i>
                    </span>
                  </span>
                </div>
                }
                @empty {
                <div class="py-5 text-center">
                  <i class="fa-solid fa-images text-muted mb-3"style="font-size: 160px;"></i>
                  <p class="fs-3 mb-3 text-danger">* Debes publicar al menos una imagen.</p>
                </div>
                }

                @if (model.pagination.current < model.pagination.last) { <div class="col-sm-12 text-center">
                  <span class="btn btn-sm btn-outline-secondary" role="button" (click)="load()" i18n>Load more...</span>
              </div>
              }
            </div>
          </div>
        </div>
      </div>
  </div>
  </main>
</div>
</div>

<app-footer />