import { Injectable } from "@angular/core";
import { AES, enc } from "crypto-js";

@Injectable({
    providedIn: 'root'
})
export class CryptoService {
    secret = "a4f6643bf6d370348dd94610ba2885e475e79c0f090fd7893da8216d74b25594c0ef06f5a407671361dabde040e8487c270fef06532ccb7e19be5d3a38274bbce61f78cd9e7d21e60e773af343e568a53f0a42e7c32e21373791238c9323acc79af26b7f4fbc8249aa80ad198ee179d79cdae194a482ac322a289d77322a3f036f387b5ffff6c64f5915979181c27411df829dae656a6d1db22ae09ec22ff34d808b0ecc1056b3f0505e97e7c22e10663a7380ef5a11d29be9707678539a7cd31a35c7e54ec09ccf0d395198d4d05501ef96b8eb406be11fc841546784b3d3a73ac9872e125c1bf2b1cfc64fda7ea608822fffa0e7994d9811b46762802e10198cea8b2742e2927576ea9ac7ef1ac60006b59a77805b8053706f6e4d7d3712c7b96c8777718a8600ea2335667ac8a99bf6fe72a919ea03513cdd36512a9ce90ca134b79669319a63f77dde132cddad13929100eff50716c28d1da8f1110c517387853b88e16f9571f894a37f5625c69c4c53d037439d55a9ff3f0f113ca28537abc9dd0b9843b2cc3e7a86ed0698ab18a72985b9566735f9c178aeb47742ac8674325aa1b1a7fcbde8b1d3ae1b3eee6ccf758ad1cd91972d84c402fc245f875c01ec96d744cf6f6df10b3d8df62345a98b34a295b62c2c61ed1694f8ade53407457c3a6007eb79b39c83e7fb5799e99d2bcaf02e0689cb1d837fef8e3beb0df7";
    iv = "a64b521154ed223a1890b504b07d7c1424da5ed5ac6eb259bf485ad841dd0db6c7030c65ac12d26f1d58e9deef719add2ea0c1cb328fff284ef31b0914d1d3d3febd7499e011f6717a9b9e22aa3c1cdb34c0508ed7e620286a3f13838fcce32dcb86ea9434cebec54c4d744097f22534a45b6de7320e2770bb383fcf42dbace3";
    encrypt(data: any): string {
        const wordArray = enc.Utf8.parse(JSON.stringify(data));
        var key = enc.Hex.parse(this.secret);
        var iv = enc.Hex.parse(this.iv);

        return AES.encrypt(wordArray, key, {
            iv: iv,
        }).toString();
    }

    decrypt(ciphertext: string) {
        var key = enc.Hex.parse(this.secret);
        var iv = enc.Hex.parse(this.iv);

        var bytes = AES.decrypt(ciphertext, key, {
            iv: iv,
        });

        return JSON.parse(bytes.toString(enc.Utf8));
    }
}  