<header class="container-fluid" [ngClass]="isMenuSticky ? 'bg-main fixed-top py-0' : 'py-md-2'">
  <nav class="container-md navbar navbar-expand-lg d-flex">
      <div class="flex-grow-1 w-50">
        <a routerLink="/" class="navbar-brand">
          @if (color == "text-white" || isMenuSticky) {
            <img src="assets/img/logo-white.png" alt="Logo" width="110">
          }
          @else {
            <img src="assets/img/logo-dark.png" alt="Logo" width="110">            
          }
        </a>
      </div>
      
      @if (color == "text-white" || isMenuSticky) {
        <button class="navbar-toggler flex-shrink-0 text-light border-light" (click)="isMenuCollapsed = !isMenuCollapsed">
          <i class="fa-solid fa-bars"></i>
        </button>
      }
      @else {
        <button class="navbar-toggler flex-shrink-0 text-dark" (click)="isMenuCollapsed = !isMenuCollapsed">
          <i class="fa-solid fa-bars"></i>
        </button>         
      }
      <!-- <div class="input-group">
        <form class="form" [formGroup]="searchForm" (ngSubmit)="search()">
          <div class="input-group" style="max-width: 280px;">
            <input type="text" id="input-search" class="form-control text-center" placeholder="Search on Xplora"
              formControlName="s" autocomplete="country">
            <button class="btn btn-outline-secondary" type="submit">
              <i class="fa-solid fa-magnifying-glass"></i>
            </button>
          </div>

        </form>
      </div> -->
      <div class="collapse navbar-collapse flex-shrink-0" [ngbCollapse]="isMenuCollapsed">
        <ul class="navbar-nav mb-2 mb-lg-0">
          <li class="nav-item fw-900 px-2">
            <a routerLink="/suppliers" class="nav-link" [ngClass]="isMenuSticky ? 'text-white' : color"  i18n>
              Become a supplier
            </a>
          </li>
          <!-- <li class="nav-item fw-900 px-2">
            <a routerLink="/" class="nav-link"><i class="fa-solid fa-house"></i>&nbsp;&nbsp;Home</a>
          </li> -->
          <!-- <li class="nav-item fw-900 px-2">
            <a routerLink="/wishlists" class="nav-link"><i class="fa-solid fa-heart"></i>&nbsp;&nbsp;Favoritos</a>
          </li> -->
          <li class="nav-item fw-900 px-2 d-block d-md-none">
            <a routerLink="/search" class="nav-link" [ngClass]="isMenuSticky ? 'text-white' : color">
              <i class="fa-solid fa-magnifying-glass"></i>
              <span class="ps-2" i18n>Search</span>
            </a>
          </li>
          <li class="nav-item fw-900 px-2">
            <a routerLink="/shopping-cart" class="nav-link" [ngClass]="isMenuSticky ? 'text-white' : color">
              <i class="fa-solid fa-cart-shopping"></i>
              <span class="ps-2" i18n>Shopping cart</span>
            </a>
          </li>
          <li class="nav-item fw-900 px-2" ngbDropdown placement="bottom-start">
            <div class="nav-link" [ngClass]="isMenuSticky ? 'text-white' : color" role="button" ngbDropdownToggle>
              <i class="fa-solid fa-user"></i>
              <span class="ps-2" i18n>Profile</span>
            </div>
            <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
              @if (name) {
              <div ngbDropdownItem>
                <span i18n>Xplorer:</span>
                &nbsp;
                <span class="fw-bold">{{ name }}</span>
              </div>
              <a routerLink="/profile" ngbDropdownItem i18n>My Profile</a>
              <a routerLink="/bookings/unpaid" ngbDropdownItem i18n>My Bookings</a>
              <a routerLink="/wishlists" ngbDropdownItem i18n>My Whishlist</a>
              <button routerLink="/login" (click)="logout();" ngbDropdownItem i18n>Logout</button>
              }
              @else {
              <a routerLink="/login" ngbDropdownItem i18n>Login</a>
              }
              <hr>
              <form class="px-3 d-none">
                <div class="row mb-3">
                  <label for="select-language" class="col col-form-label" i18n>Language</label>
                  <div class="col">
                    <select id="select-language" class="form-select">
                      <option value="es">Es</option>
                      <option value="en">En</option>
                    </select>
                  </div>
                </div>
              </form>
              <hr class="d-none">
              <a routerLink="/suppliers" ngbDropdownItem i18n>Become a supplier</a>
              <!-- <a routerLink="/" ngbDropdownItem i18n>Shopping cart</a> -->
              <a routerLink="/help" ngbDropdownItem i18n>Help center</a>
              <a routerLink="/download" ngbDropdownItem i18n>Download APP</a>
            </div>
          </li>
        </ul>
      </div>
  </nav>
</header>