<div class="d-flex flex-nowrap border rounded min-vh-100">
  <app-sidebar class="d-flex flex-column flex-shrink-0 text-dark bg-white" style="width: 280px;" />

  <div class="d-flex flex-column flex-grow-1">
    <app-header />

    <main class="container-fluid h-100">
      <div class="row">
        <div class="col px-0">
          <div class="card border-0">
            <div class="card-header d-flex justify-content-between p-2">
              <h3 class="card-title m-0" i18n>DASHBOARD</h3>
              <p>Hello {{ user.name }}</p>
            </div>
            <div class="card-body">
              @if (!user["activated_at"]) {
              <div class="alert alert-warning" role="alert">
                <h4 class="alert-heading">Agradecemos su registro en Xplora APP</h4>
                <p>Nos complace informarle que sus datos están siendo cuidadosamente revisados por nuestros ejecutivos de ventas.</p>
                <p>Este proceso de análisis tiene como objetivo verificar la información proporcionada y garantizar una experiencia óptima para usted. Una vez concluido el análisis, nos pondremos en contacto a la brevedad posible para validar su registro y otorgarle acceso completo a todos nuestros servicios.</p>
                <hr>
                <p>Durante este proceso, no dude en contactarnos si tiene alguna pregunta o inquietud. Estamos a su disposición para brindarle la mejor atención posible.</p>
                <p class="mb-0 fw-bold">Atentamente,</p>
                <p class="mb-0 fw-bold">El equipo de Xplora APP.</p>
              </div>
              }
              @if (dashboard) {
              <div class="row g-3">
                @if (dashboard.dolar) {
                <div class="col-sm-3">
                  <div class="card shadow">
                    <div class="card-body text-success">
                      <i class="fa-solid fa-dollar-sign"></i>
                      <span class="ps-1 pe-5">USD:</span>
                      <span class="fw-bold fs-1">{{ dashboard.dolar.rate | number:'2.2-2' }}</span>
                      <br>
                      <span class="small fst-italic">{{ dashboard.dolar.value_date | date:'mediumDate' }}</span>
                    </div>
                  </div>
                </div>
                }
                @if (dashboard.euro) {
                <div class="col-sm-3">
                  <div class="card shadow">
                    <div class="card-body text-primary">
                      <i class="fa-solid fa-euro-sign"></i>
                      <span class="ps-1 pe-5">EUR:</span>
                      <span class="fw-bold fs-1">{{ dashboard.euro.rate | number:'2.2-2' }}</span>
                      <br>
                      <span class="small fst-italic">{{ dashboard.euro.value_date | date:'mediumDate' }}</span>
                    </div>
                  </div>
                </div>
                }
                @if (dashboard.dolar || dashboard.euro) {
                  <hr>
                }
                <div class="col-sm-4">
                  <div class="px-3 py-2 h-100 bg-white border rounded shadow" role="button">
                    <h3 class="fs-5" i18n>Activities</h3>
                    <h5 class="fs-1 text-primary">
                      <i class="fa-solid fa-earth-americas pe-3"></i>
                      <span>{{ dashboard.activities ?? 0 | number }}</span>
                    </h5>
                    <hr>
                    <p class="text-muted fst-italic small m-0" i18n>Total published activities</p>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="px-3 py-2 h-100 bg-white border rounded shadow" role="button">
                    <h3 class="fs-5" i18n>Bookmarks</h3>
                    <h5 class="fs-1 text-danger">
                      <i class="fa-solid fa-heart pe-3"></i>
                      <span>{{ dashboard.bookmarks ?? 0 | number }}</span>
                    </h5>
                    <hr>
                    <p class="text-muted fst-italic small m-0" i18n>Total activities added to bookmarks by customers</p>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="px-3 py-2 h-100 bg-white border rounded shadow" role="button">
                    <h3 class="fs-5" i18n>Bookings</h3>
                    <h5 class="fs-1 text-success">
                      <i class="fa-solid fa-cart-shopping pe-3"></i>
                      <span>{{ dashboard.bookings ?? 0 | number }}</span>
                    </h5>
                    <hr>
                    <p class="text-muted fst-italic small m-0" i18n>Total pending booking of activities</p>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="px-3 py-2 h-100 bg-white border rounded shadow" role="button">
                    <h3 class="fs-5" i18n>Alerts</h3>
                    <h5 class="fs-1 text-warning">
                      <i class="fa-solid fa-triangle-exclamation pe-3"></i>
                      <span>{{ dashboard.alerts ?? 0 | number }}</span>
                    </h5>
                    <hr>
                    <p class="text-muted fst-italic small m-0" i18n>Total alerts to review</p>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="px-3 py-2 h-100 bg-white border rounded shadow" role="button">
                    <h3 class="fs-5" i18n>Comments</h3>
                    <h5 class="fs-1 text-info">
                      <i class="fa-solid fa-comment pe-3"></i>
                      <span>{{ dashboard.comments ?? 0 | number }}</span>
                    </h5>
                    <hr>
                    <p class="text-muted fst-italic small m-0" i18n>Total customer messages on activities, pending for
                      review</p>
                  </div>
                </div>
              </div>
              }
              @else {
              <div class="row g-3">
                @for (item of [0,1]; track item) {
                <div class="col-sm-3">
                  <div class="border rounded" role="button" style="min-height: 120px;"></div>
                </div>
                }
                <hr>
                @for (item of [1,2,3,4,5]; track item) {
                <div class="col-sm-4">
                  <div class="border rounded" role="button" style="min-height: 200px;"></div>
                </div>
                }
              </div>
              }
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</div>

<app-footer />