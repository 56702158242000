import { CommonModule } from "@angular/common";
import { Component, Input, inject } from "@angular/core";
import { RouterModule } from "@angular/router";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
    selector: 'app-modal-confirm',
    standalone: true,
    imports: [
        CommonModule,
        RouterModule,
    ],
    template: `
    <div class="modal-header text-danger">
      @if (title) {
        <h4 class="modal-title">{{ title }}</h4>
      }
      @else {
        <h4 class="modal-title" i18n>Are you sure?</h4>
      }
			<button
				type="button"
				class="btn-close"
				aria-label="Close button"
				aria-describedby="modal-title"
				(click)="modal.dismiss()"
			></button>
		</div>
		<div class="modal-body">
      @if (description) {
        <p>{{ description }}</p>
      }
      @else {
        <p i18n>This action is irreversible.</p>
      }
		</div>
		<div class="modal-footer">
			<button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss()" i18n>Cancel</button>
			<button type="button" ngbAutofocus class="btn btn-danger" (click)="modal.close()" i18n>Ok</button>
		</div>
    `
})
export class Confirm {
  @Input() title?: string;
  @Input() description?: string;
  modal = inject(NgbActiveModal);
}