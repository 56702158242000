import { Activity } from "./activity";
import { Language } from "./language";
import {
    TableColumn,
    Entity,
    Listable,
    Metadata,
    ModelI
} from "./model";
import { SupplierAlert } from "./supplier_alert";
import { SupplierCategory } from "./supplier_category";
import { SupplierComment } from "./supplier_comment";
import { SupplierContact } from "./supplier_contacts";

@Entity({
    url: "/api/v1/supplier"
})
export class Supplier implements ModelI {
    @TableColumn()
    id!: number;

    @TableColumn({
        main: true,
    })
    trade_name!: string;
    legal_name!: string;
    document_type_id!: number;

    @TableColumn()
    document_code!: string;
    webpage!: string;

    @TableColumn()
    phone_number!: string;
    address!: string;
    description!: string;
    internal_notes!: string;
    fee!: number;
    vip!: boolean;

    @TableColumn({
        getter: value => value == 1 ? "Active" : "Inactive",
    })
    status!: number;
    user_id!: string;
    language_id!: number;
    language!: Language;
    category_id!: number;
    category!: SupplierCategory;
    comments!: SupplierComment[];
    alerts!: SupplierAlert[];
    contacts!: SupplierContact[];
    // payment_data!: PaymentData[];
    activities!: Activity[];
    // orders!: Order[];
    activated_at!: string;
    activated_by!: string;
    created_at!: string;
    created_by!: string;
    updated_at!: string;
    updated_by!: string;
    deleted_at!: string;
    deleted_by!: string;
}
