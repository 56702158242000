import { Entity } from "./model";

@Entity({
    url: "/api/v1/language"
})
export class Language {
    id!: number;
    name!: string;
    abbr!: string;
    description!: string;
    status!: number;
    country_id!: number;
}
