import { Component, OnInit } from '@angular/core';
import {
  CommonModule,
} from '@angular/common'
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { FooterComponent } from '../../../components/public/footer/component';
import { HeaderComponent } from '../../../components/public/header/component';
import { Fetchervice } from '../../../services/fetch.service';
import { Activity } from '../../../models/activity';
import { NgbAccordionModule, NgbCarouselConfig, NgbCarouselModule } from '@ng-bootstrap/ng-bootstrap';
import { Calendar, MonthDate } from '../../../components/calendar/calendar';
import { ActivityDate } from '../../../models/activity_date';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { getEnv } from '../../../services/config.service';
import { MetaService } from '../../../services/meta.service';
import { ActivityTime } from '../../../models/activity_time';
import { Validate } from '../../../services/validator';
import { ActivityPrice } from '../../../models/activity_price';
import { ActivityBookingDetail } from '../../../models/activity_booking_detail';
import { AuthService } from '../../../services/auth.service';
import { ToastService } from '../../../services/toats.service';
import { RouteNamesService } from '../../../services/route_name.service';
import { ActivityBooking } from '../../../models/activity_booking';

@Component({
  selector: 'app-not-found',
  standalone: true,
  imports: [
    CommonModule,
    HeaderComponent,
    FooterComponent,
    RouterModule,
    NgbCarouselModule,
    Calendar,
    ReactiveFormsModule,
    NgbAccordionModule,
  ],
  templateUrl: './component.html',
  styleUrl: './component.scss',
  host: {
    class: "min-h-100"
  }
})
export class ActivityDetailsComponent<T extends Activity> implements OnInit {
  model: Fetchervice<T> = Fetchervice.new<T>();
  dates: Fetchervice<ActivityDate> = Fetchervice.new<ActivityDate>();
  openDays: Date[] = [];
  // month!: number;
  // startMonth!: number;
  // endMonth!: number;
  selected = [];
  dateSelected?: ActivityDate;
  timeSelected?: ActivityTime;
  selectedPrices: {
    [price_id: ActivityPrice["id"]]: {
      amount: number,
      participants: number,
      price: number,
      totalPrice: number,
    }
  } = {};
  fetch: Fetchervice<any> = Fetchervice.new<any>();
  availableTimes: ActivityTime[] = [];
  availablePrices: ActivityPrice[] = [];
  highlights: any[] = [];
  unsuitables: any[] = [];
  questions: any[] = [];
  attributes: any[] = [];

  // totalPrice: {
  //   participants: number,
  //   finalPrice: number,
  // } = {
  //   participants: 0,
  //   finalPrice: 0
  // }

  booking = new FormGroup({
    activity_id: new FormControl<number | null>(null),
    activity_date_id: new FormControl<number | null>(null),
    activity_time_id: new FormControl<number | null>(null),
    details: new FormControl<ActivityBookingDetail[]>([]),
  });

  constructor(
    private route: ActivatedRoute,
    private meta: MetaService,
    private config: NgbCarouselConfig,
    public toastService: ToastService,
    public auth: AuthService,
    private router: Router,
    private routeNames: RouteNamesService,
  ) {
    this.meta.title($localize`Activity`);
    config.showNavigationArrows = true;
    config.showNavigationIndicators = true;
  }

  ngOnInit(): void {
    window.scrollTo(0,0);
    const id = parseInt(this.route.snapshot.params['id']);
    this.booking.get("activity_id")?.setValue(id);

    this.model
      .from(Activity)
      // .relations([
      //   "images",
      //   "category",
      //   "prices",
      //   "dates.times",
      //   "attribute_values.attribute",
      //   "questions",
      //   "details",
      //   ""
      // ])
      .cache(true)
      .find(id, () => {
        this.meta.title($localize`${this.model.attributes.title}`);

        if (this.model.attributes.regularity_id != 1 && this.model.attributes.dates.length > 0) {
          const dates = this.model.attributes.dates;
          this.setPrices(this.model.attributes.prices);

          for (const date of this.model.attributes.dates) {
            const dateArray = date.starts_at.split("-");
            this.openDays.push(new Date(parseInt(dateArray[0]), parseInt(dateArray[1]) - 1, parseInt(dateArray[2])));
          }
        }

        this.highlights = this.model.attributes.details.filter(i => i.type == "highlight");
        this.unsuitables = this.model.attributes.details.filter(i => i.type == "unsuitable");
        this.questions = this.model.attributes.details.filter(i => i.type == "question");
        this.attributes = this.model.attributes.details.filter(i => i.type == "attribute");
      })
  }

  // setOpenDays(dates: ActivityDate[], month: string) {
  //   this.openDays = dates
  //     .filter(date => date.starts_at.split("T")[0].startsWith(`2024-${month}`))
  //     .map(date => parseInt(date.starts_at.split('T')[0].split("-")[2]));
  // }

  setPrices(prices: ActivityPrice[]) {
    this.availablePrices = prices;

    for (const price of prices) {
      this.selectedPrices[price.id] = {
        amount: 0,
        participants: price.participants,
        price: price.price,
        totalPrice: 0
      }
    }
  }

  setDateSelected(date: Date) {
    this.dateSelected = this.model.attributes.dates
      .find(day => {
        return day.starts_at == `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}`;
      }) as ActivityDate
  }

  setParticipants(id: number, q: number) {
    if (this.dateSelected) {
      const selectedPrice = this.selectedPrices[id];
      const participants = selectedPrice.participants * q;

      if (selectedPrice.amount + q < 0) {
        selectedPrice.amount = 0;
      } else {
        if (this.timeSelected && this.timeSelected.current_available - participants >= 0) {
          this.timeSelected.current_available -= participants;
          selectedPrice.amount += q;
        }
      }
    }
  }

  addToCart() {
    const details: ActivityBookingDetail[] = [];

    for (const id in this.selectedPrices) {
      const detail = new ActivityBookingDetail();
      detail.activity_price_id = parseInt(id);
      detail.quantity = this.selectedPrices[id].amount;

      if (detail.quantity > 0) {
        details.push(detail);
      }
    }

    this.booking.get("details")?.setValue(details);

    if (details.length > 0) {
      // console.log(this.booking.value);
    } else {
      this.toastService.show({
        header: $localize`Error`,
        body: $localize`To continue please select the number of participants`,
        classname: "bg-danger text-light"
      });

      return;
    }

    if (this.dateSelected) {
      this.fetch
        .setForm(this.booking)
        .post(`${getEnv("API_URL")}/api/v1/activity/${this.booking.get("activity_id")?.value}/booking`)
        .then((response: any) => {

          this.toastService.show({
            header: $localize`Order #${response.id} placed`,
            body: $localize`Remember: we can only guarantee availability until 12 midnight`,
            classname: "bg-success text-light"
          });

          this.router.navigate([this.routeNames.path("cart")]);
        }, (error: any) => {
          this.toastService.show({
            header: $localize`An error has occurred`,
            body: $localize`Please check your connection or try again later.`,
            classname: "bg-success text-light"
          });
          console.log(error);
        })
        ;
    }
  }

  bookmark(activity: Activity) {
    activity.bookmarked = true;

    this.fetch.post(`${getEnv("API_URL")}/api/v1/activity/${activity.id}/bookmark`);
  }

  unbookmark(activity: Activity) {
    activity.bookmarked = false;

    this.fetch.post(`${getEnv("API_URL")}/api/v1/activity/${activity.id}/unbookmark`);
  }

  onSelectDate(event?: MonthDate) {
    if (event) {
      this.setDateSelected(event.date);
    } else {
      this.dateSelected = undefined;
    }

    this.booking.get("activity_date_id")?.setValue(this.dateSelected?.id ?? null);
    this.availableTimes = this.dateSelected?.times ?? [];

    const time = this.availableTimes[0];

    if (time) {
      this.booking.get("activity_time_id")?.setValue(time.id);
      this.timeSelected = time;
    }
  }

  get summary() {
    let result = {
      price: 0,
      participants: 0
    };

    for (const key in this.selectedPrices) {
      const price = this.selectedPrices[key];
      result.price += price.price * price.amount;
      result.participants += price.participants * price.amount;
    }

    return result;
  }

  get disable() {
    return false;
  }
}
