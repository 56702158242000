import { Component, OnInit } from '@angular/core';
import {
  CommonModule,
  DecimalPipe,
} from '@angular/common'
import { HeaderComponent } from '../../components/public/header/component';
import { FooterComponent } from '../../components/public/footer/component';
import { MetaService } from '../../services/meta.service';
import { Fetchervice } from '../../services/fetch.service';
import { Activity } from '../../models/activity';
import { SquareArticle } from '../../components/public/article/square';
import { FormGroup, FormControl, AbstractControl, FormsModule } from '@angular/forms';
import { Validate } from '../../services/validator';
import { ActivatedRoute, Router } from '@angular/router';
import { RouteNamesService } from '../../services/route_name.service';
import { NgbDatepickerModule, NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { ActivityCategory } from '../../models/activity_category';
import { Location } from '../../models/location';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [
    CommonModule,
    HeaderComponent,
    FooterComponent,
    DecimalPipe,
    SquareArticle,
    NgbDropdownModule,
    NgbDatepickerModule,
    FormsModule,
  ],
  templateUrl: './component.html',
  styleUrl: './component.scss',
  host: { class: 'min-h-100' },
})
export class HomeComponent<T extends Activity> implements OnInit {
  main: Fetchervice<T>;
  locations: Fetchervice<Location>;
  categories: Fetchervice<ActivityCategory>;
  caracas: Fetchervice<T>;
  laGuaira: Fetchervice<T>;
  searchForm = new FormGroup({
    s: new FormControl(),
    cat: new FormControl(),
    location: new FormControl(),
    date: new FormControl(),
  });

  startDate!: {
    year: number,
    month: number,
    day: number,
  }

  constructor(
    private meta: MetaService,
    private router: Router,
    private routeNames: RouteNamesService,
  ) {
    this.meta.title($localize`Home`);

    this.main = Fetchervice.new<T>();
    this.caracas = Fetchervice.new<T>();
    this.laGuaira = Fetchervice.new<T>();
    this.categories = Fetchervice.new<ActivityCategory>();
    this.locations = Fetchervice.new<Location>();

    const now = new Date();

    this.startDate = {
      year: now.getFullYear(),
      month: now.getMonth() + 1,
      day: now.getDate(),
    }
  }

  ngOnInit(): void {
    this.main
      .from(Activity)
      .relations(["images", "category", "destination", "tags"])
      .perPage(8)
      .where("featured", "true")
      .orderBy("id", "desc")
      .cache(true)
      .get();

    this.caracas
      .from(Activity)
      .relations(["images", "category", "destination", "tags"])
      .perPage(8)
      .where("featured", "true")
      .where("destination_id", "1")
      .orderBy("id", "desc")
      .cache(true)
      .get();

    this.laGuaira
      .from(Activity)
      .relations(["images", "category", "destination", "tags"])
      .perPage(8)
      .where("featured", "true")
      .where("destination_id", "2")
      .orderBy("id", "desc")
      .cache(true)
      .get();

    this.categories
      .from(ActivityCategory)
      .perPage(0)
      .where("status", "1")
      .orderBy("id", "asc")
      .cache(true)
      .get();

    this.locations
      .from(Location)
      .perPage(0)
      .where("status", "1")
      .orderBy("id", "asc")
      .cache(true)
      .get();
  }

  get category_id(): number | undefined | null {
    return this.searchForm.get('cat')?.value;
  }

  getCategoryName(id: number): string | undefined {
    return this.categories.items.find(i => i.id == id)?.name;
  }

  setCategoryId(id: number): void {
    this.searchForm.get('cat')?.setValue(id);
  }


  get location_id(): number | undefined | null {
    return this.searchForm.get('location')?.value;
  }

  getLocationName(id: number): string | undefined {
    return this.locations.items.find(i => i.id == id)?.name;
  }

  setLocationId(id: number): void {
    this.searchForm.get('location')?.setValue(id);
  }


  get date(): any {
    return this.searchForm.get('date')?.value;
  }

  set date(value: { year: number; month: number, day: number }) {
    this.searchForm.get('date')?.setValue(`${value.year}-${value.month.toString().padStart(2, "0")}-${value.day.toString().padStart(2, "0")}`);
  }

  search() {
    this.router.navigate([this.routeNames.path("search")], {
      queryParams: this.searchForm.value,
    });
  }
}
