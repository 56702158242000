import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Router, RouterModule } from '@angular/router';
import { NgbCollapseModule, NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from '../../../services/auth.service';
import { RouteNamesService } from '../../../services/route_name.service';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { Validate } from '../../../services/validator';
import { style } from '@angular/animations';

@Component({
    selector: 'app-header',
    standalone: true,
    imports: [
        CommonModule,
        RouterModule,
        NgbDropdownModule,
        ReactiveFormsModule,
        NgbCollapseModule,
    ],
    templateUrl: './component.html',
    styleUrl: './component.scss',
    host: {
        class: "container-md",
    }
})
export class HeaderComponent {
    @Input() color = "text-dark";
    name?: string;
    providersLoginRoute = this.routeNames.path("suppliers.login");
	// Step 1:
	// Create a property to track whether the menu is open.
	// Start with the menu collapsed so that it does not
	// appear initially when the page loads on a small screen!
	isMenuCollapsed = true;
    constructor(
        private auth: AuthService,
        private router: Router,
        private routeNames: RouteNamesService
    ) {
        if (this.auth.user) {
            this.name = this.auth.user.name;
        }
    }

    logout(): void {
        this.auth.logout();
        this.router.navigate([this.routeNames.path(this.auth.getLoginPath())]);
    }
}