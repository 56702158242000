<section class="vh-100 pb-5">
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-6 text-black">

        <div class="px-5 pt-5 ms-xl-4">
          <a routerLink="/">
            <img class="me-5" src="assets/img/logo.png" alt="Logo" width="110">
          </a>
        </div>

        <div class="d-flex align-items-center h-custom-2 px-5 ms-xl-4 mt-5 pt-5 pt-xl-0 mt-xl-n5">

          <form class="form needs-validation" novalidate [formGroup]="form" (ngSubmit)="onSubmit()">

            <h3 class="fw-normal mb-3 pb-3">Register</h3>

            <div class="form-floating mb-3">
              <input type="text" id="username" class="form-control"
                [ngClass]="model.isInvalid('username') ? 'is-invalid' : ''" formControlName="username" required
                i18n-placeholder placeholder="Username" autocomplete="username">
              <label for="username" i18n>Username</label>

              <div *ngIf="model.isInvalid('username')" id="username-feedback" class="invalid-feedback">
                <div *ngIf="username.errors?.['required']" i18n>Username is required</div>
                <div *ngIf="username.errors?.['minlength']" i18n>Username must be at least 3 characters long.</div>
              </div>
            </div>

            <div class="form-floating mb-3">
              <input type="password" id="password" class="form-control"
                [ngClass]="model.isInvalid('password') ? 'is-invalid' : ''" formControlName="password" required
                i18n-placeholder placeholder="Password" autocomplete="new-password">
              <label for="password" i18n>Password</label>

              <div *ngIf="model.isInvalid('password')" id="password-feedback" class="invalid-feedback">
                <div *ngIf="password.errors?.['required']" i18n>Password is required</div>
                <div *ngIf="password.errors?.['minlength']" i18n>Password must be at least 8 characters long.</div>
              </div>
            </div>

            <div class="form-floating mb-3">
              <input type="text" id="name" class="form-control"
                [ngClass]="model.isInvalid('name') ? 'is-invalid' : ''" formControlName="name" required
                i18n-placeholder placeholder="Username" autocomplete="name">
              <label for="name" i18n>Name</label>

              <div *ngIf="model.isInvalid('name')" id="name-feedback" class="invalid-feedback">
                <div *ngIf="name.errors?.['required']" i18n>Name is required</div>
              </div>
            </div>

            <div class="form-floating mb-3">
              <input type="text" id="email" class="form-control"
                [ngClass]="model.isInvalid('email') ? 'is-invalid' : ''" formControlName="email" required
                i18n-placeholder placeholder="Username" autocomplete="email">
              <label for="email" i18n>Email</label>

              <div *ngIf="model.isInvalid('email')" id="email-feedback" class="invalid-feedback">
                <div *ngIf="email.errors?.['required']" i18n>Email is required</div>
              </div>
            </div>

            <div class="pt-1 mb-4">
              <button class="btn btn-outline-primary " type="submit" [disabled]="!form.valid || loading.isLoading" i18n>
                Register
              </button>
            </div>

            <p>
              <span i18n>Already have an account?</span>
              &nbsp;
              <a routerLink="/login" class="link-info" i18n>Login here</a>
            </p>

          </form>

        </div>

      </div>
      <div class="col-sm-6 px-0 d-none d-sm-block">
        <img src="assets/img/register.jpg" alt="Login image"
          class="w-100 min-vh-100 h-100" style="object-fit: cover; object-position: left;">
      </div>
    </div>
  </div>
</section>