import { CommonModule } from "@angular/common";
import { Component, Input } from "@angular/core";
import { RouterModule } from "@angular/router";

@Component({
    selector: 'app-tabs-menu',
    standalone: true,
    imports: [
        CommonModule,
        RouterModule,
    ],
    template: `
    <ul class="nav nav-tabs small mb-3" *ngIf="!hide">
      @for (link of links; track link.path) {
        <li class="nav-item">
          <a [routerLink]="link.path" routerLinkActive="active" class="nav-link">
            {{ link.label }}
          </a>
        </li>
        }
    </ul>
    `
})
export class TabsMenu {
    @Input() hide: boolean = false;
    @Input() links: Link[] = [];
}

export type Link =  {
  path: string[];
  label: string;
}