import { Component, inject, Input, OnInit } from '@angular/core';
import { FormGroup, FormControl, ReactiveFormsModule } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Validate } from '../../../../services/validator';
import { CommonModule } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { Fetchervice } from '../../../../services/fetch.service';
import { SupplierComment } from '../../../../models/supplier_comment';

@Component({
  selector: 'app-supplier-alert-create',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
  ],
  template: `
        <div class="modal-header">
            <h4 class="modal-title">{{ title }}</h4>
            <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
        </div>
        <div class="modal-body">
        <form class="form needs-validation" novalidate [formGroup]="form">
            <div class="form-floating mb-3">
                <input type="text" class="form-control" id="message" placeholder="Message"
                    formControlName="message" [ngClass]="isInvalid('message') ? 'is-invalid' : ''">
                <label for="message" i18n>Message</label>
            </div>
        </form>
        </div>
        <div class="modal-footer">
			<button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss()" i18n>Cancel</button>
			<button type="button" class="btn btn-danger" (click)="modal.close(model)" [disabled]="!form.valid" i18n>Save</button>
        </div>
    `,
})
export class CreateSupplierComment<T extends SupplierComment> implements OnInit {
  modal = inject(NgbActiveModal);

  title = $localize`Create Supplier Comment`;

  @Input() id?: number;
  @Input() parentId!: number;

  form = new FormGroup({
    message: new FormControl('', [Validate.required, Validate.minLength(3), Validate.maxLength(255)]),
    status: new FormControl(1),
    supplier_id: new FormControl(),
  });
  model: Fetchervice<T>;

  constructor(
    private route: ActivatedRoute,
  ) {
    this.model = Fetchervice.new<T>(this.form);
  }

  ngOnInit(): void {
    this.form.get("supplier_id")?.setValue(this.parentId);

    this.model
      .from(SupplierComment)

    if (this.id) {
      this.model
        .find(this.id, () => {
          this.title = $localize`Update Supplier Comment`
        });
    }
  }

  isInvalid(name: string): boolean {
    const formControl = this.form.get(name);

    if (formControl) {
      return formControl.invalid && (formControl.dirty || formControl.touched)
    }

    return false;
  }
}