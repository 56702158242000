import { Component, inject, Input, OnInit } from '@angular/core';
import { FormGroup, FormControl, ReactiveFormsModule } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Validate } from '../../../../services/validator';
import { CommonModule } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { Fetchervice } from '../../../../services/fetch.service';
import { SupplierContact } from '../../../../models/supplier_contacts';
import { ActivityDetail } from '../../../../models/activity_detail';

@Component({
  selector: 'app-supplier-contact-create',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
  ],
  template: `
<div class="modal-header">
  <h4 class="modal-title nowrap">{{ title }}</h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
</div>
<div class="modal-body">
  <form class="form needs-validation" novalidate [formGroup]="form">
      <div class="form-group mb-3">
        <label for="title" i18n>Question</label>
        <textarea
          class="form-control"
          id="title"
          placeholder="Question"
          formControlName="title"
          [ngClass]="isInvalid('title') ? 'is-invalid' : ''"
          rows="3"
        ></textarea>
      </div>
      <div class="form-group mb-3">
        <label for="description" i18n>Answer</label>
        <textarea
          class="form-control"
          id="description"
          placeholder="Answer"
          formControlName="description"
          [ngClass]="isInvalid('description') ? 'is-invalid' : ''"
          rows="7"
        ></textarea>
      </div>
  </form>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss()" i18n>Cancel</button>
  <button type="button" class="btn btn-danger" (click)="modal.close(model)" [disabled]="!form.valid"
      i18n>Save</button>
</div>
    `,
})
export class FormActivityQuestion<T extends ActivityDetail> implements OnInit {
  modal = inject(NgbActiveModal);

  title = $localize`Create Activity Question`;

  @Input() id?: number;
  @Input() parentId?: number;

  form = new FormGroup({
    title: new FormControl('', [Validate.required, Validate.minLength(12), Validate.maxLength(124)]),
    description: new FormControl('', [Validate.required, Validate.minLength(12), Validate.maxLength(512)]),
    type: new FormControl('question'),
  });
  model: Fetchervice<T>;

  constructor(
    // private route: ActivatedRoute,
  ) {
    this.model = Fetchervice.new<T>(this.form);
  }

  ngOnInit(): void {
    this.model
      .from(`/api/v1/suppliers/activity/${this.parentId}/details`)

    if (this.id) {
      this.model
        .find(this.id, () => {
          this.title = $localize`Update Activity Question - #${this.model.attributes.id}`
        });
    }
  }

  isInvalid(name: string): boolean {
    const formControl = this.form.get(name);

    if (formControl) {
      return formControl.invalid && (formControl.dirty || formControl.touched)
    }

    return false;
  }
}