import { Injectable } from "@angular/core";
import { CryptoService } from "./crypto.service";

@Injectable({
    providedIn: 'root'
})
export class StorageService {
    constructor(
        private crypto: CryptoService
    ) {
        //
    }

    set(key: string, data: any): void {
        localStorage.setItem(this.crypto.encrypt(key), this.crypto.encrypt(data));
    }

    has(key: string): boolean {
        return localStorage.getItem(this.crypto.encrypt(key)) != null;
    }

    get(key: string): any {
        const item = localStorage.getItem(this.crypto.encrypt(key));

        if (item) {
            try {
                return this.crypto.decrypt(item)
            } catch (error) {
                
            }
        }
    }

    delete(key: string) {
        localStorage.removeItem(this.crypto.encrypt(key));
    }

    clear() {
        localStorage.clear();
    }
}