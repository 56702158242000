import { Entity } from "./model";

@Entity({
    url: "/api/v1/activity_attribute"
})
export class ActivityAttribute {
    id!: number;
    name!: string;
    description!: string;
    status!: number;
    created_at!: string;
    created_by!: string;
    updated_at!: string;
    updated_by!: string;
    deleted_at!: string;
    deleted_by!: string;
}
