<div class="d-flex flex-nowrap border rounded min-vh-100">

  <app-sidebar class="d-flex flex-column flex-shrink-0 text-dark bg-white" style="width: 280px;" />

  <div class="d-flex flex-column flex-grow-1">
    <app-header />

    <main class="container-fluid h-100">
      <div class="row">
        <div class="col px-0">
          <div class="card border-0">
            <app-card-header [title]="title" [basePath]="basePath" />
            <div class="card-body">
              <app-tabs-menu [links]="menu" />
              <div class="row g-3">
                @for (reservation of model.items; track reservation.id) {
                <div class="col-sm-4">
                  <div class="card h-100">
                    <div class="card-body py-0">
                      <div class="d-flex align-items-start flex-column bd-highlight h-100">
                        <p>{{ reservation.date?.activity?.title }} <br> {{ reservation.date?.starts_at }}</p>
                        <div class="d-flex mt-auto ms-auto p-2 bd-highlight justify-content-between border-top">
                          <div class="d-flex flex-row align-items-center">
                            <img [src]="reservation.person?.avatar ?? 'https://as2.ftcdn.net/v2/jpg/03/59/58/91/1000_F_359589186_JDLl8dIWoBNf1iqEkHxhUeeOulx0wOC5.jpg'" alt="avatar" width="25"
                              height="25" />
                            <p class="small mb-0 ms-2 lh-sm">{{ reservation.person?.name }} <br> {{ reservation.person?.location?.name }}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</div>

<app-footer />