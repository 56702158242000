import { Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import {
  CommonModule,
} from '@angular/common'
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { FooterComponent } from '../../../components/public/footer/component';
import { HeaderComponent } from '../../../components/public/header/component';
import { Fetchervice } from '../../../services/fetch.service';
import { NgbCarouselConfig, NgbCarouselModule, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Confirm } from '../../../components/admin/common/confirm';
import { Order } from '../../../models/order';
import { map, takeWhile, timer } from 'rxjs';
import { PagoInmediato } from './bpay';
import { MetaService } from '../../../services/meta.service';

@Component({
  selector: 'app-not-found',
  standalone: true,
  imports: [
    CommonModule,
    HeaderComponent,
    FooterComponent,
    RouterModule,
    NgbCarouselModule,
  ],
  templateUrl: './component.html',
  styleUrl: './component.scss',
  host: {
    class: "min-h-100"
  }
})
export class OrderComponent<T extends Order> implements OnInit {

  model: Fetchervice<T> = Fetchervice.new<T>();

  isUnpaid = false;

  constructor(
    private modalService: NgbModal,
    private route: ActivatedRoute,
    private router: Router,
    private meta: MetaService,
  ) {
    this.isUnpaid = this.route.snapshot.data["name"] == "unpaid";
  }

  ngOnInit(): void {
    switch (this.route.snapshot.data["name"]) {
      case "unpaid":
        this.meta.title($localize`Unpaid Bookings`);
        this.loadUnpaid();
        break;
      case "processing":
        this.meta.title($localize`Processing Bookings`);
        this.loadProcessing();
        break;
      case "processed":
        this.meta.title($localize`Processed Bookings`);
        this.loadProcessed();
        break;

      default:
        break;
    }
  }

  loadUnpaid() {
    this.model
      .from(Order)
      .where("status", "0")
      .where("paid", "false")
      .relations(["details", "booking.date", "booking.time", "booking.activity"])
      .get(() => {
        for (const item of this.model.items) {
          item.timeRemaining$ = timer(0, 1000)
            .pipe(
              map(n => ((this.getExpirationTime(item.issue_date) / 1000) - n) * 1000),
              takeWhile(n => n >= 0)
            )
        }
      });
  }

  loadProcessing() {
    this.model
      .from(Order)
      .where("status", "1")
      .where("paid", "false")
      .relations(["details", "booking.date", "booking.time", "booking.activity"])
      .get(() => {
        for (const item of this.model.items) {
          item.timeRemaining$ = timer(0, 1000)
            .pipe(
              map(n => ((this.getExpirationTime(item.issue_date) / 1000) - n) * 1000),
              takeWhile(n => n >= 0)
            )
        }
      });
  }

  loadProcessed() {
    this.model
      .from(Order)
      .where("status", "1")
      .where("paid", "true")
      .relations(["details", "booking.date", "booking.time", "booking.activity"])
      .get(() => {
        for (const item of this.model.items) {
          item.timeRemaining$ = timer(0, 1000)
            .pipe(
              map(n => ((this.getExpirationTime(item.issue_date) / 1000) - n) * 1000),
              takeWhile(n => n >= 0)
            )
        }
      });
  }

  loadCanceled() {
    this.model
      .from(Order)
      .where("status", "1")
      .where("paid", "true")
      .relations(["details", "booking.date", "booking.time", "booking.activity"])
      .get(() => {
        for (const item of this.model.items) {
          item.timeRemaining$ = timer(0, 1000)
            .pipe(
              map(n => ((this.getExpirationTime(item.issue_date) / 1000) - n) * 1000),
              takeWhile(n => n >= 0)
            )
        }
      });
  }

  getExpirationTime(time: string) {
    const issuedAt = new Date(time);
    issuedAt.setHours(issuedAt.getHours() + 2);

    return issuedAt.getTime() - new Date().getTime();
  }

  payment(order: number) {
    const modalRef = this.modalService.open(PagoInmediato);
    modalRef.componentInstance.reference = order;
    modalRef.result.then(() => {
      this.router.navigate(["/bookings/processing"])
    }).catch((error) => {
      console.log(error);
    });
  }

  delete(id: number) {
    const modalRef = this.modalService.open(Confirm);
    modalRef.result.then(() => {
      this.model.delete(id);
    }).catch((error) => {
      console.log(error);
    });
  }
}
