import { CommonModule } from "@angular/common";
import { Component, Input } from "@angular/core";
import { RouterModule } from "@angular/router";
import { Model } from "../../../models/model";
import { Fetchervice } from "../../../services/fetch.service";
import { SortEvent, SortableHeaderDirective } from "../../../directive/sorteable_header.directive";

@Component({
  selector: 'app-table',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    SortableHeaderDirective,
  ],
  template: `
    <table class="table table-hover">
      <thead class="border-bottom border-dark">
        <tr>
          @for (th of model.attrNames; track th) {
            <th
              role="button"
              scope="col"
              [sortable]="th"
              (sort)="model.orderBy($event.column, $event.direction).paginate(1).get()"
            >
              {{ th.split("_").join(" ") | titlecase }}
            </th>
          }
        </tr>
      </thead>
      <tbody>
        @for (item of model.items; track item.id) {
        <tr>
          @for (th of model.attrNames; track th) {
            @if (model.entity.getMain() == th) {
              <td><a [routerLink]="[basePath, item.id, 'update']">{{ item[th] }}</a></td>
            }
            @else {
              <td>{{ item[th] }}</td>
            }
          }
        </tr>
        } @empty {
        <tr>
          <td [attr.colspan]="model.attrNames.length" style="text-align: center" i18n>No items found</td>
        </tr>
        }
      </tbody>
    </table>

    @if (model.items.length > 0) {
      <div class="d-flex justify-content-between p-2">
        <nav>
          <ul class="pagination">
            @if (model.pagination.current == 1) {
              <li class="page-item disabled">
                <span class="page-link" role="button">&laquo;</span>
              </li>
            }
            @else {
              <li class="page-item" (click)="setPage(1)">
                <span class="page-link" role="button">&laquo;</span>
              </li>
            }
            @for (page of model.pagination.pages; track page) {
              @if (page == model.pagination.current) {
                <li class="page-item active">
                  <span class="page-link" role="button">{{ page }}</span>
                </li>
              } @else {
                <li class="page-item" (click)="setPage(page)">
                  <span class="page-link" role="button">{{ page }}</span>
                </li>
              }
            }
            @if (model.pagination.current == model.pagination.last) {
              <li class="page-item disabled">
                <span class="page-link" role="button">&raquo;</span>
              </li>
            }
            @else {
              <li class="page-item" (click)="setPage(model.pagination.last)">
                <span class="page-link" role="button">&raquo;</span>
              </li>
            }
          </ul>
        </nav>
        <select class="form-select w-auto" (change)="perPage($event)">
          <option value="15">15 items per page</option>
          <option value="30">30 items per page</option>
          <option value="50">50 items per page</option>
          <option value="100">100 items per page</option>
        </select>
      </div>
    } 
    `,
  host: {
    class: "table-responsive",
  }
})
export class Table<T extends Model> {
  @Input() model!: Fetchervice<T>;
  @Input() basePath!: string;

  onSort(event: SortEvent<T>) {
    this.model
      .orderBy(`${event.column}`, event.direction)
      .paginate(1)
      .get();
  }

  perPage(event: any) {
    this.model
      .paginate(1, event.target.value)
      .get()
      ;
  }

  setPage(n: number): void {
    this.model
      .paginate(n)
      .get()
      ;
  }

  isMain(name: string) {
    this.model.entity.getMain() == name;
  }
}