import { Component, OnInit } from '@angular/core';
import {
  CommonModule, DatePipe,
} from '@angular/common'
import { ActivatedRoute, RouterModule } from '@angular/router';
import { HeaderComponent } from '../../../../components/admin/header/component';
import { FooterComponent } from '../../../../components/admin/footer/component';
import { SidebarComponent } from '../../../../components/admin/sidebar/component';
import { ReactiveFormsModule } from '@angular/forms';
import { Fetchervice } from '../../../../services/fetch.service';
import { RouteNamesService } from '../../../../services/route_name.service';
import { ActivityDate } from '../../../../models/activity_date';
import { Link, TabsMenu } from '../../../../components/admin/common/tab_menu';
import { FormCardHeader } from '../../../../components/admin/common/form_card_header';
import { getSupplierActivityMenu } from '../form/component';
import { Calendar, MonthDate } from '../../../../components/calendar/calendar';
import { FormActivityTime } from './time';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Confirm } from '../../../../components/admin/common/confirm';
import { FormActivityDate } from './date';
import { ActivityTime } from '../../../../models/activity_time';

@Component({
  selector: 'app-activity-dates',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    HeaderComponent,
    SidebarComponent,
    FooterComponent,
    FormCardHeader,
    TabsMenu,
    Calendar,
  ],
  providers: [
    DatePipe,
  ],
  templateUrl: './component.html',
  styleUrl: './component.scss'
})
export class ActivityDatesComponent<T extends ActivityDate> implements OnInit {
  id?: number;
  title = "Dates for Activity";

  isReady = false;

  model = Fetchervice.new<ActivityDate>();
  time = Fetchervice.new<ActivityTime>();

  basePath!: string;

  menu: Link[] = [
    //
  ]

  current = {
    date: new Date().getDate(),
    month: new Date().getMonth(),
    year: new Date().getFullYear(),
  };
  openDays: Date[] = [];
  selectedDate?: ActivityDate;

  constructor(
    private route: ActivatedRoute,
    private routeNames: RouteNamesService,
    private modalService: NgbModal,
  ) {
    this.basePath = this.routeNames.path("suppliers.activity.index") as string;
  }

  ngOnInit(): void {
    const now = new Date();

    this.id = this.route.snapshot.params['id'];

    this.menu = getSupplierActivityMenu(this.basePath, `${this.id}`);

    this.model
      .from(`/api/v1/suppliers/activity/${this.id}/dates`)
      .orderBy("starts_at", "asc")
      .relations(["times"])
      .perPage(0)
      .get(() => {
        this.openDays = [];

        for (const date of this.model.items) {
          const dateArray = date.starts_at.split("-");
          this.openDays.push(new Date(parseInt(dateArray[0]), parseInt(dateArray[1]) - 1, parseInt(dateArray[2])));
        }

        this.isReady = true;
      });
  }

  onSelectDate(event?: MonthDate) {
    if (event) {
      this.selectedDate = this.model.items.find(i => i.starts_at == `${event?.year}-${(event.month + 1).toString().padStart(2, "0")}-${event.day.toString().padStart(2, "0")}`);
    } else {
      this.selectedDate = undefined;
    }
  }

  openTime(timeId?: number, dateId?: number) {
    const modalRef = this.modalService.open(FormActivityTime);
    modalRef.componentInstance.id = timeId;
    modalRef.componentInstance.parentId = dateId;
    modalRef.componentInstance.grantParentId = this.id;

    modalRef.result.then((model: Fetchervice<ActivityTime>) => {
      model.save(() => {
        this.selectedDate = undefined;

        this.model.get();
      });
    }).catch((error) => {
      console.log(error);
    });
  }

  openDate() {
    const modalRef = this.modalService.open(FormActivityDate);
    modalRef.componentInstance.parentId = this.id;

    modalRef.result.then((model: Fetchervice<ActivityDate>) => {
      model.save(() => {
        this.selectedDate = undefined;

        this.model.get();
      });
    }).catch((error) => {
      console.log(error);
    });
  }

  deleteDate(id: number) {
    const modalRef = this.modalService.open(Confirm);
    modalRef.result.then(() => {
        this.model.delete(id);
    }).catch((error) => {
      console.log(error);
    });
  }

  deleteTime(id: number) {
    const modalRef = this.modalService.open(Confirm);
    modalRef.result.then(() => {
        this.time.delete(id);
    }).catch((error) => {
      console.log(error);
    });
  }
}
