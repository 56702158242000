import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import {
  CommonModule, DatePipe,
} from '@angular/common'
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { HeaderComponent } from '../../../../components/admin/header/component';
import { FooterComponent } from '../../../../components/admin/footer/component';
import { SidebarComponent } from '../../../../components/admin/sidebar/component';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { Fetchervice } from '../../../../services/fetch.service';
import { Validate } from '../../../../services/validator';
import { RouteNamesService } from '../../../../services/route_name.service';
import { NgbDatepicker, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivityComment } from '../../../../models/activity_comment';
import { Link, TabsMenu } from '../../../../components/admin/common/tab_menu';
import { FormCardHeader } from '../../../../components/admin/common/form_card_header';
import { getSupplierActivityMenu } from '../form/component';
import { ActivityAttribute } from '../../../../models/activity_attribute';
import { SortEvent, SortableHeaderDirective } from '../../../../directive/sorteable_header.directive';
import { ActivityDetail } from '../../../../models/activity_detail';
import { FormUnsuitables } from './form';
import { Confirm } from '../../../../components/admin/common/confirm';

@Component({
  selector: 'app-activity-details',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    HeaderComponent,
    SidebarComponent,
    FooterComponent,
    FormCardHeader,
    TabsMenu,
    SortableHeaderDirective,
  ],
  providers: [
    DatePipe,
  ],
  templateUrl: './component.html',
  styleUrl: './component.scss'
})
export class ActivityUnsuitablesComponent<T extends ActivityDetail> implements OnInit {
  id?: number;
  title = $localize`Details for Activity`;

  model: Fetchervice<T>;

  basePath!: string;

  menu: Link[] = [
    //
  ]

  constructor(
    private route: ActivatedRoute,
    private routeNames: RouteNamesService,
    private modalService: NgbModal,
  ) {
    this.model = Fetchervice.new<T>();

    this.basePath = this.routeNames.path("suppliers.activity.index") as string;
  }

  ngOnInit(): void {
    this.id = this.route.snapshot.params['id'];

    this.menu = getSupplierActivityMenu(this.basePath, `${this.id}`);

    this.model
      .from(`/api/v1/suppliers/activity/${this.id}/details`)
      .where("type", "unsuitable")
      .orderBy("id", "desc")
      .get();
  }

  onSort(event: SortEvent<T>) {
    this.model
      .orderBy(event.column, event.direction)
      .paginate(1)
      .get();
  }

  perPage(event: any) {
    this.model
      .paginate(1, event.target.value)
      .get()
    ;
  }

  setPage(n: number): void {
    this.model
      .paginate(n)
      .get()
    ;
  }

  open(id?: number) {
    const modalRef = this.modalService.open(FormUnsuitables);
    modalRef.componentInstance.id = id;
    modalRef.componentInstance.parentId = this.id;

    modalRef.result.then((model: Fetchervice<ActivityDetail>) => {
      model.save(() => {
        this.model.get();
      });
    }).catch((error) => {
      console.log(error);
    });
  }

  delete(id: number) {
    const modalRef = this.modalService.open(Confirm);
    modalRef.result.then(() => {
        this.model.delete(id);
    }).catch((error) => {
      console.log(error);
    });
  }
}
