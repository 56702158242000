import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, inject } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-calendar',
  standalone: true,
  imports: [
    CommonModule
  ],
  templateUrl: './calendar.html',
  styles: [
    ".col-day {min-width: calc(100% / 7);}"
  ]
})
export class Calendar implements OnInit, OnChanges {
  @Input() closed: Date[] = [];
  @Input() open: Date[] = [];
  @Input() select!: number | undefined;
  @Output() selected = new EventEmitter<MonthDate | undefined>();
  current!: Date;
  start!: Date;
  end?: Date;

  weekDays = [
    $localize`Sun`,
    $localize`Mon`,
    $localize`Tue`,
    $localize`Wed`,
    $localize`Thu`,
    $localize`Fri`,
    $localize`Sat`,
  ];

  months = [
    $localize`January`,
    $localize`February`,
    $localize`March`,
    $localize`April`,
    $localize`May`,
    $localize`June`,
    $localize`July`,
    $localize`August`,
    $localize`September`,
    $localize`October`,
    $localize`November`,
    $localize`December`,
  ];

  monthArray: MonthDate[] = [];

  ngOnInit(): void {
    if (this.open.length > 0) {
      this.open.sort((a, b) => {
        if (a.getTime() < b.getTime()) return -1;
        if (a.getTime() > b.getTime()) return 1;
        return 0;
      });

      const first = this.open[0];
      const last = this.open[this.open.length - 1];

      this.start = new Date(first.getFullYear(), first.getMonth(), first.getDate());
      this.end = new Date(last.getFullYear(), last.getMonth(), last.getDate());
      this.current = new Date(first.getFullYear(), first.getMonth(), first.getDate());
    } else {
      const now = new Date();
      this.current = now;

      this.start = now;
    }
    this.monthArray = this.getMonthArray();

    if (!this.select) {
      const date = this.monthArray.find(day => day.status == "open");

      if (date) {
        date.status = "selected";
        this.select = date.day;
      }

      this.selected.emit(date);
    }
  }

  ngOnChanges() {
    // this.init();
  }

  init() {
    this.monthArray = this.getMonthArray();

    if (!this.select) {
      const date = this.monthArray.find(day => day.status == "open");

      if (date) {
        date.status = "selected";
        this.select = date.day;

        this.selected.emit(date);
      } else {
        this.selected.emit(undefined);
      }
    }
  }

  getMonthArray() {
    const max = new Date(this.current.getFullYear(), this.current.getMonth() + 1, 0);
    const days: MonthDate[] = [];
    const closed = this.closed.filter(d => d.getMonth() == this.current.getMonth());
    const open = this.open.filter(d => d.getMonth() == this.current.getMonth());

    for (let x = 1; x <= max.getDate(); x++) {
      const date = new Date(this.current.getFullYear(), this.current.getMonth(), x);
      let status: MonthDate["status"] = "open";

      if (closed.map(this.getDateString).includes(this.getDateString(date))) {
        status = "close";
      } else if (open.length > 0 && !open.map(this.getDateString).includes(this.getDateString(date))) {
        status = "close";
      } else if (this.select == date.getDate()) {
        status = "selected";
      }

      days.push({
        date: date,
        day: date.getDate(),
        month: date.getMonth(),
        year: date.getFullYear(),
        weekday: date.getDay(),
        status: status,
      });
    }

    // Casillas previas
    if (days[0].weekday != 0) {
      const maxLoop = days[0].weekday;

      for (let i = 0; i < maxLoop; i++) {
        const date = new Date(this.current.getFullYear(), this.current.getMonth(), -i);
        days.unshift({
          date: date,
          day: date.getDate(),
          month: date.getMonth(),
          year: date.getFullYear(),
          weekday: date.getDay(),
          status: "none",
        });
      }
    }

    // Casillas posteriores
    if (days[days.length - 1].weekday != 6) {
      const maxLoop = days[days.length - 1].weekday;
      for (let i = 6; i > maxLoop; i--) {
        const date = new Date(this.current.getFullYear(), this.current.getMonth(), max.getDate() + i - maxLoop);

        days.push({
          date: date,
          day: date.getDate(),
          month: date.getMonth(),
          year: date.getFullYear(),
          weekday: date.getDay(),
          status: "none",
        });
      }
    }

    return days;
  }

  getDateString(d: Date): string {
    return `${d.getFullYear()}-${d.getMonth().toString().padStart(2, "0")}-${d.getDate().toString().padStart(2, "0")}`;
  }

  getMonthName() {
    return this.months[this.current?.getMonth()];
  }

  openDate(date: MonthDate) {
    this.monthArray.forEach(day => {
      if (day.status == "selected" && date.day != day.day) {
        day.status = "open";
      }
    });

    if (date.status == "open") {
      this.select = date.day;
      date.status = "selected";

      this.selected.emit(date);
    } else {
      date.status = "open";
      this.selected.emit(undefined);
    }
  }

  nextMonth() {
    this.current.setMonth(this.current.getMonth() + 1);

    // if (this.current.month == 12) {
    //   this.current.year++;
    //   this.current.month = 0;
    // }

    this.select = undefined;
    this.selected.emit(undefined);
    this.init();
  }

  prevMonth() {
    this.current.setMonth(this.current.getMonth() - 1);

    // if (this.current.month < 0) {
    //   this.current.year--;
    //   this.current.month = 11;
    // }

    this.select = undefined;
    this.selected.emit(undefined);
    this.init();
  }

  getYearAndMonth(d: Date): string {
    return `${d.getFullYear()}${d.getMonth()}`;
  }
}


export type MonthDate = {
  date: Date,
  weekday: number,
  day: number,
  month: number,
  year: number,
  status: "open" | "close" | "selected" | "none",
}

export type DateInput = {
  year: number;
  month: number;
  day: number
}
