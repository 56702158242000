 <app-header />
<main class="my-5">
  <section class="container-fluid mb-5 p-5">
    <div class="p-5 pt-0 mb-5">
      <h1 class="text-body-emphasis" i18n>Coming Soon</h1>
      <p class="col-lg-8 fs-5 text-muted" i18n>We're working hard to make this page available soon.</p>
      <a class="btn btn-outline-secondary btn-lg px-4 px-5" routerLink="/">
        <i class="fa-solid fa-home"></i>
        <span class="ps-2" i18n>Go Home</span>
      </a>
    </div>
  </section>
</main>
<app-footer />
