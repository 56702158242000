import { Component, inject, Input, OnInit } from '@angular/core';
import { FormGroup, FormControl, ReactiveFormsModule } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Validate } from '../../../../services/validator';
import { CommonModule } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { Fetchervice } from '../../../../services/fetch.service';
import { SupplierContact } from '../../../../models/supplier_contacts';

@Component({
  selector: 'app-supplier-contact-create',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
  ],
  template: `
<div class="modal-header">
  <h4 class="modal-title">{{ title }}</h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
</div>
<div class="modal-body">
  <form class="form needs-validation" novalidate [formGroup]="form">
      <div class="form-floating mb-3">
          <input type="text" class="form-control" id="name" placeholder="Name" formControlName="name"
              [ngClass]="isInvalid('name') ? 'is-invalid' : ''">
          <label for="name" i18n>Name</label>
      </div>
      <div class="form-floating mb-3">
          <input type="email" class="form-control" id="email" placeholder="Email" formControlName="email"
              [ngClass]="isInvalid('email') ? 'is-invalid' : ''">
          <label for="email" i18n>Email</label>
      </div>
      <div class="form-floating mb-3">
          <input type="text" class="form-control" id="phone_number" placeholder="Email" formControlName="phone_number" [ngClass]="isInvalid('phone_number') ? 'is-invalid' : ''">
          <label for="email" i18n>Phone Number</label>
      </div>
      <div class="form-floating mb-3">
          <select class="form-control" name="status" id="status" formControlName="status">
              <option value="0">Inactive</option>
              <option value="1">Active</option>
          </select>
          <label for="status">Status</label>
      </div>
  </form>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss()" i18n>Cancel</button>
  <button type="button" class="btn btn-danger" (click)="modal.close(model)" [disabled]="!form.valid"
      i18n>Save</button>
</div>
    `,
})
export class CreateSupplierContact<T extends SupplierContact> implements OnInit {
  modal = inject(NgbActiveModal);

  title = $localize`Create Supplier Contact`;

  @Input() id?: number;

  form = new FormGroup({
    name: new FormControl('', [Validate.required, Validate.minLength(3), Validate.maxLength(124)]),
    email: new FormControl('', [Validate.email]),
    phone_number: new FormControl('', [Validate.required]),
    // description: new FormControl('', [Validate.required, Validate.minLength(12), Validate.maxLength(255)]),
    status: new FormControl(0, [Validate.required]),
  });
  model: Fetchervice<T>;

  constructor(
    private route: ActivatedRoute,
  ) {
    this.model = Fetchervice.new<T>(this.form);
  }

  ngOnInit(): void {
    this.model
      .from(SupplierContact)

    if (this.id) {
      this.model
        .find(this.id, () => {
          this.title = $localize`Update Supplier Contact - ${this.model.attributes.name}`
        });
    }
  }

  isInvalid(name: string): boolean {
    const formControl = this.form.get(name);

    if (formControl) {
      return formControl.invalid && (formControl.dirty || formControl.touched)
    }

    return false;
  }
}