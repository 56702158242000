import { Component, OnInit } from '@angular/core';
import {
  CommonModule,
} from '@angular/common'
import { HeaderComponent } from '../../components/public/header/component';
import { FooterComponent } from '../../components/public/footer/component';
import { RouterModule } from '@angular/router';
import { Calendar, MonthDate } from '../../components/calendar/calendar';
import { BehaviorSubject } from 'rxjs';
import { MetaService } from '../../services/meta.service';

@Component({
  selector: 'app-not-found',
  standalone: true,
  imports: [
    CommonModule,
    HeaderComponent,
    FooterComponent,
    RouterModule,
    Calendar,
  ],
  templateUrl: './component.html',
  styleUrl: './component.scss',
  host: {
    class: "min-vh-100"
  }
})
export class ComingSoonComponent {
  constructor(
    private meta: MetaService,
  ) {
    this.meta.title($localize`Coming Soon`);
  }
}
