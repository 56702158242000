import {
	Entity,
	Listable,
	Metadata,
	ModelI
} from "./model";



@Entity({
    url: "/api/v1/supplier_category"
})
export class SupplierCategory implements ModelI {
	id!: number;
	name!: string;
	status!: number;
}
