import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import {
  CommonModule, DatePipe,
} from '@angular/common'
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { HeaderComponent } from '../../../../components/admin/header/component';
import { FooterComponent } from '../../../../components/admin/footer/component';
import { SidebarComponent } from '../../../../components/admin/sidebar/component';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { Fetchervice } from '../../../../services/fetch.service';
import { Validate } from '../../../../services/validator';
import { RouteNamesService } from '../../../../services/route_name.service';
import { NgbDatepicker } from '@ng-bootstrap/ng-bootstrap';
import { ActivityComment } from '../../../../models/activity_comment';
import { Link, TabsMenu } from '../../../../components/admin/common/tab_menu';
import { FormCardHeader } from '../../../../components/admin/common/form_card_header';
import { getSupplierActivityMenu } from '../form/component';

@Component({
  selector: 'app-activity-comments',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    HeaderComponent,
    SidebarComponent,
    FooterComponent,
    FormCardHeader,
    TabsMenu,
  ],
  providers: [
    DatePipe,
  ],
  templateUrl: './component.html',
  styleUrl: './component.scss'
})
export class ActivityCommentsComponent<T extends ActivityComment> implements OnInit {
  id?: number;
  title = `Comments for Activity`;

  model: Fetchervice<ActivityComment>;

  basePath!: string;

  menu: Link[] = [
    //
  ]

  constructor(
    private route: ActivatedRoute,
    private routeNames: RouteNamesService,
  ) {
    this.model = Fetchervice.new<ActivityComment>();

    this.basePath = this.routeNames.path("suppliers.activity.index") as string;
  }

  ngOnInit(): void {
    this.id = this.route.snapshot.params['id'];

    this.menu = getSupplierActivityMenu(this.basePath, `${this.id}`);

    this.model
      .from(`/api/v1/suppliers/activity/${this.id}/comments`)
      // .perPage(0)
      .orderBy("id", "desc")
      .get();
  }
}
