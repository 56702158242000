import { Component, inject, Input, OnInit } from '@angular/core';
import { FormGroup, FormControl, ReactiveFormsModule } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Validate } from '../../../../services/validator';
import { CommonModule } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { Fetchervice } from '../../../../services/fetch.service';
import { SupplierContact } from '../../../../models/supplier_contacts';
import { ActivityDetail } from '../../../../models/activity_detail';

@Component({
  selector: 'app-supplier-attributes-form',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
  ],
  template: `
  <div class="modal-header">
    <h4 class="modal-title nowrap">{{ title }}</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <form class="form needs-validation" novalidate [formGroup]="form">
      <div class="form-group mb-3">
        <label for="description" i18n>Description</label>
        <textarea class="form-control" id="description" placeholder="Description" formControlName="description"
          [ngClass]="isInvalid('description') ? 'is-invalid' : ''" rows="3"></textarea>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss()" i18n>Cancel</button>
    <button type="button" class="btn btn-danger" (click)="modal.close(model)" [disabled]="!form.valid"
      i18n>Save</button>
  </div>
    `,
})
export class FormHighlightDetail<T extends ActivityDetail> implements OnInit {
  modal = inject(NgbActiveModal);
  title = $localize`Create Activity Attribute`;

  @Input() id?: number;
  @Input() parentId?: number;

  form = new FormGroup({
    description: new FormControl(''),
    type: new FormControl("highlight")
  });
  model: Fetchervice<T>;

  constructor(
    // private route: ActivatedRoute,
  ) {
    this.model = Fetchervice.new<T>(this.form);
  }

  ngOnInit(): void {
    this.model
      .from(`/api/v1/suppliers/activity/${this.parentId}/details`)
      .where("type", "highlight")

    if (this.id) {
      this.model
        .find(this.id, () => {
          this.title = $localize`Update Activity Question - #${this.model.attributes.id}`;
          this.form.get("description")?.setValue(this.model.attributes.description);
        });
    }
  }

  isInvalid(name: string): boolean {
    const formControl = this.form.get(name);

    if (formControl) {
      return formControl.invalid && (formControl.dirty || formControl.touched)
    }

    return false;
  }
}