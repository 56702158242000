<app-header />

<main>
  <div class="container my-5">
    <div class="row">
      <div class="offset-md-4 col-md-4 bg-white p-3">
        <form class="form needs-validation" novalidate [formGroup]="form" (ngSubmit)="onSubmit()">
          <div class="form-floating mb-3">
            <input type="text" id="username" class="form-control"
              [ngClass]="model.isInvalid('username') ? 'is-invalid' : ''" formControlName="username" required
              i18n-placeholder placeholder="Username" autocomplete="username">
            <label for="username" i18n>Username</label>

            <div *ngIf="model.isInvalid('username')" id="username-feedback" class="invalid-feedback">
              <div *ngIf="username.errors?.['required']" i18n>Username is required</div>
              <div *ngIf="username.errors?.['minlength']" i18n>Username must be at least 3 characters long.</div>
            </div>
          </div>
          <div class="form-floating mb-3">
            <input type="password" id="password" class="form-control"
              [ngClass]="model.isInvalid('password') ? 'is-invalid' : ''" formControlName="password" required
              i18n-placeholder placeholder="Password" autocomplete="current-password">
            <label for="password" i18n>Password</label>

            <div *ngIf="model.isInvalid('password')" id="password-feedback" class="invalid-feedback">
              <div *ngIf="password.errors?.['required']" i18n>Password is required</div>
              <div *ngIf="password.errors?.['minlength']" i18n>Password must be at least 8 characters long.</div>
            </div>
          </div>
          <div class="form-group mb-3">
            <button class="btn btn-outline-primary " type="submit" [disabled]="!form.valid || loading.isLoading">
              <div *ngIf="!loading.isLoading; else waiting" i18n>Login</div>

              <ng-template #waiting i18n>Wait</ng-template>
            </button>
          </div>
          <p class="small">
            <a routerLink="/forgot" class="text-muted" i18n>Forgot password?</a>
          </p>

          <p>
            <span i18n>Don't have an account?</span>
            &nbsp;
            <a routerLink="/suppliers/register" class="link-dark" i18n>Register here</a>
          </p>
        </form>
      </div>
    </div>
  </div>
</main>

<app-footer />