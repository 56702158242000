<section class="vh-100 pb-5">
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-6 text-black">
        <div class="px-5 pt-5 ms-xl-4">
          <a routerLink="/">
            <img class="me-5" src="assets/img/logo.png" alt="Logo" width="110">
          </a>
        </div>

        <div class="d-flex align-items-center h-custom-2 px-5 ms-xl-4 mt-5 pt-5 pt-xl-0 mt-xl-n5">
          <form class="form needs-validation" novalidate [formGroup]="form" (ngSubmit)="onSubmit()">

            <h3 class="fw-normal mb-3 pb-3" i18n>Recover account</h3>
            <div class="form-floating mb-3">
              <input type="text" id="user" class="form-control"
                [ngClass]="model.isInvalid('user') ? 'is-invalid' : ''" formControlName="user" required
                i18n-placeholder placeholder="Username or Email" autocomplete="username">
              <label for="user" i18n>Username or Email</label>

              <div *ngIf="model.isInvalid('user')" id="user-feedback" class="invalid-feedback">
                <div *ngIf="user.errors?.['required']" i18n>User is required</div>
                <div *ngIf="user.errors?.['minlength']" i18n>user must be at least 8 characters long.</div>
              </div>
            </div>

            <div class="pt-1 mb-4">
              <button class="btn btn-outline-primary " type="submit" [disabled]="!form.valid || loading.isLoading" i18n>
                Recover
              </button>
            </div>
            <!-- <p>
              <span i18n>Don't have an account?</span>
              &nbsp;
              <a routerLink="/register" class="link-info" i18n>Register here</a>
            </p> -->
          </form>
        </div>
      </div>
      <div class="col-sm-6 px-0 d-none d-sm-block">
        <img src="assets/img/login.jpg" alt="Login image"
        class="w-100 min-vh-100 h-100" style="object-fit: cover; object-position: left;">
      </div>
    </div>
  </div>
</section>