import {
	Entity,
	Listable,
	Metadata,
	ModelI
} from "./model";

@Entity({
    url: "/api/v1/provider_alert"
})
export class SupplierAlert implements ModelI {
	id!: number;
	title!: string;
	message!: string;
	status!: number;
	read_at!: string;
	author_id!: string;
	created_at!: string;
}
