import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { RouteNamesService } from '../services/route_name.service';

export const AuthGuard: CanActivateFn = (route: ActivatedRouteSnapshot) => {
  const router = inject(Router);
  const auth = inject(AuthService);
  const routeNames = inject(RouteNamesService);

  if (auth.user && auth.tokenValid(route.data["scope"])) {
    return true;
  }

  auth.logout();

  return router.createUrlTree([routeNames.path(auth.getLoginPath())]);
};
