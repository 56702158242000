<div class="d-flex flex-nowrap border rounded min-vh-100">

  <app-sidebar class="d-flex flex-column flex-shrink-0 text-dark bg-white border-end" style="width: 280px;" />

  <div class="d-flex flex-column flex-grow-1">
    <app-header />

    <main class="container-fluid h-100">
      <div class="row">
        <div class="col px-0">
          <div class="card border-0">
            <app-card-header [title]="title" [model]="model" [basePath]="basePath" />
            <div class="card-body">
              <app-tabs-menu [links]="menu" [hide]="model.isNew" />
              <form class="form needs-validation" novalidate [formGroup]="form">
                <div class="row gx-3">
                  <div class="col-sm-8">
                    <div class="row gx-3">
                      <div class="col-sm-6">
                        <div class="form-floating mb-3">
                          <input type="text" class="form-control" id="trade_name" placeholder="Title"
                            formControlName="trade_name" [ngClass]="isInvalid('trade_name') ? 'is-invalid' : ''">
                          <label for="trade_name">Trade Name</label>

                          <div *ngIf="isInvalid('trade_name')" class="invalid-feedback">
                            <div *ngIf="this.form.get('trade_name')?.errors?.['required']" i18n>Title is required</div>
                            <div *ngIf="this.form.get('trade_name')?.errors?.['minlength']" i18n>Title must be at least
                              3
                              characters long.</div>
                            <div *ngIf="this.form.get('trade_name')?.errors?.['maxlength']" i18n>Title must be at most
                              124
                              characters long.</div>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-6">
                        <div class="form-floating mb-3">
                          <input type="text" class="form-control" id="legal_name" placeholder="Title"
                            formControlName="legal_name" [ngClass]="isInvalid('legal_name') ? 'is-invalid' : ''">
                          <label for="legal_name">Trade Name</label>

                          <div *ngIf="isInvalid('legal_name')" class="invalid-feedback">
                            <div *ngIf="this.form.get('legal_name')?.errors?.['required']" i18n>Title is required</div>
                            <div *ngIf="this.form.get('legal_name')?.errors?.['minlength']" i18n>Title must be at least
                              3
                              characters long.</div>
                            <div *ngIf="this.form.get('legal_name')?.errors?.['maxlength']" i18n>Title must be at most
                              124
                              characters long.</div>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-4">
                        <div class="input-group">
                          <select class="form-select" name="document_type_id" id="document_type_id" style="max-width: 80px;" formControlName="document_type_id">
                            <option value="1">V</option>
                            <option value="2">E</option>
                            <option value="3">J</option>
                          </select>
                          <div class="form-floating">
                            <input type="text" class="form-control" id="document_code" placeholder="Title"
                              formControlName="document_code" [ngClass]="isInvalid('document_code') ? 'is-invalid' : ''">
                            <label for="document_code">Document ID</label>
                          </div>

                          <div *ngIf="isInvalid('document_code')" class="invalid-feedback">
                            <div *ngIf="this.form.get('document_code')?.errors?.['required']" i18n>Title is required
                            </div>
                            <div *ngIf="this.form.get('document_code')?.errors?.['minlength']" i18n>Title must be at
                              least 3
                              characters long.</div>
                            <div *ngIf="this.form.get('document_code')?.errors?.['maxlength']" i18n>Title must be at
                              most 124
                              characters long.</div>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-4">
                        <div class="form-floating mb-3">
                          <input type="text" class="form-control" id="email" placeholder="Title" formControlName="email"
                            [ngClass]="isInvalid('email') ? 'is-invalid' : ''">
                          <label for="email">Email</label>

                          <div *ngIf="isInvalid('email')" class="invalid-feedback">
                            <div *ngIf="this.form.get('email')?.errors?.['required']" i18n>Title is required</div>
                            <div *ngIf="this.form.get('email')?.errors?.['minlength']" i18n>Title must be at least 3
                              characters long.</div>
                            <div *ngIf="this.form.get('email')?.errors?.['maxlength']" i18n>Title must be at most 124
                              characters long.</div>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-4">
                        <div class="form-floating mb-3">
                          <input type="text" class="form-control" id="webpage" placeholder="Title"
                            formControlName="webpage" [ngClass]="isInvalid('webpage') ? 'is-invalid' : ''">
                          <label for="webpage">Webpage</label>

                          <div *ngIf="isInvalid('webpage')" class="invalid-feedback">
                            <div *ngIf="this.form.get('webpage')?.errors?.['required']" i18n>Title is required</div>
                            <div *ngIf="this.form.get('webpage')?.errors?.['minlength']" i18n>Title must be at least 3
                              characters long.</div>
                            <div *ngIf="this.form.get('webpage')?.errors?.['maxlength']" i18n>Title must be at most 124
                              characters long.</div>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-12">
                        <div class="form-floating mb-3">
                          <input type="text" class="form-control" id="address" placeholder="Title"
                            formControlName="address" [ngClass]="isInvalid('address') ? 'is-invalid' : ''">
                          <label for="address">Address</label>

                          <div *ngIf="isInvalid('address')" class="invalid-feedback">
                            <div *ngIf="this.form.get('address')?.errors?.['required']" i18n>Title is required</div>
                            <div *ngIf="this.form.get('address')?.errors?.['minlength']" i18n>Title must be at least 3
                              characters long.</div>
                            <div *ngIf="this.form.get('address')?.errors?.['maxlength']" i18n>Title must be at most 124
                              characters long.</div>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-12">
                        <div class="mb-3">
                          <st-editor [ngClass]="isInvalid('username') ? 'is-invalid' : ''" formControlName="description"
                            [config]="config"></st-editor>
                          <div *ngIf="isInvalid('description')" id="username-feedback" class="invalid-feedback">
                            <div *ngIf="this.form.get('description')?.errors?.['required']" i18n>Category is required
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-4">
                    <div class="form-floating mb-3">
                      <select class="form-control" name="category_id" id="category_id" formControlName="category_id">
                        @for (category of categories.items; track category.id) {
                          <option [value]="category.id">{{ category.name }}</option>
                        }
                      </select>
                      <label for="category_id">Category</label>
                    </div>
                    <div class="form-floating mb-3">
                      <input type="number" class="form-control" id="fee" placeholder="Price"
                        formControlName="fee" [ngClass]="isInvalid('fee') ? 'is-invalid' : ''">
                      <label for="fee">Fee</label>

                      <div *ngIf="isInvalid('fee')" id="username-feedback" class="invalid-feedback">
                        <div *ngIf="this.form.get('fee')?.errors?.['required']" i18n>Price is required</div>
                        <div *ngIf="this.form.get('fee')?.errors?.['min']" i18n>Price must be at least 0</div>
                      </div>
                    </div>

                    <div class="form-floating mb-3">
                      <select class="form-control" name="status" id="status" formControlName="status">
                        <option value="1" selected>Active</option>
                        <option value="0">Inactive</option>
                      </select>
                      <label for="status">Status</label>
                    </div>

                    <div class="form-floating mb-3">
                      <select class="form-control" name="language_id" id="language_id" formControlName="language_id">
                        @for (language of languages.items; track language.id) {
                          <option [value]="language.id">{{ language.name }}</option>
                        }
                      </select>
                      <label for="language_id">Language</label>
                    </div>
                    <div class="form-floating mb-3">
                      <input class="form-control" type="text" name="activated_at" id="activated_at" formControlName="activated_at" readonly>
                      <label for="fee">Active Since</label>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</div>

<app-footer />