import { Component, Input } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { Activity } from '../../../models/activity';
import { getEnv } from '../../../services/config.service';
import { Fetchervice } from '../../../services/fetch.service';
import { RouterModule } from '@angular/router';

@Component({
    selector: 'article[square]',
    standalone: true,
    imports: [
      CommonModule,
      RouterModule,
    ],
    templateUrl: './square.html',
    styleUrl: './square.scss',
    host: { class: 'col position-relative overflow-hidden' },
})
export class SquareArticle {
  fetch: Fetchervice<any> = Fetchervice.new<any>();

  @Input() item!: Activity;

  bookmark(activity: Activity) {
    activity.bookmarked = true;

    this.fetch.post(`${getEnv("API_URL")}/api/v1/activity/${activity.id}/bookmark`);
  }

  unbookmark(activity: Activity) {
    activity.bookmarked = false;

    this.fetch.post(`${getEnv("API_URL")}/api/v1/activity/${activity.id}/unbookmark`);
  }

  getTagName(name: string): string {
    let result = '';

    switch (name) {
      case "new":
        result = $localize`New`;
        break;
      case "recommended":
        result = $localize`Recommended`;
        break;
      case "inexpensive":
        result = $localize`Inexpensive`;
        break;
    
      default:
        result = name;
        break;
    }

    return result;
  }
}