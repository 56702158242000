import { Component, OnChanges, OnInit, SimpleChanges, inject } from '@angular/core';
import {
  CommonModule,
} from '@angular/common'
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { FooterComponent } from '../../../components/public/footer/component';
import { HeaderComponent } from '../../../components/public/header/component';
import { Fetchervice } from '../../../services/fetch.service';
import { Activity } from '../../../models/activity';
import { NgbCarouselConfig, NgbCarouselModule, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, FormGroup, FormsModule } from '@angular/forms';
import { ActivityBooking } from '../../../models/activity_booking';
import { Confirm } from '../../../components/admin/common/confirm';
import { HttpClient } from '@angular/common/http';
import { getEnv } from '../../../services/config.service';
import { AuthService } from '../../../services/auth.service';
import { ToastService } from '../../../services/toats.service';
import { RouteNamesService } from '../../../services/route_name.service';
import { MetaService } from '../../../services/meta.service';

@Component({
  selector: 'app-not-found',
  standalone: true,
  imports: [
    CommonModule,
    HeaderComponent,
    FooterComponent,
    RouterModule,
    NgbCarouselModule,
  ],
  templateUrl: './component.html',
  styleUrl: './component.scss',
  host: {
    class: "min-h-100"
  }
})
// export class CartComponent<T extends ActivityBooking> implements OnInit {
export class CartComponent<T extends ActivityBooking> implements OnInit {

  model: Fetchervice<T> = Fetchervice.new<T>();
  fetch = inject(Fetchervice);

  constructor(
    private modalService: NgbModal,
    public toastService: ToastService,
    private router: Router,
    private routeNames: RouteNamesService,
    private meta: MetaService,
  ) {
    this.meta.title($localize`Shopping Cart`);
  }

  ngOnInit(): void {
    // const id = this.route.snapshot.params['id'];
    this.model
      .from("/api/v1/booking")
      .where("status", "0")
      .relations(["activity.images", "date", "time", "details", "details.price"])
      .get()
  }

  getActivityTotal(details: any) {
    let sum = 0;

    for (const detail of details) {
      sum += detail.price?.price * detail.quantity;
    }

    return sum;
  }

  getTotal() {
    let totalPrice = 0;

    for (const item of this.model.items) {
      totalPrice += this.getActivityTotal(item.details);
    }

    return totalPrice;
  }

  delete(id: number) {
    const modalRef = this.modalService.open(Confirm);
    modalRef.result.then(() => {
      this.model.delete(id);
    }).catch((error) => {
      console.log(error);
    });
  }

  placeOrder() {
    const form = new FormGroup({
      booking_ids: new FormControl(this.model.items.map(item => item.id))
    });

    this.fetch.setForm(form);

    this.fetch
    .from("/api/v1/booking/order")
    .save(() => {
      this.toastService.show({
        header: $localize`Order placed`,
        body: $localize`Your order has been placed successfully`,
        classname: "bg-success text-light"
      });

      this.router.navigate([this.routeNames.path("bookings.unpaid")]);
    })
  }
}
