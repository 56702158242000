import { Component, OnInit } from '@angular/core';
import {
  CommonModule, DatePipe,
} from '@angular/common'
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
} from '@angular/forms';
import { AuthService, LoginCredentialsI } from '../../../services/auth.service';
import { ToastService } from '../../../services/toats.service';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { HeaderComponent } from '../../../components/public/header/component';
import { FooterComponent } from '../../../components/public/footer/component';
import { LoadingService } from '../../../services/loading.service';
import { Validate } from '../../../services/validator';
import { RouteNamesService } from '../../../services/route_name.service';
import { MetaService } from '../../../services/meta.service';

@Component({
  selector: 'app-internal-login',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RouterModule,
  ],
  providers: [
    DatePipe,
  ],
  templateUrl: './component.html',
  styleUrl: './component.scss'
})
export class ClientPasswordForgotComponent implements OnInit {
  form = new FormGroup({
    user: new FormControl('', [Validate.required, Validate.minLength(3)]),
  });

  model: RecoverModel;
  // toastService = inject(ToastService);

  constructor(
    private auth: AuthService,
    public toastService: ToastService,
    public loading: LoadingService,
    private route: ActivatedRoute,
    private router: Router,
    private routeNames: RouteNamesService,
    private meta: MetaService,
  ) {
    this.meta.title($localize `Forgot Password`);
    this.model = new RecoverModel(this.form);
    // this.toastService.show({ header: "CONSTRUCTOR"})
  }

  ngOnInit(): void {
    this.loading.hide();
  }

  get user(): AbstractControl {
    return this.form.get('user') as AbstractControl;
  }

  onSubmit() {
    this.loading.show();
    const scope = this.route.snapshot.data["scope"];

    this.auth.requestPaswordChange(this.form.value)
      .then(() => {
        this.toastService.show({ header: $localize`Please check your email`, body: $localize`Is your credentials are valid we've sent you an email with the activation link.`, classname: "bg-success text-light" });
        console.log(this.routeNames.path("home"));
        this.router.navigate([this.routeNames.path("home")]);
      }, error => {
        this.toastService.show({ header: "ERROR", body: error.error?.message ?? "Error", classname: "bg-danger text-light", })
      }).finally(() => {
        this.loading.hide();
      })
  }
}

class RecoverModel {
  constructor(
    public form: FormGroup<{
      user: FormControl<string | null>;
    }>
  ) {
    //
  }

  isInvalid(name: "user"): boolean {
    const formControl = this.form.get(name);

    if (formControl) {
      return formControl.invalid && (formControl.dirty || formControl.touched)
    }

    return false;
  }
}