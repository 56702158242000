<app-header />

<main class="container-fluid my-5 min-vh-100">
  <div class="row g-0">
    <aside class="col-sm-3 border">
      <form class="form w-100" [formGroup]="params">
        <div class="accordion accordion-flush p-2" id="accordionExample">
          <div class="accordion-item border-0">
            <h2 class="accordion-header">
              <button class="accordion-button py-2 bg-light" type="button" data-bs-toggle="collapse"
                data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                Disponibilidad
              </button>
            </h2>
            <div id="collapseThree" class="accordion-collapse collapse show" data-bs-parent="#accordionExample">
              <div class="accordion-body px-0 pt-1 pb-3">
                <div class="input-group input-group-sm">
                  <button class="btn btn-outline-secondary col" type="button" (click)="startsAtToday()">Hoy</button>
                  <button class="btn btn-outline-secondary col" type="button"
                    (click)="startsAtTomorrow()">Mañana</button>
                  <input class="form-control col" type="date" name="starts_at" id="starts_at"
                    (change)="startAtDateChange($event)">
                </div>
              </div>
            </div>
          </div>
          <div class="accordion-item border-0">
            <h2 class="accordion-header">
              <button class="accordion-button py-2 bg-light" type="button" data-bs-toggle="collapse"
                data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                Ubicación
              </button>
            </h2>
            <div id="collapseThree" class="accordion-collapse collapse show" data-bs-parent="#accordionExample">
              <div class="accordion-body px-0 pt-1 pb-3">
                <div class="input-group input-group-sm">
                  <label class="input-group-text col rounded-bottom-0 bg-white" for="">Origen</label>
                  <label class="input-group-text col rounded-bottom-0 bg-white" for="">Destino</label>
                </div>
                <div class="input-group input-group-sm">
                  <select id="origin_id" name="origin_id" class="form-select rounded-top-0 border-top-0"
                    formControlName="origin_id" (change)="setParams()">
                    <option value="">Seleccione</option>
                    @for (location of locations.items; track location.id) {
                    <option [value]="location.id">{{ location.name }}</option>
                    }
                  </select>
                  <select id="destination_id" name="destination_id"
                    class="form-select rounded-top-0 border-top-0" formControlName="destination_id"
                    (change)="setParams()">
                    <option value="">Seleccione</option>
                    @for (location of locations.items; track location.id) {
                    <option [value]="location.id">{{ location.name }}</option>
                    }
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div class="accordion-item border-0">
            <h2 class="accordion-header">
              <button class="accordion-button py-2 bg-light" type="button" data-bs-toggle="collapse"
                data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                Categorías
              </button>
            </h2>
            <div id="collapseOne" class="accordion-collapse collapse show" data-bs-parent="#accordionExample">
              <div class="accordion-body px-0 pt-1 pb-3">
                @for (category of categories.items; track category.id) {
                <div class="form-check border-bottom">
                  <input class="form-check-input" type="checkbox" [value]="category.id" id="category_id"
                    (change)="onCategoryChecked($event)" [checked]="isCategoryChecked(category.id)">
                  <label class="form-check-label" for="category_id">
                    {{ category.name }}
                  </label>
                </div>
                }
              </div>
            </div>
          </div>
          <div class="accordion-item border-0">
            <h2 class="accordion-header">
              <button class="accordion-button py-2 bg-light" type="button" data-bs-toggle="collapse"
                data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                Detalles
              </button>
            </h2>
            <div id="collapseOne" class="accordion-collapse collapse show" data-bs-parent="#accordionExample">
              <div class="accordion-body px-0 pt-1 pb-3">
                <div id="collapseOne" class="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                  <div class="accordion-body px-0 pt-1 pb-3">
                    @for (attribute of attributes.items; track attribute.id) {
                    <div class="form-check border-bottom">
                      <input class="form-check-input" type="checkbox" [value]="attribute.id" id="attribute_id"
                        (change)="onAttributeChecked($event)">
                      <label class="form-check-label" for="attribute_id">
                        {{ attribute.name }} - {{ attribute.description }}
                      </label>
                    </div>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="accordion-item border-0">
            <h2 class="accordion-header">
              <button class="accordion-button py-2 bg-light" type="button" data-bs-toggle="collapse"
                data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                Precio
              </button>
            </h2>
            <div id="collapseThree" class="accordion-collapse collapse show" data-bs-parent="#accordionExample">
              <div class="accordion-body px-0 pt-1 pb-3">
                <div class="input-group input-group-sm">
                  <input id="base_price_gt" name="base_price_gt" type="number" class="form-control" placeholder="Min"
                    formControlName="base_price_gt" (change)="setParams()" min="0">
                  <input id="base_price_lt" name="base_price_lt" type="number" class="form-control" placeholder="Max"
                    formControlName="base_price_lt" (change)="setParams()" min="0">
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </aside>
    <section class="col-sm-9">
      <div class="row g-3 ps-3">
        @if (items.length > 0) {
        @for (item of items; track item.id) {
        <div class="col-sm-12">
          <a class="text-decoration-none" [routerLink]="['/activity', item.id, item.slug]">
            <div class="d-flex flex-row border shadow bg-white">
              <div class="flex-shrink-0">
                <img
                  [ngSrc]="item.images[0]?.path ?? 'https://loremflickr.com/cache/resized/65535_53027288638_0a0a873a95_480_320_nofilter.jpg'"
                  [alt]="item.title" width="270" height="180">
              </div>
              <div class="card border-0 rounded-0 flex-grow-1">
                <div class="card-body py-0">
                  <h3 class="card-title mb-0 pt-2 fs-5">{{ item.title }}</h3>
                  <hr class="my-1">
                  <span class="badge bg-secondary">{{ item.category.name }}</span>
                  <p class="card-text">
                    {{ item.summary }}
                  </p>
                </div>
                <div class="card-footer border-0 bg-white pt-0 pb-1">
                  <!-- <p class="small mb-0" i18n>Starts at: {{ item.starts_at }} *Anulación gratuita</p> -->
                </div>
              </div>
              <div class="d-flex px-3 border-start position-relative">
                @if (item.bookmarked) {
                <span class="position-absolute z-1 top-0 end-0 pt-1 pe-2 text-danger" (click)="unbookmark(item)">
                  <i role="button" class="fa-solid fa-heart"></i>
                </span>
                }
                @else {
                <span class="position-absolute z-1 top-0 end-0 pt-1 pe-2 text-dark" (click)="bookmark(item)">
                  <i role="button" class="fa-regular fa-heart"></i>
                </span>
                }
                <span class="text-nowrap fs-4 align-self-center">{{ item.base_price }}$</span>
              </div>
            </div>
          </a>
        </div>
        }
        @if (model.pagination.current < model.pagination.last) {
          <div class="col-sm-12 text-center">
            <span class="btn btn-sm btn-outline-secondary" role="button" (click)="load()" i18n>
              Load more...
            </span>
          </div>
        }
        }
        @else {
          <div class="col-sm-12 text-center border shadow bg-white py-5">
            <p class="fs-1">
              <i class="fa-solid fa-magnifying-glass"></i>
            </p>
            <p class="fs-3" i18n>We couldn't find anything for your current selection.</p>
            <ul class="d-inline-block text-start">
              <li i18n>Please try with a different search.</li>
              <li i18n>Check the spelling of the word.</li>
              <li i18n>Browse the categories to find a similar activity.</li>
            </ul>
          </div>
        }
  </div>
  </section>
  </div>
</main>

<app-footer />